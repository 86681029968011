var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    width: 100 + '%',
    display: 'inline'
  })},[_c('div',{style:({
      display: 'inline'
    })},[_c('iconSK',{style:({
        cursor: _vm.disabled ? 'not-allowed' : 'pointer',
        marginRight: 10 + 'px'
      }),attrs:{"icon":_vm.currentEditing ? 'rectangleChecked' : 'rectangleUnchecked',"width":_vm.isMobile ? 16 : 20,"height":_vm.isMobile ? 16 : 20},on:{"click":function($event){_vm.disabled ? '' : (_vm.currentEditing = !_vm.currentEditing)}}})],1),(_vm.editorItem)?_c('P2SK',{style:({
      display: 'inline'
    }),attrs:{"text":_vm.editorItem.toolText}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }