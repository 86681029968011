var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{class:[
    { 'sk-font-bold': _vm.bold },
    { 'sk-font-regular': !_vm.bold },
    { 'sk-selectable': _vm.selectable },
    { 'sk-user-select-none': !_vm.selectable }
  ],style:({
    marginBottom: 0 + 'px',
    display: '-webkit-box',
    webkitBoxOrient: 'vertical',
    overflow: 'hidden',
    webkitLineClamp: _vm.lineClamp !== 0 ? _vm.lineClamp : 'none',

    fontSize: _vm.isMobile ? 14 + 'px' : 18 + 'px',
    lineHeight: _vm.isMobile ? 18 + 'px' : 24 + 'px',
    color: _vm.inverted ? 'white' : '#1a304c',
    textAlign: _vm.textAlignCenter ? 'center' : 'left',
    cursor: this.text.startsWith('sk') && _vm.translateMode ? 'pointer' : ''
  }),on:{"click":function($event){_vm.translateMode ? _vm.openEditTranslationModal() : _vm.$emit('click')}}},[_vm._v(" "+_vm._s(_vm.getDixTranslation(_vm.text))+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }