var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ion-page skViewPort"},[(!_vm.isMobile)?_c('ion-header',{attrs:{"no-border":""}},[_c('RegularHeaderSK',{attrs:{"show-menu":true}})],1):_vm._e(),_c('ion-content',{class:_vm.contentIsHidden ? 'hide-content-sk' : 'show-content-sk'},[_c('div',{style:({
        background: 'rgba(169, 206, 162, 0.12)',
        height: _vm.appHeight - 100 - 48 + 'px'
      })},[_c('div',{staticClass:"flex-wrap-container",style:({
          marginTop: _vm.isMobile ? 0 + 'px' : _vm.gMDynamicSize(48) + 'px'
        })},[(_vm.appWidth > 1230)?_c('div',{style:({
            background: 'white',
            flex: 1,
            height: _vm.gMDynamicSize(273, 1441, 1000) + 'px'
          })}):_vm._e(),(_vm.currentProfile)?_c('div',{staticClass:"flex-wrap-container",style:({
            border: 'solid 15px rgb(252,185,194,0.8)',
            background: 'white',
            width: 100 + '%',
            maxWidth: 1180 + 'px'
          })},[_c('div',{style:({
              width: 100 + '%',
              marginTop: _vm.gMDynamicSize(20, 375, 280) + 'px',
              marginLeft: _vm.gMDynamicSize(15, 375, 280) + 'px',
              marginRight: _vm.gMDynamicSize(15, 375, 280) + 'px'
            })},[_c('div',{staticClass:"flex-wrap-container",style:({
                float: 'right'
              })},[(_vm.isOwnProfile)?_c('P1SK',{style:({
                  width: _vm.appWidth < 375 ? 80 + 'px' : 'auto',
                  marginTop: 4 + 'px',
                  cursor: 'pointer',
                  display: 'inline',
                  marginRight: _vm.gMDynamicSize(20, 375, 280) + 'px'
                }),attrs:{"text":'skProfileEdit'},on:{"click":function($event){return _vm.openEditor()}}}):_vm._e(),(_vm.isOwnProfile && !_vm.isFirstEdit)?_c('P1SK',{style:({
                  width: _vm.appWidth < 375 ? 80 + 'px' : 'auto',
                  marginTop: 4 + 'px',
                  cursor: 'pointer',
                  display: 'inline',
                  marginRight: _vm.gMDynamicSize(20, 375, 280) + 'px'
                }),attrs:{"text":_vm.currentProfile.profileIsPublic ? 'skProfileHide' : 'skProfilePublish'},on:{"click":function($event){return _vm.publishProfile()}}}):_vm._e(),_c('div',{style:({
                  flex: 1
                })},[_c('img',{style:({
                    cursor: 'pointer',
                    display: 'inline',
                    verticalAlign: 'bottom',
                    height: 100 + '%'
                  }),attrs:{"src":require("../assets/SKicons/closeBlue.svg"),"width":_vm.gMDynamicSize(30, 375, 280) + 'px',"height":_vm.gMDynamicSize(30, 375, 280) + 'px'},on:{"click":function($event){return _vm.goToHomePage()}}})])],1)]),_c('div',{staticClass:"flex-wrap-container",style:({
              maxWidth: _vm.appWidth > 927 ? 312 + 'px' : 100 + '%'
            })},[_c('div',{staticClass:"thumbnail",style:({
                background: _vm.showDefaultImage ? '#FFF2B0' : 'white',
                marginTop: _vm.appWidth > 927 ? _vm.gMDynamicSize(28, 375, 280) + 'px' : 15 + 'px',
                marginLeft: _vm.appWidth > 927 ? _vm.gMDynamicSize(28, 375, 280) + 'px' : 15 + 'px',
                marginRight: _vm.gMDynamicSize(14, 375, 280) + 'px',
                width: _vm.isMobile ? 197 + 'px' : _vm.gMDynamicSize(256, 375, 280) + 'px',
                height: _vm.isMobile ? 197 + 'px' : _vm.gMDynamicSize(256, 375, 280) + 'px'
              })},[(_vm.showDefaultImage)?_c('img',{staticClass:"portrait",style:({
                  height: 50 + '%'
                }),attrs:{"src":_vm.imgPath}}):_c('img',{staticClass:"portrait",attrs:{"src":_vm.imgPath}})]),(_vm.appWidth > 641)?_c('div',{style:({
                height: 100 + '%',
                maxHeight: _vm.appWidth > 927 ? 385 + 'px' : 'auto',
                marginTop: 45 + 'px',
                marginLeft: 47 + 'px',
                marginRight: 14 + 'px'
              })},[_c('H2SK',{style:({
                  marginBottom: 31 + 'px'
                }),attrs:{"text":'skProfileContact'}}),_c('div',{style:({
                  marginBottom: 14 + 'px'
                })},[_c('mediaEditLinkElementSK',{attrs:{"icon":'Mail',"text":_vm.visibleEmail === null ? 'skProfileShowEmail' : _vm.visibleEmail,"captchaCheck":true,"mailIsVisible":_vm.visibleEmail === null ? false : true},on:{"openCaptcha":function($event){_vm.showCaptchaModal = true}}})],1),(_vm.currentProfile.url !== '')?_c('div',{style:({
                  marginBottom: 14 + 'px'
                })},[_c('mediaEditLinkElementSK',{attrs:{"icon":'Web',"text":_vm.currentProfile.url}})],1):_vm._e(),(_vm.currentProfile.hasPhone || _vm.isOwnProfile)?_c('div',{style:({
                  marginBottom: 14 + 'px'
                })},[_c('mediaEditLinkElementSK',{attrs:{"icon":'Tel',"text":_vm.visiblePhone === null ? 'skProfileShowPhone' : _vm.visiblePhone,"captchaCheck":true,"selectable":_vm.visiblePhone === null ? false : true},on:{"openCaptcha":function($event){_vm.showCaptchaModal = true}}})],1):_vm._e(),(_vm.currentProfile.twitterLink !== '')?_c('div',{style:({
                  marginBottom: 14 + 'px'
                })},[_c('mediaEditLinkElementSK',{attrs:{"icon":'Twitter',"text":_vm.currentProfile.twitterLink}})],1):_vm._e(),(_vm.currentProfile.instaLink !== '')?_c('div',{style:({
                  marginBottom: 14 + 'px'
                })},[_c('mediaEditLinkElementSK',{attrs:{"icon":'Insta',"text":_vm.currentProfile.instaLink}})],1):_vm._e(),(_vm.currentProfile.linkedInLink !== '')?_c('div',{style:({
                  marginBottom: 14 + 'px'
                })},[_c('mediaEditLinkElementSK',{attrs:{"icon":'Edin',"text":_vm.currentProfile.linkedInLink}})],1):_vm._e(),(_vm.currentProfile.fbLink !== '')?_c('div',{style:({
                  marginBottom: 14 + 'px'
                })},[_c('mediaEditLinkElementSK',{attrs:{"icon":'Fb',"text":_vm.currentProfile.fbLink}})],1):_vm._e(),(_vm.currentProfile.xingLink !== '')?_c('div',{style:({
                  marginBottom: 14 + 'px'
                })},[_c('mediaEditLinkElementSK',{attrs:{"icon":'Xing',"text":_vm.currentProfile.xingLink}})],1):_vm._e(),(_vm.currentProfile.pinterestLink !== '')?_c('div',{style:({
                  marginBottom: 14 + 'px'
                })},[_c('mediaEditLinkElementSK',{attrs:{"icon":'Pinterest',"text":_vm.currentProfile.pinterestLink}})],1):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"flex-wrap-container",style:({
              minWidth: _vm.appWidth > 641 ? 565 + 'px' : 'auto',
              flex: 1
            })},[_c('div',{style:({
                flex: 1,
                marginLeft: _vm.isMobile ? 17 + 'px' : 14 + 'px',
                marginRight: 14 + 'px',
                minWidth: _vm.appWidth > 434 ? 319 + 'px' : 189 + 'px'
              })},[_c('div',{style:({
                  marginTop: _vm.appWidth > 927 ? _vm.gMDynamicSize(160, 375, 280) + 'px' : 10 + 'px'
                })},[_c('H1SK',{attrs:{"text":_vm.currentProfile.title +
                    ' ' +
                    _vm.currentProfile.firstName +
                    ' ' +
                    _vm.currentProfile.lastName,"bold":true,"selectable":true}})],1),_c('div',[_c('H2SK',{attrs:{"text":_vm.currentProfile.jobTitle,"selectable":true}})],1),_c('div',[_c('H2SK',{attrs:{"text":_vm.currentProfile.employer,"selectable":true}})],1),(_vm.isMobile)?_c('div',{style:({
                  marginTop: 9 + 'px'
                })},[_c('div',_vm._l((_vm.getLocations(_vm.currentProfile.locations)),function(location){return _c('P2SK',{key:location.nr,style:({ display: 'inline' }),attrs:{"text":location.userLanguage}})}),1),_c('div',_vm._l((_vm.getLanguages(_vm.currentProfile.languagesDd)),function(language){return _c('P2SK',{key:language.nr,style:({ display: 'inline' }),attrs:{"text":language.userLanguage}})}),1)]):_c('div',{style:({
                  marginTop: 27 + 'px'
                })},[_c('div',_vm._l((_vm.getLocations(_vm.currentProfile.locations)),function(location){return _c('P1SK',{key:location.nr,style:({ display: 'inline' }),attrs:{"text":location.userLanguage}})}),1),_c('div',_vm._l((_vm.getLanguages(_vm.currentProfile.languagesDd)),function(language){return _c('P1SK',{key:language.nr,style:({ display: 'inline' }),attrs:{"text":language.userLanguage}})}),1)]),(_vm.appWidth <= 641)?_c('div',{style:({
                  marginTop: _vm.gMDynamicSize(40, 375, 280) + 'px',
                  marginBottom: _vm.gMDynamicSize(40, 375, 280) + 'px'
                })},[_c('H2SK',{style:({
                    marginBottom: _vm.gMDynamicSize(31, 375, 280) + 'px'
                  }),attrs:{"text":'skProfileContact'}}),_c('div',{style:({
                    marginBottom: _vm.gMDynamicSize(14, 375, 280) + 'px'
                  })},[_c('mediaEditLinkElementSK',{attrs:{"icon":'Mail',"text":_vm.visibleEmail === null ? 'skProfileShowEmail' : _vm.visibleEmail,"captchaCheck":true,"mailIsVisible":_vm.visibleEmail === null ? false : true},on:{"openCaptcha":function($event){_vm.showCaptchaModal = true}}})],1),(_vm.currentProfile.url !== '')?_c('div',{style:({
                    marginBottom: _vm.gMDynamicSize(14, 375, 280) + 'px'
                  })},[_c('mediaEditLinkElementSK',{attrs:{"icon":'Web',"text":_vm.currentProfile.url}})],1):_vm._e(),(_vm.currentProfile.hasPhone || _vm.isOwnProfile)?_c('div',{style:({
                    marginBottom: _vm.gMDynamicSize(14, 375, 280) + 'px'
                  })},[_c('mediaEditLinkElementSK',{attrs:{"icon":'Tel',"text":_vm.visiblePhone === null ? 'skProfileShowPhone' : _vm.visiblePhone,"captchaCheck":true,"selectable":_vm.visiblePhone === null ? false : true},on:{"openCaptcha":function($event){_vm.showCaptchaModal = true}}})],1):_vm._e(),(_vm.currentProfile.twitterLink !== '')?_c('div',{style:({
                    marginBottom: _vm.gMDynamicSize(14, 375, 280) + 'px'
                  })},[_c('mediaEditLinkElementSK',{attrs:{"icon":'Twitter',"text":_vm.currentProfile.twitterLink}})],1):_vm._e(),(_vm.currentProfile.instaLink !== '')?_c('div',{style:({
                    marginBottom: _vm.gMDynamicSize(14, 375, 280) + 'px'
                  })},[_c('mediaEditLinkElementSK',{attrs:{"icon":'Insta',"text":_vm.currentProfile.instaLink}})],1):_vm._e(),(_vm.currentProfile.linkedInLink !== '')?_c('div',{style:({
                    marginBottom: _vm.gMDynamicSize(14, 375, 280) + 'px'
                  })},[_c('mediaEditLinkElementSK',{attrs:{"icon":'Edin',"text":_vm.currentProfile.linkedInLink}})],1):_vm._e(),(_vm.currentProfile.fbLink !== '')?_c('div',{style:({
                    marginBottom: _vm.gMDynamicSize(14, 375, 280) + 'px'
                  })},[_c('mediaEditLinkElementSK',{attrs:{"icon":'Fb',"text":_vm.currentProfile.fbLink}})],1):_vm._e(),(_vm.currentProfile.xingLink !== '')?_c('div',{style:({
                    marginBottom: _vm.gMDynamicSize(14, 375, 280) + 'px'
                  })},[_c('mediaEditLinkElementSK',{attrs:{"icon":'Xing',"text":_vm.currentProfile.xingLink}})],1):_vm._e(),(_vm.currentProfile.pinterestLink !== '')?_c('div',{style:({
                    marginBottom: _vm.gMDynamicSize(14, 375, 280) + 'px'
                  })},[_c('mediaEditLinkElementSK',{attrs:{"icon":'Pinterest',"text":_vm.currentProfile.pinterestLink}})],1):_vm._e()],1):_vm._e(),_c('div',{style:({
                  marginTop: 39 + 'px',
                  marginBottom: _vm.isMobile ? 12 + 'px' : 23 + 'px'
                })},[(_vm.currentProfile.bio !== '')?_c('H2SK',{attrs:{"text":'skProfileCuriculumBreve'}}):_vm._e()],1),_c('div',{style:({
                  marginBottom: _vm.isMobile ? 0 + 'px' : 62 + 'px'
                })},[_c('P1SK',{attrs:{"text":_vm.currentProfile.bio,"selectable":true}})],1),_c('div',[(_vm.isFirstEdit)?_c('P1SK',{attrs:{"text":'skProfileNoProfileYet'}}):_vm._e()],1)]),_c('div',{style:({
                marginLeft: _vm.appWidth > 660 ? 14 + 'px' : 17 + 'px',
                marginRight: 14 + 'px',
                flex: _vm.isMobile ? 1 : 0
              })},[_c('div',{style:({
                  marginTop: _vm.appWidth > 927 ? 165 + 'px' : 20 + 'px',
                  marginBottom: _vm.isMobile ? 20 + 'px' : 0 + 'px'
                })},[(_vm.getCategories(_vm.currentProfile.categories).length > 0)?_c('div',{style:({
                    marginBottom: 5 + 'px'
                  })},_vm._l((_vm.getCategories(_vm.currentProfile.categories)),function(item){return _c('chipElementSK',{key:item.nr,style:({
                      display: 'inline-flex',
                      marginBottom: _vm.isMobile ? 5 + 'px' : 20 + 'px'
                    }),attrs:{"text":item.userLanguage,"hovorable":true},on:{"click":function($event){_vm.updateSelectedCategories({
                        categoryObj: item,
                        maxSelection: 1
                      }),
                        _vm.$router.push({ name: 'homePage' })}}})}),1):_vm._e(),(
                    _vm.getExpertises(_vm.currentProfile.expertise).length > 0 &&
                    _vm.getExpertises(_vm.currentProfile.expertise)[0] !== ''
                  )?_c('div',_vm._l((_vm.getExpertises(_vm.currentProfile.expertise)),function(expertise,index){return _c('chipElementSK',{key:index,style:({
                      display: 'inline-flex',
                      marginBottom: _vm.isMobile ? 5 + 'px' : 20 + 'px',
                      cursor: 'pointer'
                    }),attrs:{"text":expertise,"isExpertise":true},on:{"click":function($event){return _vm.searchExpertise(expertise)}}})}),1):_vm._e()])])])]):_vm._e(),(_vm.appWidth > 1230)?_c('div',{style:({
            background: 'white',
            flex: 1,
            height: _vm.gMDynamicSize(273, 1441, 1000) + 'px'
          })}):_vm._e()])])]),_c('b-modal',{style:({
      maxWidth: 780 + 'px',
      height: _vm.appHeight - 2 + 'px',
      margin: 'auto',
      border: '1px solid'
    }),attrs:{"full-screen":"","active":_vm.showEditorModal,"scroll":"keep","can-cancel":false},on:{"update:active":function($event){_vm.showEditorModal=$event}}},[_c('EditorModalSK',{attrs:{"isFirstEdit":_vm.isFirstEdit}})],1),_c('b-modal',{style:({
      maxWidth: 600 + 'px',
      height: _vm.appWidth < 484 ? _vm.appHeight - 2 + 'px' : 655 + 'px',
      margin: 'auto',
      border: '1px solid'
    }),attrs:{"full-screen":"","active":_vm.showCaptchaModal,"scroll":"keep","can-cancel":false},on:{"update:active":function($event){_vm.showCaptchaModal=$event}}},[_c('CaptchaModalSK',{on:{"close":function($event){_vm.showCaptchaModal = false},"captchaSolved":function($event){return _vm.resolveCaptcha($event)}}})],1),_c('b-modal',{style:({
      maxWidth: 416 + 'px',
      height: _vm.appWidth < 484 ? _vm.appHeight - 2 + 'px' : 655 + 'px',
      margin: 'auto',
      border: '1px solid'
    }),attrs:{"full-screen":"","active":_vm.showPictureUploadModal,"scroll":"keep","can-cancel":false,"has-modal-card":true},on:{"update:active":function($event){_vm.showPictureUploadModal=$event}}},[_c('PictureUploadModalSK')],1),_c('b-modal',{style:({
      maxWidth: 718 + 'px',
      height: _vm.appHeight - 2 + 'px',
      margin: 'auto',
      border: '1px solid'
    }),attrs:{"full-screen":"","active":_vm.showPrivacyPolicyModal,"scroll":"keep","can-cancel":false,"has-modal-card":true},on:{"update:active":function($event){_vm.showPrivacyPolicyModal=$event}}},[_c('PrivacyPolicyModalSK')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }