var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({ maxWidth: 1118 + 'px', margin: 'auto' })},[_c('h1',{class:[_vm.isMobile ? 'sk-font-bold' : 'sk-font-regular'],style:({
      marginRight: _vm.isMobile ? _vm.gMDynamicSize(17) + 'px' : 54 + 'px',
      marginLeft: _vm.isMobile ? _vm.gMDynamicSize(15) + 'px' : 54 + 'px',
      marginTop: 69 + 'px',
      marginBottom: _vm.isMobile ? _vm.gMDynamicSize(14) + 'px' : 20 + 'px',
      fontSize: _vm.isMobile ? _vm.gMDynamicSize(30) + 'px' : 60 + 'px',
      lineHeight: _vm.isMobile ? _vm.gMDynamicSize(30) + 'px' : 70 + 'px',
      textAlign: 'center',
      color: '#F95268',
      cursor: _vm.translateMode ? 'pointer' : 'default'
    }),on:{"click":function($event){_vm.translateMode ? _vm.openEditTranslationModal('skIntroductionExpertPool') : ''}}},[_vm._v(" "+_vm._s(_vm.getDixTranslation('skIntroductionExpertPool'))+" ")]),_c('H2SK',{style:({
      marginRight: _vm.isMobile ? _vm.gMDynamicSize(17) + 'px' : 54 + 'px',
      marginLeft: _vm.isMobile ? _vm.gMDynamicSize(17) + 'px' : 54 + 'px',
      marginBottom: _vm.isMobile ? _vm.gMDynamicSize(9) + 'px' : 25 + 'px'
    }),attrs:{"text":'skIntroductionTypes',"text-align-center":true}}),_c('div',{style:({
      marginRight: _vm.isMobile ? _vm.gMDynamicSize(17) + 'px' : 90 + 'px',
      marginLeft: _vm.isMobile ? _vm.gMDynamicSize(15) + 'px' : 90 + 'px',
      textAlign: 'center',
      lineHeight: 0 + 'px'
    })},[_c('H2SK',{style:({
        display: 'inline'
      }),attrs:{"text":'skIntroductionFindOne'}}),_c('H2SK',{staticClass:"selected-category",style:({
        display: 'inline',
        cursor: 'pointer'
      }),attrs:{"text":'skIntroductionFindTwo'},on:{"click":function($event){return _vm.signIn()}}}),_c('H2SK',{style:({
        display: 'inline'
      }),attrs:{"text":'skIntroductionFindThree'}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }