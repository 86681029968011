// tslint:disable: no-shadowed-variable
import { GetterTree, MutationTree, ActionContext, ActionTree } from 'vuex'
import { Istate } from '../types/dropdownTypes'

const state: Istate = {
  dropdowns: [],
  allCategories: [],
  mainCategories: [],
  subCategories: [],
  locations: [],
  languages: [],
  corrlanguages: [],
  selectedCategories: [],
  selectedLanguages: [],
  selectedLocations: [],
  filterHasPhone: false,
  autoComplete: []
}

const actions: ActionTree<Istate, any> = {
  updateSelectedCategories: (
    { commit },
    {
      categoryObj,
      maxSelection
    }: {
      categoryObj: any
      maxSelection: number
    }
  ) => {
    // reset selection on Category Change
    state.selectedLocations = []
    state.selectedLanguages = []

    let isAlreadySelected = false
    if (state.selectedCategories.length > 0) {
      const foundObj = state.selectedCategories.find((obj: any) => {
        return obj.nr === categoryObj.nr
      })
      if (foundObj) {
        isAlreadySelected = true
      }
    }

    if (isAlreadySelected) {
      state.selectedCategories = state.selectedCategories.filter((obj: any) => {
        return obj.nr !== categoryObj.nr
      })
    } else if (state.selectedCategories.length < maxSelection) {
      state.selectedCategories.push(categoryObj)
    } else {
      state.selectedCategories = state.selectedCategories.slice(1)
      state.selectedCategories.push(categoryObj)
    }

    // if (maxSelection === 1 && state.selectedCategories[0].nr !== categoryObj.nr) {
    //   state.selectedCategories = [categoryObj]
    // }
  }
}

const mutations: MutationTree<Istate> = {
  resetSelectedCategories: (state) => {
    state.selectedCategories = []
  },
  setDropdowns: (state, dropdowns: any) => {
    state.dropdowns = state.dropdowns.concat(dropdowns)
  },
  setAllCategories: (state, categories: any) => {
    state.allCategories = state.allCategories.concat(categories)
  },
  setMainCategories: (state, categories: any) => {
    state.mainCategories = state.mainCategories.concat(categories)
  },
  setSubCategories: (state, categories: any) => {
    state.subCategories = state.subCategories.concat(categories)
  },
  setLocations: (state, locations: any) => {
    state.locations = state.locations.concat(locations)
  },
  setLanguages: (state, languages: any) => {
    state.languages = state.languages.concat(languages)
  },
  setCorrlanguages: (state, corrlanguages: any) => {
    state.corrlanguages = state.corrlanguages.concat(corrlanguages)
  },
  setAutoComplete: (state, autoComplete: any) => {
    state.autoComplete = state.autoComplete.concat(autoComplete)
  },
  changeSelectedLanguages: (state, obj: any) => {
    if (state.selectedLanguages.length > 0 && state.selectedLanguages[0].nr === obj.nr) {
      state.selectedLanguages = []
    } else {
      state.selectedLanguages = [obj]
    }
  },
  resetSelectedLanguages: (state) => {
    state.selectedLanguages = []
  },
  changeSelectedLocations: (state, obj: any) => {
    if (state.selectedLocations.length > 0 && state.selectedLocations[0].nr === obj.nr) {
      state.selectedLocations = []
    } else {
      state.selectedLocations = [obj]
    }
  },
  resetSelectedLocations: (state) => {
    state.selectedLocations = []
  },
  changeFilterHasPhone: (state) => {
    state.filterHasPhone = !state.filterHasPhone
  }
}

const getters: GetterTree<Istate, any> = {
  dropdowns() {
    return state.dropdowns
  },
  allCategories() {
    return state.allCategories
  },
  mainCategories() {
    return state.mainCategories
  },
  getSubCategories: (state, rootGetters) => (categories: string) => {
    let filtered = []
    if (rootGetters.subCategories && categories) {
      filtered = rootGetters.subCategories.filter((category: any) => {
        return categories.includes(`|${category.nr.toString()}|`)
      })
    }
    return filtered
  },
  getMainCategories: (state, rootGetters) => (categories: string) => {
    let filtered = []
    if (rootGetters.mainCategories && categories) {
      filtered = rootGetters.mainCategories.filter((category: any) => {
        return categories.includes(`|${category.nr.toString()}|`)
      })
    }
    return filtered
  },
  getCategories: (state, rootGetters) => (categories: string) => {
    let filtered = []
    if (rootGetters.mainCategories && rootGetters.subCategories && categories) {
      const allCategories = rootGetters.mainCategories.concat(rootGetters.subCategories)
      filtered = allCategories.filter((category: any) => {
        return categories.includes(`|${category.nr.toString()}|`)
      })
    }
    return filtered
  },
  subCategories() {
    return state.subCategories
  },
  locations() {
    return state.locations
  },
  getLocations: (state, rootGetters) => (locationsString: string) => {
    let filtered = []

    if (rootGetters.locations && locationsString) {
      filtered = rootGetters.locations.filter((location: any) => {
        return locationsString.includes(`|${location.userLanguage}|`)
      })
    }
    return filtered
  },
  languages() {
    return state.languages
  },
  getLanguages: (state, rootGetters) => (languagesString: string) => {
    let filtered = []

    if (rootGetters.languages && languagesString) {
      filtered = rootGetters.languages.filter((language: any) => {
        return languagesString.includes(`|${language.nr.toString()}|`)
      })
    }
    return filtered
  },
  corrlanguages() {
    return state.corrlanguages
  },
  getCorrLanguages: (state, rootGetters) => (languagesString: string) => {
    let filtered = []

    if (rootGetters.corrLanguages && languagesString) {
      filtered = rootGetters.corrLanguages.filter((language: any) => {
        return languagesString.includes(`|${language.nr.toString()}|`)
      })
    }
    return filtered
  },
  selectedCategories() {
    return state.selectedCategories
  },
  selectedLanguages() {
    return state.selectedLanguages
  },
  selectedLocations() {
    return state.selectedLocations
  },
  autoComplete() {
    return state.autoComplete
  },
  filterHasPhone() {
    return state.filterHasPhone
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
