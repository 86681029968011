var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.editorItem && _vm.editingItem)?_c('div',{staticClass:"ion-page shsViewPort"},[_c('ion-content',{attrs:{"fullscreen":""}},[_c('headerSHS',{staticClass:"shs-header",attrs:{"show-back":_vm.currentIndex !== 1 && this.$route.params.editingItemIsInvalid === 'true',"show-cancel":this.$route.params.editingItemIsInvalid === 'false'},on:{"backClick":function($event){_vm.setTransition('amasysGoBack'), _vm.$router.go(-1)}}}),_c('textContainerSHS',[_c('titleSHS',{attrs:{"text":this.$route.params.editingItemIsInvalid === 'true'
            ? _vm.getDixTranslation('editorTitleOnboarding') +
              ' ' +
              _vm.currentIndex +
              '/' +
              _vm.editorList.length
            : _vm.editorItem.toolText}}),_c('textSHS',{attrs:{"text":_vm.editorItem.info}})],1),_c('linkContainerSHS',[(_vm.currentDropdowns.length > 0 && _vm.currentDropdowns)?_c('ion-list',[_c('ion-radio-group',{attrs:{"value":_vm.currentValue},on:{"ionChange":function($event){return _vm.setEditing($event.detail.value)}}},_vm._l((_vm.currentDropdowns),function(item,index){return _c('ion-item',{key:index,attrs:{"color":"secondary"}},[_c('ion-label',{style:({ minHeight: (_vm.appWidth - 45) / 5.7 + 'px' })},[_c('div',{staticClass:"container",style:({
                  fontSize: _vm.appWidth / (375 / 22) + 'px',
                  lineHeight: _vm.appWidth / (375 / 27) + 'px'
                })},[_c('div',{style:(_vm.textStyle)},[_vm._v(_vm._s(_vm.getDixTranslation(item.userLanguage)))])])]),_c('ion-radio',{attrs:{"slot":"start","color":"success","value":item.nr},slot:"start"})],1)}),1)],1):_vm._e()],1),_c('buttonContainerSHS',{style:({ paddingTop: _vm.appWidth / (375 / 30) + 'px' })},[_c('buttonSHS',{attrs:{"disabled":_vm.$v.editing.$invalid,"text":this.$route.params.editingItemIsInvalid === 'true' ? 'buttonNext' : 'buttonOk'},on:{"click":function($event){return _vm.emitEditing()}}})],1),(
        _vm.$route.params.editorName == 'firstLogin' ||
        _vm.$route.params.editorName == 'assessmentSettings'
      )?_c('linkContainerSHS',[_c('linkSHS',{attrs:{"text":'editorLinkGeneralExplanation'},on:{"click":function($event){_vm.setTransition('amasysForward'),
            _vm.$router.push({
              name: 'faqOverlay',
              params: {
                toolText: 'editorLinkGeneralExplanation'
              }
            })}}}),_c('linkSHS',{attrs:{"text":'editorLinkDataUsage'},on:{"click":function($event){_vm.setTransition('amasysForward'),
            _vm.$router.push({
              name: 'faqOverlay',
              params: {
                toolText: 'editorLinkDataUsage'
              }
            })}}})],1):_vm._e(),(_vm.$route.params.editorName == 'mainInfoAdd')?_c('linkContainerSHS',[_c('linkSHS',{attrs:{"text":'mainInfoAddCancelLink'},on:{"click":function($event){_vm.setTransition('amasysGoBack'),
            _vm.$router.push({
              name: 'dashboardCounterspeech'
            })}}})],1):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }