<template>
  <div class="ion-page skViewPort">
    <ion-header v-if="!isMobile" no-border>
      <RegularHeaderSK :show-menu="true"> </RegularHeaderSK>
    </ion-header>
    <ion-content :class="contentIsHidden ? 'hide-content-sk' : 'show-content-sk'">
      <div
        :style="{
          background: 'rgba(169, 206, 162, 0.12)',
          paddingBottom: gMDynamicSize(109) + 'px'
        }"
      >
        <div
          class="flex-wrap-container"
          :style="{
            marginTop: gMDynamicSize(48) + 'px'
          }"
        >
          <div
            v-if="appWidth > 1230"
            :style="{
              background: 'white',
              flex: 1,
              height: gMDynamicSize(273, 1441, 1000) + 'px'
            }"
          />

          <div
            :style="{
              background: 'white',
              width: 100 + '%',
              maxWidth: 1180 + 'px',
              position: 'relative'
            }"
          >
            <img
              :style="{
                cursor: 'pointer',
                position: 'absolute',
                right: gMDynamicSize(15) + 'px'
              }"
              @click="goToHomePage()"
              src="../assets/SKicons/closeBlue.svg"
              :width="gMDynamicSize(30) + 'px'"
              :height="gMDynamicSize(30) + 'px'"
            />
            <H1SK
              :style="{
                marginLeft: isMobile
                  ? gMDynamicSize(17) + 'px'
                  : gMDynamicSize(164, 1180, 414) + 'px',
                marginBottom: gMDynamicSize(46) + 'px'
              }"
              :bold="true"
              :text="'skProjectIntoTitle'"
            />
            <H2SK
              class="sk-hover-red"
              :style="{
                marginLeft: isMobile
                  ? gMDynamicSize(17) + 'px'
                  : gMDynamicSize(164, 1180, 414) + 'px',
                marginBottom: gMDynamicSize(13) + 'px'
              }"
              :italic="true"
              :text="'skProjectIntoWhyTitle'"
            />
            <P1SK
              :style="{
                marginLeft: isMobile
                  ? gMDynamicSize(17) + 'px'
                  : gMDynamicSize(273, 1180, 414) + 'px',
                marginRight: isMobile
                  ? gMDynamicSize(17) + 'px'
                  : gMDynamicSize(273, 1180, 414) + 'px',
                marginBottom: gMDynamicSize(16) + 'px'
              }"
              :text="'skProjectIntoWhyTextOne'"
            />
            <P1SK
              :style="{
                marginLeft: isMobile
                  ? gMDynamicSize(17) + 'px'
                  : gMDynamicSize(273, 1180, 414) + 'px',
                marginRight: isMobile
                  ? gMDynamicSize(17) + 'px'
                  : gMDynamicSize(273, 1180, 414) + 'px',
                marginBottom: gMDynamicSize(16) + 'px'
              }"
              :text="'skProjectIntoWhyTextTwo'"
            />
            <P1SK
              :style="{
                marginLeft: isMobile
                  ? gMDynamicSize(17) + 'px'
                  : gMDynamicSize(273, 1180, 414) + 'px',
                marginRight: isMobile
                  ? gMDynamicSize(17) + 'px'
                  : gMDynamicSize(273, 1180, 414) + 'px',
                marginBottom: gMDynamicSize(16) + 'px'
              }"
              :text="'skProjectIntoWhyTextThree'"
            />
            <P1SK
              :style="{
                marginLeft: isMobile
                  ? gMDynamicSize(17) + 'px'
                  : gMDynamicSize(273, 1180, 414) + 'px',
                marginRight: isMobile
                  ? gMDynamicSize(17) + 'px'
                  : gMDynamicSize(273, 1180, 414) + 'px',
                marginBottom: gMDynamicSize(16) + 'px'
              }"
              :text="'skProjectIntoWhyTextFour'"
            />
            <P1SK
              :style="{
                marginLeft: isMobile
                  ? gMDynamicSize(17) + 'px'
                  : gMDynamicSize(273, 1180, 414) + 'px',
                marginRight: isMobile
                  ? gMDynamicSize(17) + 'px'
                  : gMDynamicSize(273, 1180, 414) + 'px',
                marginBottom: gMDynamicSize(30) + 'px'
              }"
              :text="'skProjectIntoWhyTextFive'"
            />
            <div
              :style="{
                marginLeft: isMobile
                  ? gMDynamicSize(17) + 'px'
                  : gMDynamicSize(273, 1180, 414) + 'px',
                marginRight: isMobile
                  ? gMDynamicSize(17) + 'px'
                  : gMDynamicSize(273, 1180, 414) + 'px',
                marginBottom: gMDynamicSize(86) + 'px'
              }"
            >
              <img
                :style="{
                  marginRight: gMDynamicSize(34) + 'px',
                  marginTop: gMDynamicSize(16) + 'px'
                }"
                :src="currentPostImg"
              />
              <img
                :style="{
                  marginTop: gMDynamicSize(16) + 'px'
                }"
                :src="currentMigrosImg"
              />
            </div>
            <H2SK
              class="sk-hover-red"
              :style="{
                marginLeft: isMobile ? gMDynamicSize(17) + 'px' : 164 + 'px',
                marginBottom: gMDynamicSize(13) + 'px'
              }"
              :italic="true"
              :text="'skProjectIntoWhoTitle'"
            />
            <P1SK
              :style="{
                marginLeft: isMobile
                  ? gMDynamicSize(17) + 'px'
                  : gMDynamicSize(273, 1180, 414) + 'px',
                marginRight: isMobile
                  ? gMDynamicSize(17) + 'px'
                  : gMDynamicSize(273, 1180, 414) + 'px',
                marginBottom: gMDynamicSize(30) + 'px'
              }"
              :text="'skProjectIntoWhoTextOne'"
            />
            <div
              :style="{
                marginLeft: isMobile
                  ? gMDynamicSize(17) + 'px'
                  : gMDynamicSize(273, 1180, 414) + 'px',
                marginRight: isMobile
                  ? gMDynamicSize(17) + 'px'
                  : gMDynamicSize(273, 1180, 414) + 'px',
                marginBottom: gMDynamicSize(86) + 'px'
              }"
            >
              <img src="../assets/SKpngs/allianceF_Logo.png" />
            </div>
          </div>
          <div
            v-if="appWidth > 1230"
            :style="{
              background: 'white',
              flex: 1,
              height: gMDynamicSize(273, 1441, 1000) + 'px'
            }"
          />
        </div>
      </div>
      <ImpressumElementSK />
    </ion-content>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import RegularHeaderSK from '../components/bodies/RegularHeaderSK.vue'
import ImpressumElementSK from '../components/elements/ImpressumElementSK.vue'

export default {
  components: {
    RegularHeaderSK,
    ImpressumElementSK
  },
  computed: {
    ...mapGetters(['userLanguageDd', 'contentIsHidden', 'isMobile', 'appWidth', 'gMDynamicSize']),
    currentPostImg() {
      let path = require('@/assets/SKpngs/post_de.png')
      if (this.userLanguageDd === 2) {
        path = require('@/assets/SKpngs/post_fr.png')
      } else if (this.userLanguageDd === 3) {
        path = require('@/assets/SKpngs/post_it.png')
      } else if (this.userLanguageDd === 4) {
        path = require('@/assets/SKpngs/post_en.png')
      }
      return path
    },
    currentMigrosImg() {
      let path = require('@/assets/SKpngs/migros_de.png')
      if (this.userLanguageDd === 2) {
        path = require('@/assets/SKpngs/migros_fr.png')
      } else if (this.userLanguageDd === 3) {
        path = require('@/assets/SKpngs/migros_it.png')
      } else if (this.userLanguageDd === 4) {
        path = require('@/assets/SKpngs/migros_en.png')
      }
      return path
    }
  },
  methods: {
    goToHomePage() {
      this.$router.push({
        name: 'homePage'
      })
    }
  }
}
</script>

<style scoped>
.flex-wrap-container {
  display: flex;
  flex-wrap: wrap;
}
</style>
