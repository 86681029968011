<template>
  <div
    :style="{
      maxWidth: gMDynamicSize(500, 414, 280) + 'px',
      paddingRight: gMDynamicSize(35, 414, 280) + 'px'
    }"
  >
    <P2SK
      :style="{ marginBottom: gMDynamicSize(10) + 'px' }"
      :text="'skEditorCVLebenslauf'"
      :bold="true"
    />

    <P2SK :style="{ marginBottom: gMDynamicSize(10) + 'px' }" :text="'skEditorCVBio'" />
    <textareaInputSK :editorItem="getEditorItem('bio')" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  props: {
    editors: {
      type: Array
    }
  },
  computed: {
    ...mapGetters(['gMDynamicSize'])
  },
  methods: {
    getEditorItem(field) {
      if (this.editors) {
        return this.editors.find((editorItem) => {
          return editorItem.field.toLowerCase() === field.toLowerCase()
        })
      }
    }
  }
}
</script>
