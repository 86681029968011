<template>
  <div id="app">
    <ion-app v-if="appWidth && appHeight">
      <MenuOverlaySK />

      <transition :name="transition">
        <ion-vue-router :key="$route.fullPath" />
      </transition>

      <b-modal
        :active.sync="showEmailFeedbackModal"
        :style="{
          maxWidth: 600 + 'px',
          height: appWidth < 484 ? appHeight + 'px' : 700 + 'px',
          margin: 'auto',
          border: '1px solid'
        }"
        full-screen
        scroll="keep"
        :can-cancel="false"
      >
        <EmailFeedbackModalSK />
      </b-modal>
      <b-modal
        :active.sync="showEditTranslationModal"
        :style="{
          maxWidth: 600 + 'px',
          height: appWidth < 484 ? appHeight + 'px' : 700 + 'px',
          margin: 'auto',
          border: '1px solid'
        }"
        full-screen
        scroll="keep"
        :can-cancel="false"
      >
        <EditTranslationModalSK />
      </b-modal>
      <b-loading
        style="background: white"
        :is-full-page="true"
        v-model="isLoading"
        :can-cancel="true"
      >
        <div :style="{ flexDirection: 'column', display: 'flex' }">
          <img
            style="z-index: 1"
            src="./assets/sheknows_logo.png"
            :width="gMDynamicSize(256) + 'px'"
            :height="gMDynamicSize(229) + 'px'"
          />
          <div class="level-item">
            <b-icon pack="fas" icon="spinner" size="is-large" custom-class="fa-spin"> </b-icon>
          </div>
        </div>
      </b-loading>
    </ion-app>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { LoadingProgrammatic as Loading } from 'buefy'
import localforage from 'localforage'
import axiosCRUD from './axios/axiosCRUD'
import MenuOverlaySK from './components/overlays/MenuOverlaySK.vue'
import EmailFeedbackModalSK from './components/modals/EmailFeedbackModalSK.vue'
import EditTranslationModalSK from './components/modals/EditTranslationModalSK.vue'

export default {
  components: {
    MenuOverlaySK,
    EmailFeedbackModalSK,
    EditTranslationModalSK
  },
  data() {
    return {
      isLoading: false,
      loading: null
    }
  },
  watch: {
    picCacheArr(arr) {
      localforage.setItem('picCacheArr', arr)
    }
  },
  mounted() {
    localforage.getItem('picCacheArr').then((picCacheArr) => {
      if (picCacheArr && picCacheArr.length > 0) {
        this.setPicCacheArr(picCacheArr)
      }
    })
    this.isLoading = true
    // this.Loading = Loading.open({
    //   isFullPage: true,
    //   canCancel: false
    // })

    localforage.getItem('installedappversion').then((localStorageVersion) => {
      const newestVersion = process.env.VUE_APP_VERSION
      // console.log(newestVersion, localStorageVersion)
      if (!localStorageVersion || localStorageVersion === '') {
        localforage.setItem('installedappversion', newestVersion).then(() => {
          window.location.reload(true)
        })
      } else if (localStorageVersion !== newestVersion) {
        localforage.setItem('installedappversion', newestVersion).then(() => {
          window.location.reload(true)
          // window.history.forward(1)
        })
      } else {
        this.initApp()
        console.log('newest Version installed: ', newestVersion)
      }
    })
  },
  created() {
    this.setAppHeightWidth()

    window.addEventListener(
      'resize',
      () => {
        if ('standalone' in window.navigator && window.navigator.standalone) {
          setTimeout(() => {
            this.changeAppWidth(window.innerWidth)
            this.changeAppHeight(window.innerHeight)
            const vhStandalone = window.innerHeight * 0.01
            document.documentElement.style.setProperty('--vh', `${vhStandalone}px`)
          }, 500)
        } else {
          this.changeAppWidth(window.innerWidth)
          this.changeAppHeight(window.innerHeight)

          // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
          const vh = window.innerHeight * 0.01
          // Then we set the value in the --vh custom property to the root of the document
          document.documentElement.style.setProperty('--vh', `${vh}px`)
        }
      },
      false
    )
  },
  computed: {
    ...mapGetters([
      'gMDynamicSize',
      'picCacheArr',
      'showEditTranslationModal',
      'showCategorySelectModal',
      'showEmailFeedbackModal',
      'maxAppWidth',
      'transition',
      'appHeight',
      'appWidth',
      'hasProfile',
      'isFirstLogin',
      'editingItem',
      'dataFinishedLoading',
      'profile',
      'userLanguageDd'
    ])
  },
  methods: {
    ...mapActions(['continueSession', 'checkAuth']),
    ...mapMutations([
      'setPicCacheArr',
      'setCorrlanguages',
      'setAutoComplete',
      'setExperts',
      'pushExperts',
      'setDix',
      'setDropdowns',
      'setAllCategories',
      'setMainCategories',
      'setSubCategories',
      'setLocations',
      'setLanguages',
      'setTransition',
      'resetTransition',
      'changeAppHeight',
      'changeAppWidth',
      'currentPosts',
      'currentArticle',
      'deleteEditingItem',
      'setUserLanguageDd'
    ]),
    initApp() {
      localforage
        .getItem('userlanguagenr')
        .then((value) => {
          if (!value) {
            const browserSettings = navigator.language

            if (browserSettings.toLowerCase().indexOf('fr') !== -1) {
              this.setUserLanguageDd({ languageDd: 2 }) // French
            } else if (browserSettings.toLowerCase().indexOf('it') !== -1) {
              this.setUserLanguageDd({ languageDd: 3 }) // Italian
            } else if (browserSettings.toLowerCase().indexOf('en') !== -1) {
              this.setUserLanguageDd({ languageDd: 4 }) // English
            } else {
              this.setUserLanguageDd({ languageDd: 1 }) // German
            }
            localforage.setItem('userlanguagenr', this.userLanguageDd)
          } else {
            this.setUserLanguageDd({ languageDd: value })
          }
          axiosCRUD.get(`/sheknows/dix/${this.userLanguageDd}`).then((dixList) => {
            this.setDix(dixList.data)
          })
          axiosCRUD.get('/sheknows/experts/0/2000').then((expertList) => {
            const randomOrderList = expertList.data.sort(() => Math.random() - 0.5)
            this.setExperts(randomOrderList)
            this.isLoading = false
            // this.Loading.close()
            // axiosCRUD.get(`/sheknows/experts/20/2000`).then((secondExpertList) => {
            //   this.pushExperts(secondExpertList.data)
            //   console.log(secondExpertList.data)
            // })
          })

          axiosCRUD.get(`/sheknows/dropdowns/${this.userLanguageDd}`).then((dropdownList) => {
            const dropdowns = dropdownList.data

            const locations = dropdowns.filter((item) => {
              return item.dropdown.toLowerCase() === 'sheknowslocationdd'
            })
            const languages = dropdowns.filter((item) => {
              return item.dropdown.toLowerCase() === 'sheknowslanguagesdd'
            })
            const corrlanguages = dropdowns.filter((item) => {
              return item.dropdown.toLowerCase() === 'sheknowscorrlanguagedd'
            })
            const maincategories = dropdowns.filter((item) => {
              return item.dropdown.toLowerCase() === 'sheknowscategorymaindd'
            })
            const subcategories = dropdowns.filter((item) => {
              return item.dropdown.toLowerCase() === 'sheknowscategorysubdd'
            })
            this.setLanguages(languages)
            this.setLocations(locations)
            this.setCorrlanguages(corrlanguages)
            this.setMainCategories(maincategories)
            this.setSubCategories(subcategories)

            axiosCRUD.get(`/sheknows/autocompletelist`).then((autocompletelist) => {
              const auto = autocompletelist.data

              // add categories
              maincategories.forEach((item) => {
                if (item.nrOfExperts > 0) {
                  const obj = {}
                  obj.contentType = 'skAutocompleteCategory'
                  obj.label = item.userLanguage

                  auto.push(obj)
                }
              })
              subcategories.forEach((item) => {
                if (item.nrOfExperts > 0) {
                  const obj = {}
                  obj.contentType = 'skAutocompleteCategory'
                  obj.label = item.userLanguage

                  auto.push(obj)
                }
              })

              this.setAutoComplete(auto)
            })
          })
        })
        .catch((err) => {
          console.log('error', err)
        })
    },
    setAppHeightWidth() {
      if ('standalone' in window.navigator && window.navigator.standalone) {
        this.changeAppWidth(window.innerWidth)
        this.changeAppHeight(window.innerHeight)
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        const vhStandalone = window.innerHeight * 0.01
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vhStandalone}px`)
      } else {
        this.changeAppWidth(window.innerWidth)
        this.changeAppHeight(window.innerHeight)
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        const vh = window.innerHeight * 0.01
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`)
      }
    },
    checkOrientationChange() {
      const screenOrientation = window.orientation
      switch (screenOrientation) {
        case 0:
          window.scrollTo(0, 0)
          document.body.scrollTop = 0
          setTimeout(() => {
            window.scrollTo(0, 0)
            document.body.scrollTop = 0
          }, 500)
          break
        case 90:
          window.scrollTo(0, 0)
          document.body.scrollTop = 0
          setTimeout(() => {
            window.scrollTo(0, 0)
            document.body.scrollTop = 0
          }, 500)
          break
        case 180:
          window.scrollTo(0, 0)
          document.body.scrollTop = 0
          setTimeout(() => {
            window.scrollTo(0, 0)
            document.body.scrollTop = 0
          }, 500)
          break
        case 270:
          window.scrollTo(0, 0)
          document.body.scrollTop = 0
          setTimeout(() => {
            window.scrollTo(0, 0)
            document.body.scrollTop = 0
          }, 500)
          break
        default:
          console.log('implementation of screen orientation')
      }
    }
  }
}
</script>

<style>
@supports (padding: max(0px)) {
  body,
  header,
  footer {
    padding-left: min(0vmin, env(safe-area-inset-left));
    padding-right: min(0vmin, env(safe-area-inset-right));
  }
}
@import url('https://use.typekit.net/itf7syh.css');
.ion-navbar.toolbar.toolbar-ios.statusbar-padding,
.ion-navbar.toolbar-ios .ion-title.title-ios,
.ion-toolbar.toolbar.toolbar-ios.statusbar-padding,
.ion-toolbar.toolbar-ios .ion-title.title-ios {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}

.ion-toolbar {
  --background-color: transparent;
  --ion-color-base: transparent !important;
}
:root {
  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 255, 255, 255;

  --ion-color-step-50: #f2f2f2;
  --ion-color-step-100: #e6e6e6;
  --ion-color-step-150: #d9d9d9;
  --ion-color-step-200: #cccccc;
  --ion-color-step-250: #bfbfbf;
  --ion-color-step-300: #b3b3b3;
  --ion-color-step-350: #a6a6a6;
  --ion-color-step-400: #999999;
  --ion-color-step-450: #8c8c8c;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #737373;
  --ion-color-step-600: #666666;
  --ion-color-step-650: #595959;
  --ion-color-step-700: #4d4d4d;
  --ion-color-step-750: #404040;
  --ion-color-step-800: #333333;
  --ion-color-step-850: #262626;
  --ion-color-step-900: #191919;
  --ion-color-step-950: #0d0d0d;

  --ion-color-light: rgba(236, 236, 236, 1);
  --ion-color-light-rgb: 0, 102, 0;
  --ion-color-light-contrast: black;
  --ion-color-light-contrast-rgb: black;
  --ion-color-light-shade: rgba(236, 236, 236, 1);
  --ion-color-light-tint: rgba(236, 236, 236, 1);

  --ion-color-success: #29265b;
  --ion-color-success-rgb: 0, 102, 0;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #29265b;
  --ion-color-success-tint: #29265b;

  --ion-color-danger: #e61b73;
  --ion-color-danger-rgb: 0, 102, 0;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e61b73;
  --ion-color-danger-tint: #e61b73;

  --ion-color-secondary: white;
  --ion-color-secondary-rgb: 0, 102, 0;
  --ion-color-secondary-contrast: black;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: white;
  --ion-color-secondary-tint: white;

  --ion-color-tertiary: #ffe3ef;
  --ion-color-tertiary-rgb: 0, 102, 0;
  --ion-color-tertiary-contrast: black;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #ffe3ef;
  --ion-color-tertiary-tint: #ffe3ef;
}

.hide-content-sk {
  visibility: hidden;
  opacity: 0;
}
.show-content-sk {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity ease-in 325ms;
}

.lineClamp1 {
  display: '-webkit-box';
  -webkit-line-clamp: 1;
  -webkit-box-orient: 'vertical';
  overflow: 'hidden';
  text-overflow: 'ellipsis';
  text-align: 'left';
  white-space: 'normal';
}

.line-clamp-two {
  display: '-webkit-box';
  -webkit-line-clamp: 2;
  -webkit-box-orient: 'vertical';
  overflow: 'hidden';
  text-overflow: 'ellipsis';
  text-align: 'left';
  white-space: 'normal';
}

.sk-user-select-none {
  -webkit-touch-callout: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* firefox */
  -ms-user-select: none; /* IE 10+ and Edge */
  user-select: none; /* Standard syntax */
}

.sk-selectable {
  -webkit-touch-callout: default !important;
  -webkit-user-select: auto !important; /* Safari */
  -moz-user-select: auto !important; /* firefox */
  -ms-user-select: auto !important; /* IE 10+ and Edge */
  user-select: auto !important; /* Standard syntax */
}

.sk-font-regular {
  font-family: 'degular', 'sansserif';
  font-weight: 400;
  font-style: normal;
}

.sk-font-bold {
  font-family: 'degular', 'sansserif';
  font-weight: 700;
}

.sk-hover-red {
  color: #f95268 !important;
}

.skViewPort {
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  margin: 0 auto;
}
.amasys-mobile-page {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  display: flex;
  position: absolute;

  flex-direction: column;
  justify-content: space-between;

  contain: layout size style;
  overflow: hidden;
  z-index: 0;
}
.shs-header {
  background: white;
  position: sticky;
  top: 0;
  z-index: 3;
}
.sk-button {
  font-family: 'degular', 'sansserif';
  text-align: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.11);
}
.amasysViewPort {
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  margin: 0 auto;
  background: white;
}
.amasysShowMore-enter-active,
.amasysShowMore-leave-active {
  transition: opacity 0.5s;
}
.amasysShowMore-enter, .amasysShowMore-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.amasysForward-leave-active {
  transition: 0.4s;
}
.amasysForward-enter-active {
  transition: 0.4s;
}
.amasysForward-enter {
  transform: translate(100%, 0);
}
.amasysForward-leave-to {
  transform: translate(-100%, 0);
}
.amasysGoBack-leave-active {
  transition: 0.4s;
}
.amasysGoBack-enter-active {
  transition: 0.4s;
}
.amasysGoBack-enter {
  transform: translate(-100%, 0);
}
.amasysGoBack-leave-to {
  transform: translate(100%, 0);
}
.amasysGoDown-leave-active {
  transition: 0.4s;
}
.amasysGoDown-enter-active {
  transition: 0.4s;
}
.amasysGoDown-leave-to {
  transform: translate(0, -100%);
}
.amasysGoDown-enter {
  transform: translate(0, 100%);
}
.amasysGoUp-leave-active {
  transition: 0.4s;
}
.amasysGoUp-enter-active {
  transition: 0.4s;
}
.amasysGoUp-leave-to {
  transform: translate(0, 100%);
}
.amasysGoUp-enter {
  transform: translate(0, -100%);
}
</style>
