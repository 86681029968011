var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-card",style:({
    background: 'white',
    height: 100 + '%',
    flexDirection: 'column',
    display: 'flex'
  })},[_c('div',{staticClass:"modal-card-head",style:({
      paddingLeft: _vm.gMDynamicSize(33) + 'px',
      paddingRight: _vm.gMDynamicSize(33) + 'px',
      background: 'white',
      borderBottom: 'none'
    })},[_c('div',{staticClass:"media is-mobile",style:({
        width: 100 + '%',
        marginBottom: 0 + 'px'
      })},[_c('div',{staticClass:"media-left",style:({
          marginRight: 0 + 'px'
        })},[_c('H2SK',{style:({
            marginBottom: _vm.gMDynamicSize(27) + 'px'
          }),attrs:{"selectable":true,"text":'skprivacyPolicyTitel',"bold":true}})],1),_c('div',{staticClass:"media-content level-item"}),_c('div',{staticClass:"media-right",style:({
          marginLeft: 0 + 'px'
        })},[_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"src":require("./../../assets/SKicons/closeBlue.svg"),"width":_vm.gMDynamicSize(20) + 'px',"height":_vm.gMDynamicSize(20) + 'px'},on:{"click":function($event){return _vm.changeShowPrivacyPolicyModal(false)}}})])])]),_c('div',{staticClass:"modal-card-body",style:({
      padding: 0 + 'px',
      marginLeft: _vm.gMDynamicSize(33) + 'px',
      marginRight: _vm.gMDynamicSize(33) + 'px'
    })},[_c('P2SK',{style:({ marginBottom: _vm.gMDynamicSize(4) + 'px' }),attrs:{"selectable":true,"bold":true,"text":'skprivacyPolicyInfo'}}),_c('P2SK',{style:({ marginBottom: _vm.gMDynamicSize(10) + 'px' }),attrs:{"selectable":true,"text":'skprivacyPolicyWir'}}),_c('P2SK',{attrs:{"selectable":true,"text":'skprivacyPolicyIstWichtig'}}),_c('P2SK',{style:({
        marginBottom: _vm.gMDynamicSize(4) + 'px',
        marginTop: _vm.gMDynamicSize(6) + 'px'
      }),attrs:{"selectable":true,"bold":true,"text":'skprivacyPolicyAnliegenTitle'}}),_c('P2SK',{attrs:{"selectable":true,"text":'skprivacyPolicyAnliegenText'}}),_c('P2SK',{style:({ marginTop: _vm.gMDynamicSize(6) + 'px' }),attrs:{"selectable":true,"bold":true,"text":'skprivacyPolicyPerson'}}),_c('P2SK',{attrs:{"selectable":true,"text":'skprivacyPolicyPersonTitle'}}),_c('P2SK',{attrs:{"selectable":true,"text":'skprivacyPolicyStrasse'}}),_c('P2SK',{attrs:{"selectable":true,"text":'skprivacyPolicyPLZ'}}),_c('P2SK',{attrs:{"selectable":true,"text":'skprivacyPolicyTelefon'}}),_c('P2SK',{attrs:{"selectable":true,"text":'skprivacyPolicyMail'}}),_c('P2SK',{style:({
        marginBottom: _vm.gMDynamicSize(4) + 'px',
        marginTop: _vm.gMDynamicSize(6) + 'px'
      }),attrs:{"selectable":true,"text":'skprivacyPolicyErhebungTitle',"bold":true}}),_c('P2SK',{attrs:{"selectable":true,"text":'skprivacyPolicyErhebungText'}}),_c('P2SK',{style:({
        marginBottom: _vm.gMDynamicSize(4) + 'px',
        marginTop: _vm.gMDynamicSize(6) + 'px'
      }),attrs:{"selectable":true,"bold":true,"text":'skprivacyPolicyZweckTitle'}}),_c('P2SK',{attrs:{"selectable":true,"text":'skprivacyPolicyZweckText'}}),_c('P2SK',{style:({
        marginBottom: _vm.gMDynamicSize(4) + 'px',
        marginTop: _vm.gMDynamicSize(6) + 'px'
      }),attrs:{"selectable":true,"bold":true,"text":'skprivacyPolicyDritteTitle'}}),_c('P2SK',{attrs:{"selectable":true,"text":'skprivacyPolicyDritteText'}}),_c('P2SK',{style:({
        marginBottom: _vm.gMDynamicSize(4) + 'px',
        marginTop: _vm.gMDynamicSize(6) + 'px'
      }),attrs:{"selectable":true,"bold":true,"text":'skprivacyPolicyAufbewahrungTitle'}}),_c('P2SK',{attrs:{"selectable":true,"text":'skprivacyPolicyAufbewahrungText'}}),_c('P2SK',{style:({
        marginBottom: _vm.gMDynamicSize(4) + 'px',
        marginTop: _vm.gMDynamicSize(6) + 'px'
      }),attrs:{"selectable":true,"bold":true,"text":'skprivacyPolicyEinwilligungTitle'}}),_c('P2SK',{style:({ marginBottom: _vm.gMDynamicSize(10) + 'px' }),attrs:{"selectable":true,"text":'skprivacyPolicyEinwilligungText'}}),_c('P2SK',{style:({ marginBottom: _vm.gMDynamicSize(10) + 'px' }),attrs:{"selectable":true,"text":'skprivacyPolicyRückzugText'}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }