<template>
  <img
    v-if="iconSrc"
    :style="{
      verticalAlign: 'middle',
      height: 100 + '%'
    }"
    @click="$emit('click')"
    :src="iconSrc"
    :width="getDynamicSize(width) + 'px'"
    :height="getDynamicSize(height) + 'px'"
  />
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    icon: {
      type: String,
      default: 'add'
    },
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  },
  computed: {
    ...mapGetters(['appWidth', 'getDynamicSize']),
    iconSrc() {
      if (this.icon) {
        return require(`./../../assets/SKicons/${this.icon}.svg`)
      }
    }
  }
}
</script>
