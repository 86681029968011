var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ion-page skViewPort"},[(!_vm.isMobile)?_c('ion-header',{attrs:{"no-border":""}},[_c('RegularHeaderSK',{attrs:{"show-menu":true}})],1):_vm._e(),_c('ion-content',{class:_vm.contentIsHidden ? 'hide-content-sk' : 'show-content-sk'},[_c('div',{style:({
        background: 'rgba(169, 206, 162, 0.12)',
        paddingBottom: _vm.gMDynamicSize(109) + 'px'
      })},[_c('div',{staticClass:"flex-wrap-container",style:({
          marginTop: _vm.gMDynamicSize(48) + 'px'
        })},[(_vm.appWidth > 1230)?_c('div',{style:({
            background: 'white',
            flex: 1,
            height: _vm.gMDynamicSize(273, 1441, 1000) + 'px'
          })}):_vm._e(),_c('div',{style:({
            background: 'white',
            width: 100 + '%',
            maxWidth: 1180 + 'px',
            position: 'relative'
          })},[_c('img',{style:({
              cursor: 'pointer',
              position: 'absolute',
              right: _vm.gMDynamicSize(15) + 'px'
            }),attrs:{"src":require("../assets/SKicons/closeBlue.svg"),"width":_vm.gMDynamicSize(30) + 'px',"height":_vm.gMDynamicSize(30) + 'px'},on:{"click":function($event){return _vm.goToHomePage()}}}),_c('H1SK',{style:({
              marginLeft: _vm.isMobile
                ? _vm.gMDynamicSize(17) + 'px'
                : _vm.gMDynamicSize(164, 1180, 414) + 'px',
              marginBottom: _vm.gMDynamicSize(46) + 'px'
            }),attrs:{"bold":true,"text":'skFAQTitle'}}),_c('H2SK',{staticClass:"sk-hover-red",style:({
              marginLeft: _vm.isMobile
                ? _vm.gMDynamicSize(17) + 'px'
                : _vm.gMDynamicSize(164, 1180, 414) + 'px',
              marginBottom: _vm.gMDynamicSize(13) + 'px'
            }),attrs:{"italic":true,"text":'skFAQProfileCreationTitle'}}),_c('P1SK',{style:({
              marginLeft: _vm.isMobile
                ? _vm.gMDynamicSize(17) + 'px'
                : _vm.gMDynamicSize(273, 1180, 414) + 'px',
              marginRight: _vm.isMobile
                ? _vm.gMDynamicSize(17) + 'px'
                : _vm.gMDynamicSize(273, 1180, 414) + 'px',
              marginBottom: _vm.gMDynamicSize(46) + 'px'
            }),attrs:{"text":'skFAQProfileCreationText'}}),_c('H2SK',{staticClass:"sk-hover-red",style:({
              marginLeft: _vm.isMobile
                ? _vm.gMDynamicSize(17) + 'px'
                : _vm.gMDynamicSize(164, 1180, 414) + 'px',
              marginBottom: _vm.gMDynamicSize(13) + 'px'
            }),attrs:{"italic":true,"text":'skFAQPhoneTitle'}}),_c('P1SK',{style:({
              marginLeft: _vm.isMobile
                ? _vm.gMDynamicSize(17) + 'px'
                : _vm.gMDynamicSize(273, 1180, 414) + 'px',
              marginRight: _vm.isMobile
                ? _vm.gMDynamicSize(17) + 'px'
                : _vm.gMDynamicSize(273, 1180, 414) + 'px',
              marginBottom: _vm.gMDynamicSize(46) + 'px'
            }),attrs:{"text":'skFAQPhoneText'}}),_c('H2SK',{staticClass:"sk-hover-red",style:({
              marginLeft: _vm.isMobile
                ? _vm.gMDynamicSize(17) + 'px'
                : _vm.gMDynamicSize(164, 1180, 414) + 'px',
              marginBottom: _vm.gMDynamicSize(13) + 'px'
            }),attrs:{"italic":true,"text":'skFAQCategoryTitle'}}),_c('P1SK',{style:({
              marginLeft: _vm.isMobile
                ? _vm.gMDynamicSize(17) + 'px'
                : _vm.gMDynamicSize(273, 1180, 414) + 'px',
              marginRight: _vm.isMobile
                ? _vm.gMDynamicSize(17) + 'px'
                : _vm.gMDynamicSize(273, 1180, 414) + 'px',
              marginBottom: _vm.gMDynamicSize(46) + 'px'
            }),attrs:{"text":'skFAQCategoryText'}}),_c('H2SK',{staticClass:"sk-hover-red",style:({
              marginLeft: _vm.isMobile
                ? _vm.gMDynamicSize(17) + 'px'
                : _vm.gMDynamicSize(164, 1180, 414) + 'px',
              marginBottom: _vm.gMDynamicSize(13) + 'px'
            }),attrs:{"italic":true,"text":'skFAQProfileContentTitle'}}),_c('P1SK',{style:({
              marginLeft: _vm.isMobile
                ? _vm.gMDynamicSize(17) + 'px'
                : _vm.gMDynamicSize(273, 1180, 414) + 'px',
              marginRight: _vm.isMobile
                ? _vm.gMDynamicSize(17) + 'px'
                : _vm.gMDynamicSize(273, 1180, 414) + 'px',
              marginBottom: _vm.gMDynamicSize(46) + 'px'
            }),attrs:{"text":'skFAQProfileContentText'}}),_c('H2SK',{staticClass:"sk-hover-red",style:({
              marginLeft: _vm.isMobile
                ? _vm.gMDynamicSize(17) + 'px'
                : _vm.gMDynamicSize(164, 1180, 414) + 'px',
              marginBottom: _vm.gMDynamicSize(13) + 'px'
            }),attrs:{"italic":true,"text":'skFAQFindExpertTitle'}}),_c('P1SK',{style:({
              marginLeft: _vm.isMobile
                ? _vm.gMDynamicSize(17) + 'px'
                : _vm.gMDynamicSize(273, 1180, 414) + 'px',
              marginRight: _vm.isMobile
                ? _vm.gMDynamicSize(17) + 'px'
                : _vm.gMDynamicSize(273, 1180, 414) + 'px',
              marginBottom: _vm.gMDynamicSize(46) + 'px'
            }),attrs:{"text":'skFAQFindExpertText'}}),_c('H2SK',{staticClass:"sk-hover-red",style:({
              marginLeft: _vm.isMobile
                ? _vm.gMDynamicSize(17) + 'px'
                : _vm.gMDynamicSize(164, 1180, 414) + 'px',
              marginBottom: _vm.gMDynamicSize(13) + 'px'
            }),attrs:{"italic":true,"text":'skFAQWhoTitle'}}),_c('P1SK',{style:({
              marginLeft: _vm.isMobile
                ? _vm.gMDynamicSize(17) + 'px'
                : _vm.gMDynamicSize(273, 1180, 414) + 'px',
              marginRight: _vm.isMobile
                ? _vm.gMDynamicSize(17) + 'px'
                : _vm.gMDynamicSize(273, 1180, 414) + 'px',
              marginBottom: _vm.gMDynamicSize(46) + 'px'
            }),attrs:{"text":'skFAQWhoText'}}),_c('H2SK',{staticClass:"sk-hover-red",style:({
              marginLeft: _vm.isMobile
                ? _vm.gMDynamicSize(17) + 'px'
                : _vm.gMDynamicSize(164, 1180, 414) + 'px',
              marginBottom: _vm.gMDynamicSize(13) + 'px'
            }),attrs:{"italic":true,"text":'skFAQNoAnswerTitle'}}),_c('P1SK',{style:({
              marginLeft: _vm.isMobile
                ? _vm.gMDynamicSize(17) + 'px'
                : _vm.gMDynamicSize(273, 1180, 414) + 'px',
              marginRight: _vm.isMobile
                ? _vm.gMDynamicSize(17) + 'px'
                : _vm.gMDynamicSize(273, 1180, 414) + 'px',
              marginBottom: _vm.gMDynamicSize(75) + 'px'
            }),attrs:{"text":'skFAQNoAnswerText'}})],1),(_vm.appWidth > 1230)?_c('div',{style:({
            background: 'white',
            flex: 1,
            height: _vm.gMDynamicSize(273, 1441, 1000) + 'px'
          })}):_vm._e()])]),_c('ImpressumElementSK')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }