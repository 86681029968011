<template>
  <div
    :style="{
      background: '#1A304C'
    }"
  >
    <div
      class="level-item"
      :style="{ paddingTop: gMDynamicSize(53) + 'px', marginBottom: gMDynamicSize(22) + 'px' }"
    >
      <img
        @click="openUrl('https://www.facebook.com/allianceFCH/')"
        :style="{
          cursor: 'pointer',
          marginRight: gMDynamicSize(27) + 'px'
        }"
        :src="getIconPath('fb')"
        :width="gMDynamicSize(20) + 'px'"
        :height="gMDynamicSize(20) + 'px'"
      />
      <img
        @click="openUrl('https://www.instagram.com/alliancef.ch/')"
        :style="{
          cursor: 'pointer',
          marginRight: gMDynamicSize(27) + 'px'
        }"
        :src="getIconPath('insta')"
        :width="gMDynamicSize(20) + 'px'"
        :height="gMDynamicSize(20) + 'px'"
      />
      <img
        @click="openUrl('https://twitter.com/alliance_F')"
        :style="{
          cursor: 'pointer',
          marginRight: gMDynamicSize(27) + 'px'
        }"
        :src="getIconPath('twitter')"
        :width="gMDynamicSize(20) + 'px'"
        :height="gMDynamicSize(20) + 'px'"
      />
      <img
        @click="openUrl('https://www.linkedin.com/company/alliance-f/')"
        :style="{
          cursor: 'pointer',
          marginRight: gMDynamicSize(27) + 'px'
        }"
        :src="getIconPath('linkedin')"
        :width="gMDynamicSize(20) + 'px'"
        :height="gMDynamicSize(20) + 'px'"
      />
    </div>
    <div
      class="level-item"
      :style="{
        marginLeft: gMDynamicSize(17) + 'px',
        marginRight: gMDynamicSize(17) + 'px',
        paddingBottom: gMDynamicSize(26) + 'px'
      }"
    >
      <P1SK :textAlignCenter="true" :text="'skImpressumText'" :inverted="true" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['gMDynamicSize'])
  },
  methods: {
    openUrl(url) {
      window.open(url)
    },
    getIconPath(name) {
      if (name) {
        // eslint-disable-next-line import/no-dynamic-require
        return require(`./../../assets/SKicons/${name}White.svg`)
      }
    }
  }
}
</script>
