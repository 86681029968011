<template>
  <h1
    :class="[
      { 'sk-font-bold': bold },
      { 'sk-font-regular': !bold },
      { 'sk-selectable': selectable },
      { 'sk-user-select-none': !selectable },
      { 'sk-hover-red': hover }
    ]"
    :style="{
      display: '-webkit-box',
      webkitBoxOrient: 'vertical',
      overflow: 'hidden',
      webkitLineClamp: lineClamp !== 0 ? lineClamp : 'none',
      fontSize: isMobile ? 24 + 'px' : 30 + 'px',
      fontStyle: italic ? 'italic' : 'normal',
      lineHeight: isMobile ? 26 + 'px' : 32 + 'px',
      color: inverted ? 'white' : '#1a304c',
      textAlign: textAlignCenter ? 'center' : 'left',
      transition: hover ? 'color ease-out 0.4s' : 'none',
      cursor: this.text.startsWith('sk') && translateMode ? 'pointer' : ''
    }"
    @click="translateMode ? openEditTranslationModal() : $emit('click')"
  >
    {{ getDixTranslation(text) }}
  </h1>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  props: {
    lineClamp: {
      type: Number,
      default: 0
    },
    text: {
      type: String,
      default: 'text missing'
    },
    textAlignCenter: {
      type: Boolean,
      default: false
    },
    inverted: {
      type: Boolean,
      default: false
    },
    bold: {
      type: Boolean,
      default: false
    },
    italic: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: Boolean,
      default: false
    },
    hover: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'isMobile',
      'translateMode',
      'gMDynamicSize',
      'getDynamicSize',
      'getDixTranslation'
    ])
  },
  methods: {
    ...mapMutations(['changeShowEditTranslationModal', 'setToolTextToBeTranslated']),
    openEditTranslationModal() {
      if (this.text.startsWith('sk')) {
        this.setToolTextToBeTranslated(this.text)
        this.changeShowEditTranslationModal(true)
      }
    }
  }
}
</script>
