<template>
  <div>
    <b-field
      v-if="editorItem"
      :type="currentErrors.length > 0 ? 'is-danger' : ''"
      :message="debounce ? currentErrors : []"
    >
      <b-input
        ref="textareask"
        :maxlength="editorItem.maxValue"
        :placeholder="getDixTranslation(editorItem.placeholder)"
        v-model="currentEditing"
        has-counter
        type="textarea"
      ></b-input>
    </b-field>
    <!-- <div class="editor">
      <editor-menu-bar class="menubar" :editor="editor" v-slot="{ commands, isActive }">
        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.bold() }"
          @click="commands.bold"
        >
          <P2SK :text="'bold'" :bold="true" />
        </button>
      </editor-menu-bar>
      <editor-menu-bubble
        class="menububble"
        :editor="editor"
        @hide="hideLinkMenu"
        v-slot="{ commands, isActive, getMarkAttrs, menu }"
      >
        <div
          class="menububble"
          :class="{ 'is-active': menu.isActive }"
          :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
        >
          <form
            class="menububble__form"
            v-if="linkMenuIsActive"
            @submit.prevent="setLinkUrl(commands.link, linkUrl)"
          >
            <input
              class="menububble__input"
              type="text"
              v-model="linkUrl"
              placeholder="https://"
              ref="linkInput"
              @keydown.esc="hideLinkMenu"
            />
            <button
              class="menububble__button"
              @click="setLinkUrl(commands.link, linkUrl)"
              type="button"
            >
              <P2SK :text="'confirm'" :inverted="true" />
            </button>
          </form>

          <template v-else>
            <button
              class="menububble__button"
              @click="showLinkMenu(getMarkAttrs('link'))"
              :class="{ 'is-active': isActive.link() }"
            >
              <span>{{ isActive.link() ? 'Update Link' : 'Add Link' }}</span>
            </button>
            <button
              class="menububble__button"
              :class="{ 'is-active': isActive.bold() }"
              @click="commands.bold"
            >
              <P2SK :text="'bold'" :bold="true" :inverted="true" />
            </button>
            <button
              class="menububble__button"
              :class="{ 'is-active': isActive.italic() }"
              @click="commands.italic"
            >
              <P2SK :text="'italic'" :bold="true" :inverted="true" />
            </button>
          </template>
        </div>
      </editor-menu-bubble>
      <editor-content
        class="editor__content"
        :editor="editor"
        :content="getDixTranslation(editorItem.placeholder)"
      />
    </div> -->
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { Editor, EditorContent, EditorMenuBar, EditorMenuBubble } from 'tiptap'
import { Bold, Italic, Link, Focus } from 'tiptap-extensions'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
  components: {
    EditorContent,
    EditorMenuBar,
    EditorMenuBubble
  },
  data() {
    return {
      editing: null,
      debounce: true,
      textContent: '',
      // Create an `Editor` instance with some default content. The editor is
      // then passed to the `EditorContent` component as a `prop`
      editor: new Editor({
        extensions: [
          new Bold(),
          new Italic(),
          new Link(),
          new Focus({
            className: 'has-focus',
            nested: true
          })
        ],
        autoFocus: true,
        onUpdate: ({ getHTML }) => {
          // get new content on update
          this.textContent = getHTML()
        }
      }),
      linkUrl: null,
      linkMenuIsActive: false
    }
  },
  props: {
    editorItem: {
      type: Object
    }
  },
  watch: {
    appWidth(width) {
      if (width) {
        this.setInputStyling()
      }
    },
    editing(string) {
      if (this.currentErrors.length === 0 && this.editorItem) {
        const data = {}
        if (string) {
          data.value = string
        } else {
          data.value = ''
        }

        data.field = this.editorItem.field
        this.editEditingItem(data)
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.setInputStyling()
    }, 1)
  },
  validations() {
    return {
      editing: this.rules
    }
  },
  computed: {
    ...mapGetters(['gMDynamicSize', 'appWidth', 'editingItem']),
    currentEditing: {
      get() {
        if (this.editingItem && this.editorItem.field) {
          // if (this.textContent === '') {
          //   this.editor.setContent(`<p>${this.getDixTranslation(this.editorItem.placeholder)}</p>`)
          // }
          return this.editingItem[this.editorItem.field]
        }
      },
      set(newValue) {
        this.editing = newValue
      }
    },
    rules() {
      if (
        this.editorItem.minValue === -1 &&
        this.editorItem.maxValue === -1 &&
        this.editorItem.isMandatory === true
      ) {
        return { required }
      }
      if (
        this.editorItem.minValue !== -1 &&
        this.editorItem.maxValue !== -1 &&
        this.editorItem.isMandatory === true
      ) {
        return {
          required,
          minLength: minLength(this.editorItem.minValue),
          maxLength: maxLength(this.editorItem.maxValue)
        }
      }
      if (
        this.editorItem.minValue !== -1 &&
        this.editorItem.maxValue !== -1 &&
        this.editorItem.isMandatory === false
      ) {
        return {
          minLength: minLength(this.editorItem.minValue),
          maxLength: maxLength(this.editorItem.maxValue)
        }
      }
      if (
        this.editorItem.minValue === -1 &&
        this.editorItem.maxValue !== -1 &&
        this.editorItem.isMandatory === false
      ) {
        return {
          maxLength: maxLength(this.editorItem.maxValue)
        }
      }
      return {}
    },
    currentErrors() {
      const errors = []
      this.debounce = false
      if (this.editing !== null) {
        if (!this.$v.editing.required && this.$v.editing.required !== undefined) {
          errors.push(this.getDixTranslation('skVMMandatory'))
        }
        if (!this.$v.editing.minLength && this.$v.editing.minLength !== undefined) {
          errors.push(this.getDixTranslation('skVMTextTooShort'))
        }
        if (!this.$v.editing.maxLength && this.$v.editing.maxLength !== undefined) {
          errors.push(this.getDixTranslation('skVMTextTooLong'))
        }
      }
      // eslint-disable-next-line vue/no-async-in-computed-properties
      setTimeout(() => {
        this.debounce = true
      }, 500)
      return errors
    }
  },
  methods: {
    ...mapMutations(['editEditingItem']),
    setInputStyling() {
      if (this.$refs.textareask && this.$refs.textareask.$refs.textarea) {
        this.$refs.textareask.$refs.textarea.setAttribute(
          'style',
          `font-size:${this.gMDynamicSize(16)}px`,
          `line-height:${this.gMDynamicSize(20)}px`
        )
      }
    },
    showLinkMenu(attrs) {
      this.linkUrl = attrs.href
      this.linkMenuIsActive = true
      this.$nextTick(() => {
        this.$refs.linkInput.focus()
      })
    },
    hideLinkMenu() {
      this.linkUrl = null
      this.linkMenuIsActive = false
    },
    setLinkUrl(command, url) {
      command({ href: url })
      this.hideLinkMenu()
    }
  }
}
</script>

<style scoped>
.textarea {
  background-color: rgba(196, 196, 196, 0.15);
  max-width: 502px;
  height: 187px;
}
.has-focus {
  border-radius: 3px;
  box-shadow: 0 0 0 3px #f95268;
}
.editor {
  position: relative;
  max-width: 30rem;
  margin: 0 auto 5rem auto;
}
.editor__content {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}
.editor__content * {
  caret-color: currentColor;
}
.editor__content pre {
  padding: 0.7rem 1rem;
  border-radius: 5px;
  background: black;
  color: white;
  font-size: 0.8rem;
  overflow-x: auto;
}
.editor__content pre code {
  display: block;
}
.editor__content p code {
  padding: 0.2rem 0.4rem;
  border-radius: 5px;
  font-size: 0.8rem;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.8);
}
.editor__content ul,
.editor__content ol {
  padding-left: 1rem;
}
.editor__content li > p,
.editor__content li > ol,
.editor__content li > ul {
  margin: 0;
}
.editor__content a {
  color: inherit;
}
.editor__content blockquote {
  border-left: 3px solid rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.8);
  padding-left: 0.8rem;
  font-style: italic;
}
.editor__content blockquote p {
  margin: 0;
}
.editor__content img {
  max-width: 100%;
  border-radius: 3px;
}
.editor__content table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  margin: 0;
  overflow: hidden;
}
.editor__content table td,
.editor__content table th {
  min-width: 1em;
  border: 2px solid grey;
  padding: 3px 5px;
  vertical-align: top;
  box-sizing: border-box;
  position: relative;
}
.editor__content table td > *,
.editor__content table th > * {
  margin-bottom: 0;
}
.editor__content table th {
  font-weight: bold;
  text-align: left;
}
.editor__content table .selectedCell:after {
  z-index: 2;
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(200, 200, 255, 0.4);
  pointer-events: none;
}
.editor__content table .column-resize-handle {
  position: absolute;
  right: -2px;
  top: 0;
  bottom: 0;
  width: 4px;
  z-index: 20;
  background-color: #adf;
  pointer-events: none;
}
.editor__content .tableWrapper {
  margin: 1em 0;
  overflow-x: auto;
}
.editor__content .resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
.menubar {
  margin-bottom: 3rem;
  transition: visibility 0.2s 0.4s, opacity 0.2s 0.4s;
}
.menubar.is-hidden {
  visibility: hidden;
  opacity: 0;
}
.menubar.is-focused {
  visibility: visible;
  opacity: 1;
  transition: visibility 0.2s, opacity 0.2s;
}
.menubar__button {
  font-weight: bold;
  display: inline-flex;
  background: transparent;
  border: 0;
  color: #f95268;
  padding: 0.2rem 0.5rem;
  margin-right: 0.2rem;
  border-radius: 3px;
  cursor: pointer;
}
.menubar__button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.menubar__button.is-active {
  background-color: rgba(0, 0, 0, 0.1);
}

.menububble {
  position: absolute;
  display: flex;
  z-index: 20;
  background: #f95268;
  border-radius: 5px;
  padding: 0.3rem;
  margin-bottom: 0.5rem;
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s, visibility 0.2s;
}
.menububble.is-active {
  opacity: 1;
  visibility: visible;
}
.menububble__button {
  display: inline-flex;
  background: transparent;
  border: 0;
  color: white;
  padding: 0.2rem 0.5rem;
  margin-right: 0.2rem;
  border-radius: 3px;
  cursor: pointer;
}
.menububble__button:last-child {
  margin-right: 0;
}
.menububble__button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.menububble__button.is-active {
  background-color: rgba(255, 255, 255, 0.2);
}
.menububble__form {
  display: flex;
  align-items: center;
}
.menububble__input {
  font: inherit;
  border: none;
  background: transparent;
  color: white;
}
</style>
