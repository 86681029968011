<template>
  <div
    class="card"
    :style="{
      height: appWidth < 484 ? 100 + '%' : 655 + 'px',
      margin: 'auto',
      flexDirection: 'column',
      display: 'flex'
    }"
  >
    <div
      class="modal-card-head"
      :style="{
        paddingLeft: gMDynamicSize(33) + 'px',
        paddingRight: gMDynamicSize(33) + 'px',
        background: 'white',
        borderBottom: 'none'
      }"
    >
      <div
        class="media is-mobile"
        :style="{
          width: 100 + '%',
          marginBottom: gMDynamicSize(47, 600, 280) + 'px'
        }"
      >
        <div class="media-content level-item">
          <H2SK :text="'skNoRoboter'" :bold="true" />
        </div>
        <div
          class="media-right"
          :style="{
            marginLeft: 0 + 'px'
          }"
        >
          <img
            style="cursor: pointer"
            @click="$emit('close')"
            src="./../../assets/SKicons/closeBlue.svg"
            :width="20 + 'px'"
            :height="20 + 'px'"
          />
        </div>
      </div>
    </div>
    <div
      :key="updateKey"
      v-if="testActive"
      class="modal-card-body"
      :style="{
        padding: 0 + 'px',
        marginLeft: appWidth > 414 ? gMDynamicSize(30, 600, 280) + 'px' : 20 + 'px',
        marginRight: appWidth > 414 ? gMDynamicSize(30, 600, 280) + 'px' : 20 + 'px',
        marginBottom: appWidth > 414 ? gMDynamicSize(30, 600, 280) + 'px' : 20 + 'px'
      }"
    >
      <P2SK
        v-if="randomTest"
        :text="randomTest.userLanguage"
        :textAlignCenter="true"
        :bold="true"
      />
      <VueDragResize
        @dragstop="checkRedraw()"
        :style="{ background: orderArr[0] }"
        axis="x"
        :isResizable="false"
        :w="currentSize"
        :h="currentSize"
        :z="firstZIndex"
        :x="this.gMDynamicSize(40, 600, 280)"
        :y="200"
        @dragging="handleDrag($event, 1)"
      >
        <!-- <img
          :style="{
            cursor: 'pointer',
            float: 'right'
          }"
          src="./../../assets/SKicons/closeBlue.svg"
          :width="gMDynamicSize(currentSize, 600, 280) + 'px'"
          :height="gMDynamicSize(currentSize, 600, 280) + 'px'"
        /> -->
      </VueDragResize>
      <VueDragResize
        @dragstop="checkRedraw()"
        :style="{ background: orderArr[1] }"
        axis="x"
        :isResizable="false"
        :z="secondZIndex"
        :x="this.currentSize + this.gMDynamicSize(40 + 30, 600, 280)"
        :y="200"
        :w="currentSize"
        :h="currentSize"
        @dragging="handleDrag($event, 2)"
      >
      </VueDragResize>
      <VueDragResize
        @dragstop="checkRedraw()"
        :style="{ background: orderArr[2] }"
        axis="x"
        :isResizable="false"
        :z="thirdZIndex"
        :x="2 * this.currentSize + this.gMDynamicSize(40 + 30 + 30, 600, 280)"
        :y="200"
        :w="currentSize"
        :h="currentSize"
        @dragging="handleDrag($event, 3)"
      >
      </VueDragResize>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import VueDragResize from 'vue-drag-resize'
import { LoadingProgrammatic as Loading } from 'buefy'
import axiosCRUD from '../../axios/axiosCRUD'

export default {
  components: {
    VueDragResize
  },
  data() {
    return {
      loading: null,
      updateKey: 1,
      currentUserMoveFrom: '',
      currentCenterPosition: -1,
      randomTest: null,
      testActive: false,
      currentTestIsSolved: false,
      firstZIndex: 1,
      secondZIndex: 1,
      thirdZIndex: 1,
      randomPossibilities: null,
      orderArr: []
    }
  },
  mounted() {
    this.Loading = Loading.open({
      isFullPage: true,
      canCancel: false
    })
    axiosCRUD.get(`/sheknows/captcha/${this.userLanguageDd}`).then((response) => {
      this.orderArr = response.data.pictureOrder.split('|').map((picture) => {
        let pictureName = ''
        if (picture === 'picture1') {
          // yellow
          pictureName = '#FFC204'
        } else if (picture === 'picture2') {
          // blue
          pictureName = '#1E88E5'
        } else if (picture === 'picture3') {
          // brown
          pictureName = '#994F00'
        }
        return pictureName
      })
      console.log(this.orderArr)
      this.randomTest = response.data
      this.testActive = true
      this.Loading.close()
    })
  },
  beforeDestroy() {
    this.firstZIndex = 1
    this.secondZIndex = 1
    this.thirdZIndex = 1
  },
  computed: {
    ...mapGetters([
      'currentProfile',
      'userLanguageDd',
      'editingItem',
      'appHeight',
      'appWidth',
      'getDynamicSize',
      'gMDynamicSize'
    ]),
    currentSize() {
      let size = (600 - 140) / 3
      if (this.appWidth && this.appWidth < 600) {
        size = (this.appWidth - 140) / 3
      }
      return size
    },
    currentModalWidth() {
      let width = 600
      if (this.appWidth < 600) {
        width = this.appWidth
      }
      return width
    }
  },
  methods: {
    checkRedraw() {
      this.Loading = Loading.open({
        isFullPage: true,
        canCancel: false
      })
      this.testActive = false
      const guid = this.randomTest.guid
      const moveFrom = this.currentUserMoveFrom
      const moveTo = this.getMoveTo()

      axiosCRUD
        .get(
          `/sheknows/checkCaptchaResult/${guid}/${this.currentProfile.guid}/${this.userLanguageDd}/${moveFrom}/${moveTo}`
        )
        .then((response) => {
          this.Loading.close()
          if (response.status === 200) {
            if (response.data.guid) {
              this.orderArr = response.data.pictureOrder.split('|').map((picture) => {
                let pictureName = ''
                if (picture === 'picture1') {
                  // yellow
                  pictureName = '#FFC204'
                } else if (picture === 'picture2') {
                  // blue
                  pictureName = '#1E88E5'
                } else if (picture === 'picture3') {
                  // brown
                  pictureName = '#994F00'
                }
                return pictureName
              })
              this.randomTest = response.data
              this.testActive = true
              this.updateKey += 1
            } else {
              this.$emit('captchaSolved', response.data)
            }
          }
        })
    },
    getMoveTo() {
      let position = 1
      if (this.currentCenterPosition < this.currentModalWidth - (2 * this.currentModalWidth) / 3) {
        position = 1
      } else if (
        this.currentModalWidth / 3 < this.currentCenterPosition &&
        this.currentCenterPosition < this.currentModalWidth - this.currentModalWidth / 3
      ) {
        position = 2
      } else if (this.currentCenterPosition > this.currentModalWidth - this.currentModalWidth / 3) {
        position = 3
      }
      return position
    },
    first(center) {
      let solved = false
      if (center < this.currentModalWidth - (2 * this.currentModalWidth) / 3) {
        solved = true
      }
      return solved
    },
    second(center) {
      let solved = false
      if (
        this.currentModalWidth / 3 < center &&
        center < this.currentModalWidth - this.currentModalWidth / 3
      ) {
        solved = true
      }
      return solved
    },
    third(center) {
      let solved = false
      if (center > this.currentModalWidth - this.currentModalWidth / 3) {
        solved = true
      }
      return solved
    },
    handleDrag(newRect, userMoveFrom) {
      if (userMoveFrom === 1 && this.firstZIndex !== 10) {
        this.firstZIndex = 10
        this.secondZIndex = 1
        this.thirdZIndex = 1
      } else if (userMoveFrom === 2 && this.secondZIndex !== 10) {
        this.firstZIndex = 1
        this.secondZIndex = 10
        this.thirdZIndex = 1
      } else if (userMoveFrom === 3 && this.thirdZIndex !== 10) {
        this.firstZIndex = 1
        this.secondZIndex = 1
        this.thirdZIndex = 10
      }

      this.currentUserMoveFrom = userMoveFrom
      this.currentCenterPosition = newRect.left + this.currentSize / 2
    }
  }
}
</script>
