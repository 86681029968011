// tslint:disable: no-shadowed-variable
import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'
import { Istate } from '../types/expertsTypes'

const state: Istate = {
  experts: [],
  currentExperts: [],
  picCacheArr: []
}

const mutations: MutationTree<Istate> = {
  setExperts: (state, list: any) => {
    state.experts = list
  },
  pushExperts: (state, list: any) => {
    list.forEach((expert: any) => {
      state.experts.push(expert)
    })
  },
  setCurrentExperts: (state, list: any) => {
    state.currentExperts = list
  },
  addExpert: (state, expert: any) => {
    const searchExpert: any = {}
    searchExpert.title = expert.title
    searchExpert.firstName = expert.firstName
    searchExpert.lastName = expert.lastName
    searchExpert.languagesDd = expert.languagesDd
    searchExpert.jobTitle = expert.jobTitle
    searchExpert.expertise = expert.expertise
    searchExpert.categories = expert.categories
    searchExpert.locations = expert.locations
    searchExpert.bio = expert.bio
    searchExpert.hasPhone = expert.hasPhone
    searchExpert.pictureName = expert.pictureName
    // searchExpert.picData = expert.picData
    searchExpert.guid = expert.guid

    state.experts.push(searchExpert)
  },
  deleteExpert: (state, expert: any) => {
    const index: number = state.experts.map((e: any) => e.guid).indexOf(expert.guid)
    if (index !== -1) {
      state.experts.splice(index, 1)
    }
  },
  setPicCacheArr: (state, list: any) => {
    state.picCacheArr = list
  }
}

const getters: GetterTree<Istate, any> = {
  experts: (state) => {
    return state.experts
  },
  currentExperts: (state) => {
    return state.currentExperts
  },
  picCacheArr: (state) => {
    return state.picCacheArr
  }
}

export default {
  state,
  mutations,
  getters
}
