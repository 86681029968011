<template>
  <div v-if="editorItem && editingItem" class="ion-page shsViewPort">
    <ion-content fullscreen>
      <headerSHS
        class="shs-header"
        :show-back="currentIndex !== 1 && this.$route.params.editingItemIsInvalid === 'true'"
        :show-cancel="this.$route.params.editingItemIsInvalid === 'false'"
        @backClick="setTransition('amasysGoBack'), $router.go(-1)"
      />
      <textContainerSHS>
        <titleSHS
          :text="
            this.$route.params.editingItemIsInvalid === 'true'
              ? getDixTranslation('editorTitleOnboarding') +
                ' ' +
                currentIndex +
                '/' +
                editorList.length
              : editorItem.toolText
          "
        />
        <textSHS :text="editorItem.info" />
      </textContainerSHS>
      <buttonContainerSHS>
        <buttonSHS
          v-if="editorItem.field.toLowerCase() === 'privacypolicyaccepted'"
          :text="'editorLinkPrivacyPolicy'"
          @click="
            setTransition('amasysForward')
            $router.push({
              name: 'privacyPolicyOverlay'
            })
          "
        />
      </buttonContainerSHS>
      <linkContainerSHS>
        <ion-item color="secondary">
          <ion-label :style="{ minHeight: (appWidth - 45) / 5.7 + 'px' }">
            <div
              class="container"
              :style="{
                fontSize: appWidth / (375 / 22) + 'px',
                lineHeight: appWidth / (375 / 27) + 'px'
              }"
            >
              <div :style="textStyle">{{ getDixTranslation(editorItem.checkBoxText) }}</div>
            </div>
          </ion-label>
          <ion-checkbox
            slot="start"
            color="success"
            :checked="currentValue"
            @ionChange="setEditing($event.detail.checked)"
          ></ion-checkbox>
        </ion-item>
        <div
          v-if="!$v.editing.sameAs && $v.editing.sameAs !== undefined"
          style="padding-left: 10px"
          class="help is-danger is-size-6-mobile is-size-5-tablet"
        >
          {{ getDixTranslation('vMMandatory') }}
        </div>
        <br />
      </linkContainerSHS>
      <buttonContainerSHS :style="{ paddingTop: appWidth / (375 / 30) + 'px' }">
        <buttonSHS
          :disabled="$v.editing.$invalid"
          :text="this.$route.params.editingItemIsInvalid === 'true' ? 'buttonNext' : 'buttonOk'"
          @click="emitEditing()"
        />
      </buttonContainerSHS>
      <linkContainerSHS
        v-if="
          $route.params.editorName == 'firstLogin' ||
          $route.params.editorName == 'assessmentSettings'
        "
      >
        <linkSHS
          :text="'editorLinkGeneralExplanation'"
          @click="
            setTransition('amasysForward'),
              $router.push({
                name: 'faqOverlay',
                params: {
                  toolText: 'editorLinkGeneralExplanation'
                }
              })
          "
        />
        <linkSHS
          :text="'editorLinkDataUsage'"
          @click="
            setTransition('amasysForward'),
              $router.push({
                name: 'faqOverlay',
                params: {
                  toolText: 'editorLinkDataUsage'
                }
              })
          "
        />
      </linkContainerSHS>
    </ion-content>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { sameAs } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      editing: null,
      buttonText: 'Ganze Policy lesen',
      textStyle: {
        display: '-webkit-box',
        webkitLineClamp: 5,
        webkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'left',
        whiteSpace: 'normal'
      }
    }
  },
  validations() {
    return {
      editing: this.rules
    }
  },
  mounted() {
    if (this.editingItem === null) {
      this.$router.replace({ path: '/' })
    }
  },
  computed: {
    ...mapGetters([
      'isFirstLogin',
      'getDixTranslation',
      'user',
      'editingItem',
      'getEditors',
      'appWidth'
    ]),
    currentIndex() {
      if (this.editorList) {
        const index = this.editorList
          .map((item) => {
            return item.guid
          })
          .indexOf(this.$route.params.guid)
        return index + 1
      }
    },
    editorItem() {
      if (this.editorList) {
        return this.editorList.filter((e) => {
          return e.guid === this.$route.params.guid
        })[0]
      }
    },
    editorList() {
      if (
        this.getEditors(this.$route.params.editorName) &&
        this.getEditors(this.$route.params.editorName).length > 0 &&
        this.$route.params.editorName
      ) {
        return this.getEditors(this.$route.params.editorName)
      }
    },
    currentValue() {
      let value = false
      if (this.editing === null && this.editingItem && this.editorItem) {
        value = this.editingItem[this.editorItem.field]
        this.editing = this.editingItem[this.editorItem.field]
      } else if (this.editing) {
        value = this.editing
      }
      return value
    },
    rules() {
      if (this.editorItem.isMandatory) {
        return { sameAs: sameAs(() => true) }
      }
      return {}
    }
  },
  methods: {
    ...mapMutations(['editEditingItem', 'setTransition']),
    ...mapActions(['confirmSingleEditUser', 'initEditingItem']),
    goToNextEdit() {
      const index = this.editorList
        .map((e) => {
          return e.guid
        })
        .indexOf(this.editorItem.guid)

      if (index + 1 === this.editorList.length) {
        this.setTransition('amasysForward')
        this.$router.push({
          name: this.$route.params.editorName
        })
      } else {
        const nextEditorItem = this.editorList[index + 1]

        const inputType = nextEditorItem.inputType
        this.setTransition('amasysForward')
        this.$router.push({
          name: inputType,
          params: {
            guid: nextEditorItem.guid,
            editorName: this.$route.params.editorName,
            editingItemIsInvalid: this.$route.params.editingItemIsInvalid.toString()
          }
        })
      }
    },

    setEditing(value) {
      this.editing = value
    },
    emitEditing() {
      const data = {}
      data.value = this.editing
      data.field = this.editorItem.field
      this.editEditingItem(data)
      if (this.$route.params.editingItemIsInvalid === 'true') {
        this.goToNextEdit()
      } else {
        this.setTransition('amasysGoBack')
        this.$router.go(-1)
      }
    }
  }
}
</script>
