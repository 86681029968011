<template>
  <div
    :style="{
      width: 100 + '%',
      display: 'inline'
    }"
  >
    <div
      :style="{
        display: 'inline'
      }"
    >
      <iconSK
        @click="disabled ? '' : (currentEditing = !currentEditing)"
        :style="{
          cursor: disabled ? 'not-allowed' : 'pointer',
          marginRight: 10 + 'px'
        }"
        :icon="currentEditing ? 'rectangleChecked' : 'rectangleUnchecked'"
        :width="isMobile ? 16 : 20"
        :height="isMobile ? 16 : 20"
      />
    </div>
    <P2SK
      v-if="editorItem"
      :style="{
        display: 'inline'
      }"
      :text="editorItem.toolText"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  data() {
    return {
      editing: null
    }
  },
  props: {
    editorItem: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    editing(bool) {
      const data = {}
      if (bool) {
        data.value = bool
      } else {
        data.value = false
      }

      data.field = this.editorItem.field
      this.editEditingItem(data)
    }
  },
  computed: {
    ...mapGetters([
      'isMobile',
      'editingItem',
      'gMDynamicSize',
      'getDynamicSize',
      'selectedCategories'
    ]),
    currentEditing: {
      get() {
        let editing = false
        if (this.editingItem && this.editorItem && this.editing === null) {
          editing = this.editingItem[this.editorItem.field]
        } else {
          editing = this.editing
        }
        return editing
      },
      set(newValue) {
        this.editing = newValue
      }
    }
  },
  methods: {
    ...mapMutations(['editEditingItem'])
  }
}
</script>
