import Vue from 'vue'

import Ionic from '@ionic/vue'
import '@ionic/core/css/ionic.bundle.css'

import Buefy from 'buefy'

import Vuelidate from 'vuelidate'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faSpinner,
  faSearchPlus,
  faSearchMinus,
  faUndo,
  faExclamationCircle,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import 'buefy/dist/buefy.css'

import App from './App.vue'

import store from './store'
import router from './router'
// import './registerServiceWorker'

import './globals/mixins'
import './globals/components'

Vue.use(Vuelidate)

library.add(faSpinner, faSearchPlus, faSearchMinus, faUndo, faExclamationCircle, faTimesCircle)
Vue.component('vue-fontawesome', FontAwesomeIcon)

Vue.use(Buefy, {
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fas'
  // more global default options https://buefy.org/documentation/constructor-options/...
})
Vue.use(Ionic)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
