<template>
  <div
    class="modal-card"
    :style="{
      height: appWidth < 484 ? 100 + '%' : 655 + 'px',
      margin: 'auto',
      flexDirection: 'column',
      display: 'flex'
    }"
  >
    <div
      class="modal-card-head"
      :style="{
        paddingLeft: gMDynamicSize(33) + 'px',
        paddingRight: gMDynamicSize(33) + 'px',
        background: 'white',
        borderBottom: 'none'
      }"
    >
      <div
        class="media is-mobile"
        :style="{
          width: 100 + '%',
          marginBottom: 0 + 'px'
        }"
      >
        <div class="media-content level-item"></div>
        <div class="media-left"></div>
        <div
          class="media-right"
          :style="{
            marginLeft: 0 + 'px'
          }"
        >
          <img
            style="cursor: pointer"
            @click="changeShowPictureUploadModal(false)"
            src="./../../assets/SKicons/closeBlue.svg"
            :width="gMDynamicSize(20) + 'px'"
            :height="gMDynamicSize(20) + 'px'"
          />
        </div>
      </div>
    </div>
    <div class="modal-card-body">
      <div class="media">
        <div class="media-content">
          <div class="content" id="amasysImageCropper">
            <vue-cropper
              v-if="selectedFile !== null && currentViewMode !== -1"
              :key="componentKey"
              :style="{
                height: appWidth + 'px',
                maxHeight: 414 + 'px',
                maxWidth: 414 + 'px',
                margin: 'auto'
              }"
              ref="cropper"
              :responsive="false"
              :src="imgSrc"
              :autoCropArea="1"
              alt="Source Image"
              drag-mode="move"
              :view-mode="currentViewMode"
              :movable="isMovable"
              :crop-box-movable="false"
              :crop-box-resizable="false"
              :modal="false"
              :ready="initEventListener()"
              preview=".preview"
              :zoomOnWheel="false"
              :zoomOnTouch="false"
            ></vue-cropper>
            <div
              v-else
              class="level is-mobile box"
              style="padding: 0px 0px"
              :style="{
                height: appWidth + 'px',
                maxHeight: 414 + 'px',
                maxWidth: 414 + 'px',
                margin: 'auto'
              }"
            >
              <div class="level-item">
                <input
                  type="file"
                  name="pictureData"
                  id="pictureData"
                  accept="image/gif, image/jpg, image/jpeg, image/png"
                  @change="onFileSelected"
                  style="display: none"
                />
                <a class="button is-big">
                  <label for="pictureData" style="cursor: pointer">{{
                    getDixTranslation('Select Image')
                  }}</label>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="amasys-flex-row-container"
        :style="{
          width: 100 + '%',
          margin: 'auto'
        }"
      >
        <div class="amasys-flex-button-container">
          <div @click="zoomIn(0.1)" class="button is-white" style="background: inherit">
            <b-icon pack="fas" :icon="'search-plus'" size="is-small" />
          </div>
        </div>
        <div class="amasys-flex-button-container">
          <div
            @click="centerImage(), zoomOut(-0.1)"
            class="button is-white"
            style="background: inherit"
          >
            <b-icon pack="fas" :icon="'search-minus'" size="is-small" />
          </div>
        </div>
        <div class="amasys-flex-button-container">
          <div @click="rotate(-90)" class="button is-white" style="background: inherit">
            <b-icon pack="fas" :icon="'undo'" size="is-small" />
          </div>
        </div>
        <div class="amasys-flex-button-container">
          <div @click="reset" class="button is-white" style="background: inherit">reset</div>
        </div>
      </div>
      <div class="level-item" :style="{ marginTop: 10 + 'px' }">
        <chipElementSK
          v-if="selectedFile"
          :button="true"
          :hovorable="true"
          :text="'skSavePicture'"
          @click="uploadFile(), changeShowPictureUploadModal(false)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import VueCropper from 'vue-cropperjs'
import { LoadingProgrammatic as Loading } from 'buefy'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cropperjs/dist/cropper.css'
import imageCompression from 'browser-image-compression'

export default {
  components: {
    VueCropper
  },
  data: () => ({
    isMovable: true,
    componentKey: -1,
    selectedFile: null,
    croppedImage: null,
    fileName: '',
    currentViewMode: 3,
    cropperWidth: 0,
    cropperHeight: 0,
    innerWidth: 0,
    innerHeight: 0,
    prevDiff: 0,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    resizeTimer: setTimeout(() => {}, 250),
    loading: null,
    currentOffsetX: 0
  }),
  beforeDestroy() {
    // not working fix another time
    if (document.getElementById('amasysImageCropper')) {
      document.getElementById('amasysImageCropper').removeEventListener('wheel', this.handleZoom)
      document
        .getElementById('amasysImageCropper')
        .removeEventListener('touchmove', this.handlePhoneZoom)
      document
        .getElementById('amasysImageCropper')
        .removeEventListener('touchend', this.handlePhoneZoomReset)
    }
  },
  mounted() {
    if (this.$route.params.cropType === 'any') {
      this.currentViewMode = 1
    } else if (this.$route.params.cropType === 'square') {
      this.currentViewMode = 3
    }
  },
  watch: {
    appWidth(width) {
      this.componentKey += 1
    },
    appHeight() {
      this.componentKey += 1
    }
  },
  computed: {
    ...mapGetters(['gMDynamicSize', 'appHeight', 'appWidth', 'getSubject', 'getDixTranslation']),

    imgSrc() {
      if (this.selectedFile !== null) {
        return window.URL.createObjectURL(this.selectedFile)
      }
    }
  },
  methods: {
    ...mapActions(['uploadPictureData']),
    ...mapMutations([
      'changeAppHeight',
      'setTransition',
      'setPictureFormFile',
      'changeShowPictureUploadModal'
    ]),
    initEventListener() {
      document.getElementById('amasysImageCropper').addEventListener('wheel', this.handleZoom)
      document
        .getElementById('amasysImageCropper')
        .addEventListener('touchmove', this.handlePhoneZoom)
      document
        .getElementById('amasysImageCropper')
        .addEventListener('touchend', this.handlePhoneZoomReset)
    },
    handleZoom(event) {
      if (event) {
        if (event.deltaY < 0) {
          this.zoomIn(0.025)
        } else if (event.deltaY > 0) {
          this.zoomOut(-0.035)
        }
      }
    },
    handlePhoneZoom(event) {
      if (event.touches.length === 2) {
        this.isMovable = false
        const curDiff = Math.abs(event.touches[0].clientX - event.touches[1].clientX)

        if (this.prevDiff > 0) {
          if (curDiff > this.prevDiff) {
            // The distance between the two pointers has increased
            this.zoomIn(0.025)
          }
          if (curDiff < this.prevDiff) {
            // The distance between the two pointers has decreased
            this.zoomOut(-0.035)
          }
        }
        this.prevDiff = curDiff
      }
    },
    handlePhoneZoomReset(event) {
      this.isMovable = true
      this.prevDiff = 0
    },
    currentImgIsPortrait() {
      if (this.$refs.cropper.getImageData()) {
        const { height } = this.$refs.cropper.getImageData()
        const { width } = this.$refs.cropper.getImageData()
        let isPortrait = false
        if (height / width > 1) {
          isPortrait = true
        }
        return isPortrait
      }
    },
    currentViewIsPortrait() {
      if (this.$refs.cropper.getCanvasData()) {
        const { height } = this.$refs.cropper.getCanvasData()
        const { width } = this.$refs.cropper.getCanvasData()
        let isPortrait = null
        if (height / width > 1) {
          isPortrait = true
        } else if (height / width < 1) {
          isPortrait = false
        }
        return isPortrait
      }
    },
    async handleImageCrop(file) {
      this.Loading = Loading.open({
        isFullPage: true,
        canCancel: false
      })
      const imageFile = file

      // console.log('originalFile instanceof Blob', imageFile instanceof Blob)
      // console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`)

      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920
      }
      try {
        const compressedFile = await imageCompression(imageFile, options)
        // console.log(`firstCompressedFile size ${compressedFile.size / 1024 / 1024} MB`)
        this.selectedFile = compressedFile
        this.Loading.close()
      } catch (error) {
        this.Loading.close()
      }
    },
    async handleUploadCrop(imageFile) {
      this.Loading = Loading.open({
        isFullPage: true,
        canCancel: false
      })
      const options = {
        maxSizeMB: 0.1,
        maxWidthOrHeight: 1920
      }
      try {
        const compressedFile = await imageCompression(imageFile, options)
        this.setPictureFormFile({ file: compressedFile })
        this.Loading.close()
      } catch (error) {
        this.Loading.close()
      }
    },
    onFileSelected(event) {
      this.fileName = event.target.files[0].name
      this.handleImageCrop(event.target.files[0])
    },
    uploadFile() {
      const canvas = this.$refs.cropper.getCroppedCanvas()
      canvas.toBlob(
        (blob) => {
          this.handleUploadCrop(blob)
        },
        'image/jpeg',
        1
      )
    },
    zoomIn(percent) {
      /* let offsetXBefore = this.$refs.cropper.getCanvasData().left;
      let offsetYBefore = this.$refs.cropper.getCanvasData().top;

      this.$refs.cropper.moveTo(offsetXBefore, offsetYBefore); */

      this.$refs.cropper.relativeZoom(percent)

      this.$refs.cropper.setAspectRatio(0)
    },
    zoomOut(percent) {
      // this.$refs.cropper.zoomTo(0);
      let canvasSize = 0

      const containerSize = Math.round(this.$refs.cropper.getContainerData().width)
      if (this.currentImgIsPortrait()) {
        canvasSize = Math.round(this.$refs.cropper.getImageData().height)
      } else {
        canvasSize = Math.round(this.$refs.cropper.getImageData().width)
      }
      if (canvasSize > containerSize) {
        const offset = this.$refs.cropper.getContainerData().width / 2
        const data = {
          left: offset - 25,
          top: offset - 25,
          width: 50,
          height: 50
        }
        // this.centerImage();
        this.$refs.cropper.setCropBoxData(data)

        this.$refs.cropper.relativeZoom(percent)
        this.$refs.cropper.setAspectRatio(0)

        const cropBoxHeight = this.$refs.cropper.getCropBoxData().height
        const cropBoxWidth = this.$refs.cropper.getCropBoxData().width

        const containerHeight = this.$refs.cropper.getContainerData().height
        const containerWidth = this.$refs.cropper.getContainerData().width

        // const canvasHeight = this.$refs.cropper.getCanvasData().height
        // const canvasWidth = this.$refs.cropper.getCanvasData().width

        let offsetX = 0

        let offsetY = 0
        if (this.currentViewIsPortrait()) {
          offsetX = containerHeight - cropBoxHeight
          if (this.$refs.cropper.getCanvasData().top > 0) {
            offsetX = -offsetX
          }
          offsetY = (containerWidth - cropBoxWidth) / 2
          if (offsetY < this.$refs.cropper.getCanvasData().left) {
            offsetY = -offsetY
          }
        } else if (this.currentViewIsPortrait() === false) {
          offsetX = containerWidth - cropBoxWidth
          if (this.$refs.cropper.getCanvasData().left > 0) {
            offsetX = -offsetX
          }
          offsetY = (containerHeight - cropBoxHeight) / 2
          if (offsetY < this.$refs.cropper.getCanvasData().top) {
            offsetY = -offsetY
          }
        } else {
          offsetX = containerWidth - cropBoxWidth
          if (this.$refs.cropper.getCanvasData().left > 0) {
            offsetX = -offsetX
          }
          offsetY = containerHeight - cropBoxHeight
          if (this.$refs.cropper.getCanvasData().top > 0) {
            offsetY = -offsetY
          }
        }

        if (Math.abs(offsetX) > 0 || Math.abs(offsetY) > 0) {
          if (this.currentViewIsPortrait()) {
            this.$refs.cropper.move(offsetY, offsetX)
          } else if (this.currentViewIsPortrait() === false) {
            this.$refs.cropper.move(offsetX, offsetY)
          } else {
            this.$refs.cropper.move(offsetX, offsetY)
          }
          this.$refs.cropper.setAspectRatio(0)
        }
      }
    },
    centerImage() {
      const containerWidth = this.$refs.cropper.getContainerData().width
      const containerHeight = this.$refs.cropper.getContainerData().height
      const canvasWidth = this.$refs.cropper.getCanvasData().width
      const canvasHeight = this.$refs.cropper.getCanvasData().height
      const deltaHeight = (containerHeight - canvasHeight) / 2
      const deltaWidth = (containerWidth - canvasWidth) / 2
      this.$refs.cropper.moveTo(deltaWidth, deltaHeight)
    },
    rotate(deg) {
      // this.$refs.cropper.zoomTo(0);
      this.centerImage()

      this.$refs.cropper.setAspectRatio(1)

      this.$refs.cropper.rotate(deg)

      this.$refs.cropper.setAspectRatio(0)
    },
    reset() {
      this.componentKey += 1
      /* this.$refs.cropper.zoomTo(0);

      this.centerImage();

      this.$refs.cropper.setAspectRatio(1);

      this.$refs.cropper.rotateTo(0);

      this.$refs.cropper.setAspectRatio(0); */
    }
  }
}
</script>

<style scoped>
input[type='file'] {
  display: none;
}
.amasys-flex-row-container {
  background: rgb(230, 230, 230);
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  box-sizing: border-box;
  max-width: 100%;
  min-height: 62px;
  padding: 4px 12px;
}
.amasys-flex-button-container {
  flex: 1 1 auto;
  padding: 5px 5px 8px 0px;
  text-align: center;
  min-height: 52px;
}
</style>
