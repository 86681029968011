<template>
  <div v-if="editorItem">
    <div
      v-if="multiple && currentSelected && currentSelected.length > 0"
      :style="{
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: gMDynamicSize(10) + 'px'
      }"
    >
      <P2SK
        :style="{ marginRight: gMDynamicSize(10) + 'px' }"
        v-for="obj in currentSelected"
        :key="obj.nr"
        :text="obj.userLanguage"
      />
    </div>
    <section
      @mouseover="hover = true"
      @mouseleave="hover = false"
      @click="showDropdown = !showDropdown"
    >
      <b-dropdown
        ref="dropdownSK"
        :disabled="translateMode"
        expanded
        aria-role="list"
        :mobile-modal="false"
        :close-on-click="false"
        v-model="currentSelected"
        :multiple="multiple"
        scrollable
        :style="{
          width: 264 + 'px',
          fontSize: gMDynamicSize(16) + 'px',
          lineHeight: gMDynamicSize(20) + 'px'
        }"
      >
        <template #trigger>
          <chipElementSK
            v-if="multiple"
            :dropdown="true"
            :hasIcon="true"
            :text="getDixTranslation(editorItem.placeholder)"
          />
          <chipElementSK
            v-else-if="currentSelected"
            :dropdown="true"
            :hasIcon="true"
            :text="currentSelected.userLanguage"
          />
        </template>
        <b-dropdown-item
          :style="{
            width: 200 + 'px'
          }"
          aria-role="listitem"
          :value="obj"
          v-for="obj in dropdowns"
          :key="obj.nr"
        >
          {{ obj.userLanguage }}
        </b-dropdown-item>
      </b-dropdown>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      selected: [],
      editing: [],
      debounce: true,
      items: [],
      hover: false,
      showDropdown: false
    }
  },
  props: {
    editorItem: {
      type: Object,
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    },
    dropdowns: {
      type: Array
    }
  },
  watch: {
    hover(bool) {
      if (!bool && this.showDropdown) {
        this.showDropdown = false
        this.$refs.dropdownSK.toggle()
      }
    },
    selected(selected) {
      this.showDropdown = false
      if (this.multiple) {
        let value = ''
        if (selected.length > 0) {
          if (this.editorItem.field === 'locations') {
            value = `|${selected
              .map((obj) => {
                return obj.userLanguage
              })
              .join('|')}|`
          } else {
            value = `|${selected
              .map((obj) => {
                return obj.nr
              })
              .join('|')}|`
          }
        }

        const data = {}
        data.value = value
        data.field = this.editorItem.field
        this.editEditingItem(data)
      } else {
        const data = {}
        data.value = selected.nr
        data.field = this.editorItem.field
        this.editEditingItem(data)
      }
    }
  },
  validations() {
    return {
      editing: this.rules
    }
  },
  computed: {
    ...mapGetters(['translateMode', 'gMDynamicSize', 'appWidth', 'editingItem', 'corrlanguages']),
    currentSelected: {
      get() {
        let selected = null
        if (
          this.editorItem &&
          this.editorItem.field &&
          this.editingItem &&
          this.editingItem[this.editorItem.field]
        ) {
          if (this.multiple) {
            if (this.editorItem.field === 'locations') {
              selected = this.dropdowns.filter((dropdown) => {
                return this.editingItem[this.editorItem.field].includes(
                  `|${dropdown.userLanguage}|`
                )
              })
            } else {
              selected = this.dropdowns.filter((dropdown) => {
                return this.editingItem[this.editorItem.field].includes(`|${dropdown.nr}|`)
              })
            }
          } else {
            selected = this.dropdowns.find((dropdown) => {
              return dropdown.nr === this.editingItem[this.editorItem.field]
            })
          }
        }
        return selected
      },
      set(newValue) {
        this.selected = newValue
      }
    },
    rules() {
      if (this.editorItem.isMandatory === true) {
        return { required }
      }

      return {}
    },
    currentErrors() {
      const errors = []
      this.debounce = false
      if (this.editing !== null) {
        if (!this.$v.editing.required && this.$v.editing.required !== undefined) {
          errors.push(this.getDixTranslation('vMMandatory'))
        }
      }
      // eslint-disable-next-line vue/no-async-in-computed-properties
      setTimeout(() => {
        this.debounce = true
      }, 500)
      return errors
    }
  },
  methods: {
    ...mapMutations(['editEditingItem'])
  }
}
</script>

<style lang="scss">
@import '../../globals/buefySK.scss';
</style>
