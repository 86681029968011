var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"container",staticClass:"dynamic-scroller-demo"},[(_vm.currentVirtualList.length > 0)?_c('RecycleScroller',{ref:"scroller",staticClass:"scroller",attrs:{"id":"selectExpertList","emit-update":_vm.allowScrollerUpdate,"items":_vm.currentVirtualList,"item-size":_vm.isMobile ? _vm.gMDynamicSize(386) : 445,"buffer":_vm.buffer},on:{"update":function($event){return _vm.onScrollListUpdate()}},scopedSlots:_vm._u([{key:"before",fn:function(){return [(_vm.isMobile)?_c('div',{style:({
          position: 'fixed',
          top: 0 + 'px',
          zIndex: 3,
          width: 100 + '%',
          background: 'white'
        })},[_c('img',{style:({
            paddingTop: 15 + 'px',
            paddingLeft: 15 + 'px'
          }),attrs:{"src":require("./../../assets/sheknows_logo.png"),"width":_vm.gMDynamicSize(34) + 'px',"height":_vm.gMDynamicSize(23) + 'px'}}),_c('svg',{staticStyle:{"cursor":"pointer"},style:({
            float: 'right',
            paddingTop: 27 + 'px',
            paddingRight: 22 + 'px',
            paddingBottom: 8 + 'px'
          }),attrs:{"width":"19","height":"16"},on:{"click":function($event){return _vm.changeShowMenu(true)}}},[_c('rect',{attrs:{"width":"19","height":"2"}}),_c('rect',{attrs:{"y":"7","width":"19","height":"2"}}),_c('rect',{attrs:{"y":"14","width":"19","height":"2"}})])]):_vm._e(),_c('IntroductionSearchBodySK'),(!_vm.isMobile)?_c('FilterSearchBodySK',{style:({
          marginTop: 60 + 'px'
        })}):_vm._e(),(_vm.isMobile)?_c('div',[_c('FilterSearchBodySK',{directives:[{name:"show",rawName:"v-show",value:(_vm.showScrollToTopButton),expression:"showScrollToTopButton"}]}),_c('transition',{attrs:{"name":"slidedown"}},[_c('FilterSearchBodySK',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilter || !_vm.showScrollToTopButton),expression:"showFilter || !showScrollToTopButton"}],style:({
              paddingTop: !_vm.showScrollToTopButton ? 20 + 'px' : 5 + 'px',
              position: _vm.showScrollToTopButton ? 'fixed' : 'static',
              top: _vm.gMDynamicSize(46) + 'px',
              zIndex: 1,
              background: 'white'
            }),attrs:{"showLanguageLocationFilter":!_vm.showScrollToTopButton}})],1)],1):_vm._e(),_c('div',{staticClass:"level-item"},[_c('chipElementSK',{directives:[{name:"show",rawName:"v-show",value:(_vm.showScrollToTopButton && _vm.showFilter),expression:"showScrollToTopButton && showFilter"}],style:({ position: 'fixed', bottom: 10 + 'px', zIndex: 2 }),attrs:{"button":true,"parentHover":true,"text":'skSearchScrollUp'},on:{"click":function($event){_vm.scrollToPosition(0),
              (_vm.currentLastScrollTop = 0),
              (_vm.showScrollToTopButton = false),
              (_vm.showFilter = true)}}})],1),_c('div',{style:({
          background: 'rgba(169, 206, 162, 0.12)',
          height: _vm.isMobile ? _vm.gMDynamicSize(24) + 'px' : _vm.gMDynamicSize(85) + 'px'
        })})]},proxy:true},{key:"default",fn:function(ref){
        var item = ref.item;
return [_c('VirtualListRowItem',{key:item.id,class:_vm.contentIsHidden ? 'hide-content-sk' : 'show-content-sk',attrs:{"current-virtual-list":item.list,"current-column-count":_vm.currentColumnCount,"defaultPicture":_vm.defaultPicture}})]}},{key:"after",fn:function(){return [_c('div',{style:({
          background: 'rgba(169, 206, 162, 0.12)',
          height: _vm.isMobile ? _vm.gMDynamicSize(24) + 'px' : _vm.gMDynamicSize(85) + 'px'
        })})]},proxy:true}],null,false,3041488604)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }