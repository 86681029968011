<template>
  <div
    :style="{
      marginBottom: gMDynamicSize(5) + 'px',
      width: 100 + '%'
    }"
  >
    <iconSK
      @click="iconClicked()"
      :style="{
        display: 'inline',
        cursor: 'pointer',
        marginRight: gMDynamicSize(10) + 'px'
      }"
      :icon="isChecked ? 'rectangleChecked' : 'rectangleUnchecked'"
      :width="10"
      :height="10"
    />
    <div
      @mouseover="hover = true"
      @mouseleave="hover = false"
      :style="{
        display: 'inline',
        cursor: 'pointer',
        marginRight: 5 + 'px'
      }"
    >
      <P2SK
        v-if="isMainCategory"
        @click="labelClicked()"
        :style="{
          display: 'inline',
          color: selectedMainCategory === categoryObj.nr || hover ? '#F95268' : 'black'
        }"
        :text="categoryObj.userLanguage"
        :bold="isMainCategory ? true : false"
      />
      <P2SK
        v-if="!isMainCategory"
        @click="labelClicked()"
        :style="{
          display: 'inline',
          color: '#3E7291'
        }"
        :text="categoryObj.userLanguage"
        :bold="isMainCategory ? true : false"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    categoryObj: {
      type: Object
    },
    isMainCategory: {
      type: Boolean,
      default: false
    },
    selectedMainCategory: {
      type: Number,
      default: -1
    },
    maxSelection: {
      type: Number,
      default: 3
    }
  },
  data() {
    return {
      hover: false
    }
  },
  methods: {
    ...mapActions(['updateSelectedCategories']),
    iconClicked() {
      this.updateSelectedCategories({
        categoryObj: this.categoryObj,
        maxSelection: this.maxSelection
      })
    },
    labelClicked() {
      if (this.isMainCategory) {
        this.$emit('mainCategorySelection', this.categoryObj)
      } else {
        this.updateSelectedCategories({
          categoryObj: this.categoryObj,
          maxSelection: this.maxSelection
        })
      }
    }
  },
  computed: {
    ...mapGetters(['gMDynamicSize', 'getDynamicSize', 'selectedCategories']),
    isChecked() {
      let checked = false
      if (this.selectedCategories && this.categoryObj) {
        this.selectedCategories.forEach((obj) => {
          if (obj.nr === this.categoryObj.nr) {
            checked = true
          }
        })
      }
      return checked
    }
  }
}
</script>
