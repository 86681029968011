<template>
  <div v-if="editorItem && editingItem" class="ion-page shsViewPort">
    <ion-content fullscreen>
      <headerSHS
        class="shs-header"
        :show-back="currentIndex !== 1 && this.$route.params.editingItemIsInvalid === 'true'"
        :show-cancel="this.$route.params.editingItemIsInvalid === 'false'"
        @backClick="setTransition('amasysGoBack'), $router.go(-1)"
      />
      <textContainerSHS>
        <titleSHS
          :text="
            this.$route.params.editingItemIsInvalid === 'true'
              ? getDixTranslation('editorTitleOnboarding') +
                ' ' +
                currentIndex +
                '/' +
                editorList.length
              : editorItem.toolText
          "
        />
        <textSHS :text="editorItem.info" />
      </textContainerSHS>
      <linkContainerSHS>
        <div>
          <v-text-field
            ref="input"
            v-model="editing === null ? currentValue : editing"
            :style="{
              fontSize: appWidth / (375 / 22) + 'px',
              lineHeight: appWidth / (375 / 27) + 'px'
            }"
            solo
            flat
            :height="(appWidth - 45) / 5.7 + 'px'"
            background-color="rgba(196, 196, 196, 0.15)"
            :error-messages="debounce ? currentErrors : []"
            :placeholder="getDixTranslation(editorItem.toolText)"
            @keyup.native.enter="emitEditing()"
          ></v-text-field>
        </div>
      </linkContainerSHS>
      <buttonContainerSHS
        :style="{
          paddingTop: appWidth / (375 / 25.5) + 'px',
          paddingBottom: appWidth / (375 / 25.5) + 'px'
        }"
      >
        <buttonSHS
          :disabled="$v.editing.$invalid"
          :text="this.$route.params.editingItemIsInvalid === 'true' ? 'buttonNext' : 'buttonOk'"
          @click="emitEditing()"
        />
      </buttonContainerSHS>
      <linkContainerSHS
        v-if="
          $route.params.editorName == 'firstLogin' ||
          $route.params.editorName == 'assessmentSettings'
        "
      >
        <linkSHS
          :text="'editorLinkGeneralExplanation'"
          @click="
            setTransition('amasysForward'),
              $router.push({
                name: 'faqOverlay',
                params: {
                  toolText: 'editorLinkGeneralExplanation'
                }
              })
          "
        />
        <linkSHS
          :text="'editorLinkDataUsage'"
          @click="
            setTransition('amasysForward'),
              $router.push({
                name: 'faqOverlay',
                params: {
                  toolText: 'editorLinkDataUsage'
                }
              })
          "
        />
      </linkContainerSHS>
    </ion-content>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { required, minValue, maxValue, numeric } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      editing: null,
      debounce: true
    }
  },
  validations() {
    return {
      editing: this.rules
    }
  },
  mounted() {
    if (this.editingItem === null) {
      this.$router.replace({ path: '/' })
      /* this.initEditingItem({ editorType: this.$route.params.editorName });
      if (this.currentIndex !== 1) {
        const firstEditorItem = this.getEditors(
          this.$route.params.editorName,
        )[0];
        const name = firstEditorItem.inputType;
        this.$router.replace({
          name: name,
          params: {
            guid: firstEditorItem.guid,
            editorName: this.$route.params.editorName,
            editingItemIsInvalid: true,
          },
        });
      } */
    }
  },
  computed: {
    ...mapGetters([
      'isFirstLogin',
      'profile',
      'getDixTranslation',
      'editingItem',
      'getEditors',
      'appHeight',
      'appWidth'
    ]),
    currentIndex() {
      if (this.editorList) {
        const index = this.editorList
          .map((item) => {
            return item.guid
          })
          .indexOf(this.$route.params.guid)
        return index + 1
      }
    },
    editorItem() {
      if (this.editorList) {
        return this.editorList.filter((e) => {
          return e.guid === this.$route.params.guid
        })[0]
      }
    },
    editorList() {
      if (
        this.getEditors(this.$route.params.editorName) &&
        this.getEditors(this.$route.params.editorName).length > 0 &&
        this.$route.params.editorName
      ) {
        return this.getEditors(this.$route.params.editorName)
      }
    },
    currentValue() {
      let value = ''
      if (this.editing === null && this.editingItem && this.editorItem) {
        if (this.editingItem[this.editorItem.field] === 0) {
          this.editing = ''
          value = ''
        } else {
          this.editing = this.editingItem[this.editorItem.field]
          value = this.editingItem[this.editorItem.field]
        }
      } else if (this.editing) {
        value = this.editing
      }
      this.focusInput(value)
      return value
    },
    rules() {
      if (this.editorItem.minValue === -1 && this.editorItem.maxValue === -1) {
        return { numeric, required }
      }
      if (this.editorItem.minValue !== -1 && this.editorItem.maxValue !== -1) {
        return {
          required,
          numeric,
          minValue: minValue(this.editorItem.minValue),
          maxValue: maxValue(this.editorItem.maxValue)
        }
      }
    },
    currentErrors() {
      const errors = []
      this.debounce = false
      if (!this.$v.editing.required && this.$v.editing.required !== undefined) {
        errors.push(this.getDixTranslation('vMMandatory'))
      }
      if (
        !this.$v.editing.minValue &&
        this.$v.editing.numeric &&
        this.$v.editing.minValue !== undefined
      ) {
        errors.push(this.getDixTranslation('vMNumberTooSmall'))
      }
      if (
        !this.$v.editing.maxValue &&
        this.$v.editing.numeric &&
        this.$v.editing.maxValue !== undefined
      ) {
        errors.push(this.getDixTranslation('vMNumberTooBig'))
      }
      if (!this.$v.editing.numeric && this.$v.editing.numeric !== undefined) {
        errors.push(this.getDixTranslation('vMNumberOnly'))
      }
      // eslint-disable-next-line vue/no-async-in-computed-properties
      setTimeout(() => {
        this.debounce = true
      }, 500)
      return errors
    }
  },
  methods: {
    ...mapMutations(['editEditingItem', 'setTransition']),
    ...mapActions(['confirmSingleEditUser', 'initEditingItem']),
    goToNextEdit() {
      const index = this.editorList
        .map((e) => {
          return e.guid
        })
        .indexOf(this.editorItem.guid)

      if (index + 1 === this.editorList.length) {
        this.setTransition('amasysForward')
        this.$router.push({
          name: this.$route.params.editorName
        })
      } else {
        const nextEditorItem = this.editorList[index + 1]

        const inputType = nextEditorItem.inputType
        this.setTransition('amasysForward')
        this.$router.push({
          name: inputType,
          params: {
            guid: nextEditorItem.guid,
            editorName: this.$route.params.editorName,
            editingItemIsInvalid: this.$route.params.editingItemIsInvalid.toString()
          }
        })
      }
    },
    focusInput(value) {
      if (value === '') {
        setTimeout(() => this.$refs.input.focus(), 500)
      }
    },
    emitEditing() {
      if (this.$v.editing.$invalid === false) {
        if (this.$route.params.singleEdit) {
          const data = {}
          data.fieldName = this.editorItem.field
          data.stringOld = this.editingItem[this.editorItem.field]
          data.stringNew = Math.floor(this.editing)
          if (this.editorItem.tableName.toLowerCase() === 'users') {
            this.confirmSingleEditUser({
              item: data,
              itemGuid: this.editingItem.guid,
              dataType: 'string'
            })
          }
        } else {
          const data = {}
          data.value = Math.floor(this.editing)
          data.field = this.editorItem.field
          this.editEditingItem(data)
          if (this.$route.params.editingItemIsInvalid === 'true') {
            this.goToNextEdit()
          } else {
            this.$router.go(-1)
          }
        }
      }
    }
  }
}
</script>
