<template>
  <div v-if="editorItem && editingItem" class="ion-page shsViewPort">
    <headerSHS
      v-if="$route.params"
      class="shs-header"
      :show-back="currentIndex !== 1 && this.$route.params.editingItemIsInvalid === 'true'"
      :show-cancel="this.$route.params.editingItemIsInvalid === 'false'"
      @backClick="setTransition('amasysGoBack'), $router.go(-1)"
    />
    <textContainerSHS>
      <titleSHS
        :text="
          this.$route.params.editingItemIsInvalid === 'true'
            ? getDixTranslation('editorTitleOnboarding') +
              ' ' +
              currentIndex +
              '/' +
              editorList.length
            : editorItem.toolText
        "
      />
      <textSHS :text="editorItem.info" />
    </textContainerSHS>
    <!-- <ion-item>
      <ion-label position="stacked">
        <div class="is-size-5-mobile is-size-4-tablet">
          {{ getDixTranslation(editorItem.toolText) }}
        </div>
      </ion-label>
      <ion-textarea
        @keyup.enter="emitEditing()"
        ref="input"
        :value="currentValue"
        @ionChange="setEditing($event.detail.value)"
        autofocus
        clearInput
      ></ion-textarea>
    </ion-item> -->
    <linkContainerSHS>
      <div
        :style="{
          marginBottom: appWidth / (375 / 30) + 'px'
        }"
      >
        <v-textarea
          ref="input"
          v-model="editing === null ? currentValue : editing"
          :style="{
            fontSize: appWidth / (375 / 22) + 'px',
            lineHeight: appWidth / (375 / 27) + 'px',
            width: 95 + '%'
          }"
          solo
          flat
          rows="5"
          background-color="rgba(196, 196, 196, 0.15)"
          :error-messages="debounce ? currentErrors : []"
          :placeholder="getDixTranslation(editorItem.placeholder)"
          @keyup.native.enter="emitEditing()"
        ></v-textarea>
      </div>
    </linkContainerSHS>
    <linkContainerSHS v-if="$route.params.editorName == 'mainInfoAdd'">
      <linkSHS
        :text="'mainInfoAddCancelLink'"
        @click="
          setTransition('amasysGoBack'),
            $router.push({
              name: 'dashboardCounterspeech'
            })
        "
      />
    </linkContainerSHS>
    <buttonContainerSHS
      :style="{
        paddingTop: appWidth / (375 / 25.5) + 'px',
        paddingBottom: appWidth / (375 / 25.5) + 'px'
      }"
    >
      <buttonSHS
        :disabled="$v.editing.$invalid"
        :text="this.$route.params.editingItemIsInvalid === 'true' ? 'buttonNext' : 'buttonOk'"
        @click="emitEditing()"
      />
    </buttonContainerSHS>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      editing: null,
      debounce: true
    }
  },
  validations() {
    return {
      editing: this.rules
    }
  },
  mounted() {
    if (this.editingItem === null) {
      this.$router.replace({ path: '/' })
      this.focusInput()
    }
  },
  computed: {
    ...mapGetters(['getDixTranslation', 'user', 'editingItem', 'getEditors', 'appWidth']),
    currentIndex() {
      if (this.editorList) {
        const index = this.editorList
          .map((item) => {
            return item.guid
          })
          .indexOf(this.$route.params.guid)
        return index + 1
      }
    },
    editorItem() {
      if (this.editorList) {
        return this.editorList.filter((e) => {
          return e.guid === this.$route.params.guid
        })[0]
      }
    },
    editorList() {
      if (
        this.getEditors(this.$route.params.editorName) &&
        this.getEditors(this.$route.params.editorName).length > 0 &&
        this.$route.params.editorName
      ) {
        return this.getEditors(this.$route.params.editorName)
      }
    },
    currentValue() {
      let value = ''
      if (this.editing === null && this.editingItem && this.editorItem) {
        value = this.editingItem[this.editorItem.field]
        this.editing = this.editingItem[this.editorItem.field]
      } else if (this.editing) {
        value = this.editing
      }
      return value
    },
    rules() {
      if (
        this.editorItem.minValue === -1 &&
        this.editorItem.maxValue === -1 &&
        this.editorItem.isMandatory === true
      ) {
        return { required }
      }
      if (
        this.editorItem.minValue !== -1 &&
        this.editorItem.maxValue !== -1 &&
        this.editorItem.isMandatory === true
      ) {
        return {
          required,
          minLength: minLength(this.editorItem.minValue),
          maxLength: maxLength(this.editorItem.maxValue)
        }
      }
      if (
        this.editorItem.minValue !== -1 &&
        this.editorItem.maxValue !== -1 &&
        this.editorItem.isMandatory === false
      ) {
        return {
          minLength: minLength(this.editorItem.minValue),
          maxLength: maxLength(this.editorItem.maxValue)
        }
      }
      return {}
    },
    currentErrors() {
      const errors = []
      this.debounce = false
      if (this.editing !== null) {
        if (!this.$v.editing.required && this.$v.editing.required !== undefined) {
          errors.push(this.getDixTranslation('vMMandatory'))
        }
        if (!this.$v.editing.minLength && this.$v.editing.minLength !== undefined) {
          errors.push(this.getDixTranslation('vMTextTooShort'))
        }
        if (!this.$v.editing.maxLength && this.$v.editing.maxLength !== undefined) {
          errors.push(this.getDixTranslation('vMTextTooLong'))
        }
      }
      // eslint-disable-next-line vue/no-async-in-computed-properties
      setTimeout(() => {
        this.debounce = true
      }, 500)
      return errors
    }
  },
  methods: {
    ...mapMutations(['editEditingItem', 'setTransition']),
    ...mapActions(['confirmSingleEditUser']),
    goToNextEdit() {
      const index = this.editorList
        .map((e) => {
          return e.guid
        })
        .indexOf(this.editorItem.guid)

      if (index + 1 === this.editorList.length) {
        this.setTransition('amasysForward')
        this.$router.push({
          name: this.$route.params.editorName
        })
      } else {
        const nextEditorItem = this.editorList[index + 1]

        const inputType = nextEditorItem.inputType
        this.setTransition('amasysForward')
        this.$router.push({
          name: inputType,
          params: {
            guid: nextEditorItem.guid,
            editorName: this.$route.params.editorName,
            editingItemIsInvalid: this.$route.params.editingItemIsInvalid.toString()
          }
        })
      }
    },
    focusInput() {
      setTimeout(() => this.$refs.input.setFocus(), 600)
    },
    setEditing(value) {
      this.editing = value
    },
    emitEditing() {
      if (this.$v.editing.$invalid === false) {
        if (this.$route.params.singleEdit) {
          const data = {}
          data.fieldName = this.editorItem.field
          data.stringOld = this.editingItem[this.editorItem.field]
          data.stringNew = this.editing
          if (this.editorItem.tableName.toLowerCase() === 'users') {
            this.confirmSingleEditUser({
              item: data,
              itemGuid: this.editingItem.guid,
              dataType: 'string'
            })
            this.setTransition('amasysGoBack')
            this.$router.go(-1)
          }
        } else {
          const data = {}
          data.value = this.editing
          data.field = this.editorItem.field

          this.editEditingItem(data)
          if (this.$route.params.editingItemIsInvalid === 'true') {
            this.goToNextEdit()
          } else {
            this.setTransition('amasysGoBack')
            this.$router.go(-1)
          }
        }
      }
    }
  }
}
</script>
