<template>
  <div
    :style="{
      width: isMobile ? appWidth - gMDynamicSize(32) + 'px' : 'auto',
      paddingLeft: gMDynamicSize(15) + 'px',
      paddingRight: gMDynamicSize(17) + 'px'
    }"
  >
    <div
      :style="{
        textAlign: 'center',
        marginBottom: isMobile ? gMDynamicSize(11) + 'px' : 0 + 'px'
      }"
    >
      <P1SK :style="{ display: 'inline' }" :text="'skFilterSearch'" />
    </div>
    <div
      :style="{
        textAlign: 'center'
      }"
    >
      <div
        @mouseover="hover = true"
        @mouseleave="hover = false"
        :style="{
          display: 'inline-block',
          width: gMDynamicSize(444, 600, 280) + 'px',
          marginBottom: gMDynamicSize(20) + 'px'
        }"
      >
        <b-autocomplete
          ref="autocomplete"
          @blur="handleBlur()"
          clearable
          v-model="input"
          field="label"
          :data="filteredDataArray"
          :placeholder="getDixTranslation('skFilterYourSearchText')"
        >
          <template #empty>{{ getDixTranslation('skFilterNoResults') }}</template>
        </b-autocomplete>
      </div>
    </div>
    <div
      :style="{
        textAlign: 'center',
        marginBottom: isMobile ? gMDynamicSize(20) + 'px' : 38 + 'px'
      }"
    >
      <P1SK
        @click="changeShowCategorySelectModal(true)"
        class="selected-category"
        :style="{ display: 'inline', cursor: 'pointer' }"
        :text="'skFilterByCategories'"
      />
      <chipElementSK
        :style="{
          display: 'inline-flex',
          marginTop: gMDynamicSize(8) + 'px',
          marginLeft: gMDynamicSize(15) + 'px'
        }"
        v-for="item in selectedCategories"
        :key="item.nr"
        @click="
          updateSelectedCategories({
            categoryObj: item,
            maxSelection: 1
          })
        "
        :text="item.userLanguage"
        :hasIcon="true"
      />
    </div>
    <div
      :style="{
        textAlign: 'center',
        marginBottom: isMobile ? gMDynamicSize(23) + 'px' : 38 + 'px'
      }"
    >
      <P1SK
        @click="changeFilterHasPhone()"
        :style="{ display: 'inline', cursor: 'pointer', marginRight: 10 + 'px' }"
        :class="{ 'selected-category': filterHasPhone }"
        :text="'skFilterHasPhone'"
      />
      <iconSK
        @click="changeFilterHasPhone()"
        :style="{
          display: 'inline',
          cursor: 'pointer'
        }"
        :icon="filterHasPhone ? 'rectangleChecked' : 'rectangleUnchecked'"
        :width="15"
        :height="15"
      />
    </div>
    <div
      v-show="
        showLanguageLocationFilter &&
        currentExperts.length > 0 &&
        (selectedCategories.length > 0 || searchString.length > 0)
      "
      :style="{
        textAlign: 'center',
        marginBottom: isMobile ? gMDynamicSize(23) + 'px' : 38 + 'px'
      }"
    >
      <P1SK
        :class="{ 'selected-category': selectedLanguages.length === 0 }"
        :style="{ display: 'inline', cursor: 'pointer' }"
        :text="'skFilterAllLanguages'"
        @click="resetSelectedLanguages()"
      />

      <P1SK
        v-for="item in currentLanguages"
        :key="item.nr"
        @click="changeSelectedLanguages(item)"
        :class="{ 'selected-category': languageIsSelected(item) }"
        :style="{
          display: 'inline',
          cursor: 'pointer',
          marginLeft: gMDynamicSize(20) + 'px'
        }"
        :text="item.userLanguage"
      />
    </div>
    <div
      v-show="
        showLanguageLocationFilter &&
        currentExperts.length > 0 &&
        (selectedCategories.length > 0 || searchString.length > 0)
      "
      :style="{
        textAlign: 'center',
        marginBottom: isMobile ? gMDynamicSize(20) + 'px' : 38 + 'px'
      }"
    >
      <P1SK
        :class="{ 'selected-category': selectedLocations.length === 0 }"
        :style="{ display: 'inline', cursor: 'pointer' }"
        :text="'skFilterAllCantones'"
        @click="resetSelectedLocations()"
      />
      <P1SK
        v-for="item in currentLocations"
        :key="item.nr"
        @click="changeSelectedLocations(item)"
        :class="{ 'selected-category': locationIsSelected(item) }"
        :style="{
          display: 'inline',
          cursor: 'pointer',
          marginLeft: gMDynamicSize(10) + 'px'
        }"
        :text="item.userLanguage"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  props: {
    showLanguageLocationFilter: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      currentLanguages: [],
      currentLocations: [],
      timeout: null,
      debouncedInput: '',
      hover: false
    }
  },
  mounted() {
    this.debouncedInput = this.searchString
    this.setCurrentLanguages()
    this.setCurrentLocations()
  },
  watch: {
    hover() {
      this.handleBlur()
    },
    currentExperts() {
      this.setCurrentLanguages()
      this.setCurrentLocations()
    }
  },
  computed: {
    ...mapGetters([
      'isMobile',
      'appWidth',
      'autoComplete',
      'searchString',
      'selectedLocations',
      'selectedLanguages',
      'gMDynamicSize',
      'currentExperts',
      'locations',
      'languages',
      'selectedCategories',
      'filterHasPhone',
      'getDixTranslation'
    ]),
    filteredDataArray() {
      let filtered = []
      if (this.autoComplete) {
        filtered = this.autoComplete.filter((option) => {
          return option.label.toLowerCase().includes(this.input.toLowerCase())
        })
      }
      return filtered
    },
    input: {
      get() {
        return this.debouncedInput
      },
      set(val) {
        this.debouncedInput = val
        if (this.timeout) clearTimeout(this.timeout)
        let debounce = 1000
        if (val === '') {
          debounce = 0
        }
        this.timeout = setTimeout(() => {
          this.showChange(val)
        }, debounce)
      }
    }
  },
  methods: {
    ...mapActions(['updateSelectedCategories']),
    ...mapMutations([
      'changeSelectedLanguages',
      'resetSelectedLanguages',
      'changeSelectedLocations',
      'resetSelectedLocations',
      'changeSearchString',
      'changeShowCategorySelectModal',
      'resetSelectedCategories',
      'changeFilterHasPhone'
    ]),
    setCurrentLanguages() {
      let filteredLanguages = []
      const uniqueLanguageGroups = [
        ...new Set(
          this.currentExperts.map((expert) => {
            return expert.languagesDd
          })
        )
      ]
      filteredLanguages = this.languages.filter((language) => {
        return uniqueLanguageGroups.find((groupString) => {
          return groupString.includes(`|${language.nr.toString()}|`)
        })
      })
      this.currentLanguages = filteredLanguages
    },
    setCurrentLocations() {
      let filteredLocations = []
      const uniqueLocationsGroups = [
        ...new Set(
          this.currentExperts.map((expert) => {
            return expert.locations
          })
        )
      ]
      filteredLocations = this.locations.filter((location) => {
        return uniqueLocationsGroups.find((groupString) => {
          return groupString.includes(`|${location.userLanguage.toString()}|`)
        })
      })
      this.currentLocations = filteredLocations
    },
    handleFocus() {
      if (this.isMobile) {
        window.scrollTo(0, 0)
        document.body.scrollTop = 0
        this.scrollToPosition(250)
        requestAnimationFrame(() => {
          window.scrollTo(0, 0)
          document.body.scrollTop = 0
          this.scrollToPosition(250)
          setTimeout(() => {
            window.scrollTo(0, 0)
            document.body.scrollTop = 0
            this.scrollToPosition(250)
          }, 50)
        })
      }
    },
    scrollToPosition(position) {
      const ref = document.getElementById('selectExpertList')
      if (ref) {
        ref.scrollTop = position
        requestAnimationFrame(() => {
          ref.scrollTop = position
          setTimeout(() => {
            ref.scrollTop = position
          }, 50)
        })
      }
    },
    handleBlur() {
      if (this.hover === false) {
        this.$refs.autocomplete.setSelected(this.debouncedInput)
      }
    },
    languageIsSelected(language) {
      let bool = false
      if (
        this.selectedLanguages.length > 0 &&
        this.selectedLanguages[0] &&
        language.nr === this.selectedLanguages[0].nr
      ) {
        bool = true
      }
      return bool
    },
    locationIsSelected(location) {
      let bool = false
      if (
        this.selectedLocations.length > 0 &&
        this.selectedLocations[0] &&
        location.nr === this.selectedLocations[0].nr
      ) {
        bool = true
      }
      return bool
    },
    resetSearch() {
      this.handleFocus()
      if (this.selectedCategories[0]) {
        this.resetSelectedCategories()
        this.input = ''
      }
    },
    showChange(search) {
      this.changeSearchString(search)
      this.handleFocus()
      console.log(search)
      if (this.selectedLanguages.length > 0 && this.selectedLocations.length > 0) {
        this.resetSelectedLocations()
        this.resetSelectedLanguages()
      }
      if (!search && this.selectedCategories.length > 0) {
        this.resetSelectedCategories()
      }
    }
  }
}
</script>

<style scoped>
.selected-category {
  border-bottom: 2px solid;
  border-color: #f95268;
}
</style>
