import Vue from 'vue'
import Vuex from 'vuex'

import dixModule from './store/modules/dixModule'
import dropdownModule from './store/modules/dropdownModule'
import expertsModule from './store/modules/expertsModule'
import statesModule from './store/modules/statesModule'
import userModule from './store/modules/userModule'
import authModule from './store/modules/authModule'

Vue.use(Vuex)

export default new Vuex.Store<any>({
  modules: {
    dixModule,
    dropdownModule,
    expertsModule,
    statesModule,
    userModule,
    authModule
  }
})
