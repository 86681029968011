<template>
  <p
    :class="[
      { 'sk-font-bold': bold },
      { 'sk-font-regular': !bold },
      { 'sk-selectable': selectable },
      { 'sk-user-select-none': !selectable }
    ]"
    :style="{
      marginBottom: 0 + 'px',
      display: '-webkit-box',
      webkitBoxOrient: 'vertical',
      overflow: 'hidden',
      webkitLineClamp: lineClamp !== 0 ? lineClamp : 'none',

      fontSize: isMobile ? 12 + 'px' : 16 + 'px',
      lineHeight: isMobile ? 14 + 'px' : 20 + 'px',
      color: inverted ? 'white' : '#1a304c',
      textAlign: textAlignCenter ? 'center' : 'left',
      cursor: this.text.startsWith('sk') && translateMode ? 'pointer' : ''
    }"
    @click="translateMode ? openEditTranslationModal() : $emit('click')"
  >
    {{ getDixTranslation(text) }}
  </p>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  props: {
    lineClamp: {
      type: Number,
      default: 0
    },
    text: {
      type: String,
      default: 'text missing'
    },
    textAlignCenter: {
      type: Boolean,
      default: false
    },
    inverted: {
      type: Boolean,
      default: false
    },
    bold: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'isMobile',
      'translateMode',
      'gMDynamicSize',
      'getDynamicSize',
      'getDixTranslation'
    ])
  },
  methods: {
    ...mapMutations(['changeShowEditTranslationModal', 'setToolTextToBeTranslated']),
    openEditTranslationModal() {
      if (this.text.startsWith('sk')) {
        this.setToolTextToBeTranslated(this.text)
        this.changeShowEditTranslationModal(true)
      }
    }
  }
}
</script>
