<template>
  <div>
    <P2SK
      :style="{ marginBottom: gMDynamicSize(10) + 'px' }"
      :text="'skEditorProfile'"
      :bold="true"
    />
    <div :style="{ display: 'flex', flexWrap: 'wrap' }">
      <div
        :style="{
          width: gMDynamicSize(95, 414, 280) + 'px',
          marginRight: gMDynamicSize(35, 414, 280) + 'px'
        }"
      >
        <textInputSK :editorItem="getEditorItem('title')" />
      </div>
      <div
        :style="{
          width: gMDynamicSize(170, 414, 280) + 'px',
          marginRight: gMDynamicSize(35, 414, 280) + 'px'
        }"
      >
        <textInputSK :editorItem="getEditorItem('firstName')" />
      </div>
      <div
        :style="{
          width: gMDynamicSize(170, 414, 280) + 'px'
        }"
      >
        <textInputSK :editorItem="getEditorItem('lastName')" />
      </div>
    </div>
    <P2SK
      :style="{ marginBottom: gMDynamicSize(10) + 'px' }"
      :text="'skEditorProfileKorrespondenzsprache'"
      :bold="true"
    />
    <P2SK
      :style="{ marginBottom: gMDynamicSize(10) + 'px' }"
      :text="'skEditorProfileKorrespondenzspracheInfo'"
    />
    <div
      :style="{
        marginBottom: gMDynamicSize(50, 414, 280) + 'px'
      }"
    >
      <dropdownInputSK :editorItem="getEditorItem('corrLanguageDd')" :dropdowns="corrlanguages" />
    </div>

    <P2SK
      :style="{ marginBottom: gMDynamicSize(10) + 'px' }"
      :text="'skEditorProfileSprachen'"
      :bold="true"
    />
    <P2SK
      :style="{
        marginBottom: gMDynamicSize(8, 414, 280) + 'px'
      }"
      :text="'skEditorProfileSprachenInfo'"
    />
    <div
      :style="{
        marginBottom: gMDynamicSize(36, 414, 280) + 'px'
      }"
    >
      <dropdownInputSK
        :multiple="true"
        :editorItem="getEditorItem('languagesDd')"
        :dropdowns="languages"
      />
    </div>
    <P2SK
      :style="{ marginBottom: gMDynamicSize(10) + 'px' }"
      :text="'skEditorProfileRegionen'"
      :bold="true"
    />
    <P2SK
      :style="{ marginBottom: gMDynamicSize(10) + 'px' }"
      :text="'skEditorProfileRegionenInfo'"
    />
    <div
      :style="{
        marginBottom: gMDynamicSize(36, 414, 280) + 'px'
      }"
    >
      <dropdownInputSK
        :multiple="true"
        :editorItem="getEditorItem('locations')"
        :dropdowns="locations"
      />
    </div>
    <P2SK
      :style="{ marginBottom: gMDynamicSize(10) + 'px' }"
      :text="'skEditorProfilePicture'"
      :bold="true"
    />
    <P2SK
      :style="{ marginBottom: gMDynamicSize(10) + 'px' }"
      :text="'skEditorProfilePictureInfo'"
    />
    <div
      :style="{
        width: 100 + '%',
        display: 'flex',
        flexWrap: 'wrap'
      }"
    >
      <chipElementSK
        :style="{
          marginBottom: gMDynamicSize(36) + 'px'
        }"
        @click="changeShowPictureUploadModal(true)"
        :text="'skEditorProfileUploadPicture'"
        :dropdown="true"
      />
      <chipElementSK
        v-if="base64"
        :style="{
          marginBottom: gMDynamicSize(36) + 'px'
        }"
        @click="deletePictureFormFile()"
        :text="'skEditorProfileDeletePicture'"
        :dropdown="true"
      />
      <div
        v-if="base64"
        class="thumbnail"
        :style="{
          marginLeft: gMDynamicSize(10, 414, 280) + 'px',
          width: gMDynamicSize(90, 414, 280) + 'px',
          height: gMDynamicSize(90, 414, 280) + 'px'
        }"
      >
        <img class="portrait" :src="base64" />
      </div>
    </div>
    <div
      :style="{
        marginBottom: gMDynamicSize(50, 414, 280) + 'px'
      }"
    >
      <P2SK
        :style="{ marginBottom: gMDynamicSize(10) + 'px' }"
        :text="'skEditorProfilePrivacyPolicyInfo'"
        :bold="true"
      />
      <chipElementSK
        :style="{ marginBottom: gMDynamicSize(10) + 'px' }"
        @click="changeShowPrivacyPolicyModal(true)"
        :text="'skEditorProfileShowPrivacyPolicy'"
        :dropdown="true"
      />
      <div
        :style="{
          marginLeft: gMDynamicSize(20, 414, 280) + 'px'
        }"
      >
        <checkboxInputSK
          :editorItem="getEditorItem('privacyPolicyAccepted')"
          :disabled="currentPrivacyPolicyAccepted"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  data() {
    return {
      base64: null
    }
  },
  watch: {
    pictureFormFile(file) {
      if (file) {
        this.setBase64(file)
      } else {
        this.base64 = null
      }
    }
  },
  props: {
    editors: {
      type: Array
    }
  },
  computed: {
    ...mapGetters([
      'appWidth',
      'appHeight',
      'pictureFormFile',
      'gMDynamicSize',
      'editingItem',
      'corrlanguages',
      'languages',
      'locations'
    ]),
    currentPrivacyPolicyAccepted() {
      let bool = false
      if (this.editingItem) {
        bool = this.editingItem.privacyPolicyAccepted
      }
      return bool
    }
  },
  methods: {
    ...mapMutations([
      'deletePictureFormFile',
      'changeShowPictureUploadModal',
      'changeShowPrivacyPolicyModal'
    ]),
    getEditorItem(field) {
      if (this.editors) {
        return this.editors.find((editorItem) => {
          return editorItem.field.toLowerCase() === field.toLowerCase()
        })
      }
    },
    setBase64(blob) {
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        this.base64 = reader.result
      }
    }
  }
}
</script>

<style scoped>
.thumbnail {
  position: relative;
  overflow: hidden;
}
.thumbnail img {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: auto;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.thumbnail img.portrait {
  /* width: 100%; */
  height: auto;
}
</style>
