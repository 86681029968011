var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[(_vm.appWidth && _vm.appHeight)?_c('ion-app',[_c('MenuOverlaySK'),_c('transition',{attrs:{"name":_vm.transition}},[_c('ion-vue-router',{key:_vm.$route.fullPath})],1),_c('b-modal',{style:({
        maxWidth: 600 + 'px',
        height: _vm.appWidth < 484 ? _vm.appHeight + 'px' : 700 + 'px',
        margin: 'auto',
        border: '1px solid'
      }),attrs:{"active":_vm.showEmailFeedbackModal,"full-screen":"","scroll":"keep","can-cancel":false},on:{"update:active":function($event){_vm.showEmailFeedbackModal=$event}}},[_c('EmailFeedbackModalSK')],1),_c('b-modal',{style:({
        maxWidth: 600 + 'px',
        height: _vm.appWidth < 484 ? _vm.appHeight + 'px' : 700 + 'px',
        margin: 'auto',
        border: '1px solid'
      }),attrs:{"active":_vm.showEditTranslationModal,"full-screen":"","scroll":"keep","can-cancel":false},on:{"update:active":function($event){_vm.showEditTranslationModal=$event}}},[_c('EditTranslationModalSK')],1),_c('b-loading',{staticStyle:{"background":"white"},attrs:{"is-full-page":true,"can-cancel":true},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}},[_c('div',{style:({ flexDirection: 'column', display: 'flex' })},[_c('img',{staticStyle:{"z-index":"1"},attrs:{"src":require("./assets/sheknows_logo.png"),"width":_vm.gMDynamicSize(256) + 'px',"height":_vm.gMDynamicSize(229) + 'px'}}),_c('div',{staticClass:"level-item"},[_c('b-icon',{attrs:{"pack":"fas","icon":"spinner","size":"is-large","custom-class":"fa-spin"}})],1)])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }