var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card",style:({
    height: _vm.appWidth < 484 ? 100 + '%' : 700 - 2 + 'px',
    flexDirection: 'column',
    display: 'flex'
  })},[_c('div',{staticClass:"modal-card-head",style:({
      paddingLeft: _vm.gMDynamicSize(33) + 'px',
      paddingRight: _vm.gMDynamicSize(33) + 'px',
      background: 'white',
      borderBottom: 'none'
    })},[_c('div',{staticClass:"media is-mobile",style:({
        width: 100 + '%',
        marginBottom: _vm.gMDynamicSize(27, 960, 600) + 'px'
      })},[_c('div',{staticClass:"media-content level-item",style:({
          marginRight: 0 + 'px'
        })},[_c('H2SK',{attrs:{"text":'skSendEmailSendFeedback',"bold":true}})],1),_c('div',{staticClass:"media-right",style:({
          marginLeft: 0 + 'px'
        })},[_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"src":require("./../../assets/SKicons/closeBlue.svg"),"width":_vm.gMDynamicSize(20) + 'px',"height":_vm.gMDynamicSize(20) + 'px'},on:{"click":function($event){return _vm.changeShowEmailFeedbackModal(false)}}})])])]),(_vm.editors)?_c('div',{staticClass:"modal-card-body",style:({
      padding: 0 + 'px',
      marginLeft: _vm.appWidth > 414 ? _vm.gMDynamicSize(30, 960, 600) + 'px' : 20 + 'px',
      marginRight: _vm.appWidth > 414 ? _vm.gMDynamicSize(30, 960, 600) + 'px' : 20 + 'px',
      marginBottom: _vm.appWidth > 414 ? _vm.gMDynamicSize(30, 960, 600) + 'px' : 20 + 'px'
    })},[_c('div',{style:({
        marginBottom: _vm.gMDynamicSize(27, 960, 600) + 'px',
        minHeight: _vm.gMDynamicSize(20) + 'px'
      })},[(_vm.$v.editingItem.subject.$invalid)?_c('div',[_c('P2SK',{style:({ color: 'red' }),attrs:{"text":'skFeedbackModalMissingSubjectError'}})],1):(_vm.$v.editingItem.text.$invalid)?_c('div',[_c('P2SK',{style:({ color: 'red' }),attrs:{"text":'skFeedbackModalMissingTextDdError'}})],1):(_vm.$v.editingItem.email.$invalid)?_c('div',[_c('P2SK',{style:({ color: 'red' }),attrs:{"text":'skFeedbackModalMissingEmailError'}})],1):_vm._e()]),_c('P2SK',{style:({ marginBottom: _vm.gMDynamicSize(17) + 'px' }),attrs:{"text":'skSendEmailBetreff:',"bold":true}}),_c('textInputSK',{attrs:{"editorItem":_vm.getEditorItem('subject')}}),_c('P2SK',{style:({ marginBottom: _vm.gMDynamicSize(10) + 'px' }),attrs:{"text":'skSendEmailText:',"bold":true}}),_c('textareaInputSK',{attrs:{"editorItem":_vm.getEditorItem('text')}}),_c('P2SK',{style:({ marginBottom: _vm.gMDynamicSize(17) + 'px' }),attrs:{"text":'skSendEmailE-Mail-Adresse:',"bold":true}}),_c('textInputSK',{attrs:{"editorItem":_vm.getEditorItem('email')}})],1):_vm._e(),_c('div',{staticClass:"modal-card-foot",style:({
      padding: '0 0 0 0px'
    })},[_c('div',{style:({
        width: 100 + '%',
        textAlign: 'center',
        height: _vm.gMDynamicSize(70, 670, 670) + 'px'
      })},[_c('buttonElementSK',{style:({
          display: 'inline-flex',
          marginTop: _vm.gMDynamicSize(14, 670, 670) + 'px'
        }),attrs:{"disabled":_vm.$v.editingItem.subject.$invalid ||
          _vm.$v.editingItem.text.$invalid ||
          _vm.$v.editingItem.email.$invalid,"text":'skFeedbackSendEmail'},on:{"click":function($event){return _vm.confirmFeedbackEditor()}}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }