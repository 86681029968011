// tslint:disable: no-shadowed-variable
import { GetterTree, MutationTree } from 'vuex'
import { Dix, Istate } from '../types/dixTypes'

const state: Istate = {
  dix: [],
  toolTextToBeTranslated: ''
}

const mutations: MutationTree<Istate> = {
  setDix: (state, list: any) => {
    state.dix = list
  },
  setToolTextToBeTranslated: (state, tooltext: string) => {
    state.toolTextToBeTranslated = tooltext
  },
  editDix: (state, dixObj: any) => {
    const index: number = state.dix.map((e: any) => e.toolText).indexOf(dixObj.toolText)
    if (index !== -1) {
      state.dix.splice(index, 1, dixObj)
    }
  }
}

const getters: GetterTree<Istate, any> = {
  dix: (state) => {
    return state.dix
  },
  toolTextToBeTranslated: (state) => {
    return state.toolTextToBeTranslated
  },
  getDixTranslation: (state, rootGetters) => (toolText: string) => {
    let translation = ''
    let arr: Dix[] = []
    if (
      rootGetters.dix.length > 0 &&
      toolText !== undefined &&
      toolText !== '' &&
      toolText !== ' '
    ) {
      arr = rootGetters.dix.filter((item: Dix) => {
        return item.toolText.toLowerCase() === toolText.toLowerCase()
      })

      // for public release !

      // if (arr.length > 0) {
      //   if (arr[0].userLanguage !== '') {
      //     translation = arr[0].userLanguage
      //   } else if (process.env.NODE_ENV !== 'production') {
      //     translation = toolText
      //   }
      // } else if (process.env.NODE_ENV !== 'production') {
      //   translation = toolText
      // }
      if (arr.length > 0) {
        if (arr[0].userLanguage !== '') {
          translation = arr[0].userLanguage
        } else {
          translation = toolText
        }
      } else {
        translation = toolText
      }
    }
    return translation
  }
}

export default {
  state,
  mutations,
  getters
}
