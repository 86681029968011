<template>
  <div class="ion-page skViewPort">
    <ion-content>
      <b-sidebar :fullheight="true" :right="true" :fullwidth="false" v-model="open" :overlay="true">
        <div class="level-item">
          <div
            :style="{
              position: 'fixed',
              top: 27 + 'px',
              right: 22 + 'px'
            }"
          >
            <img
              style="cursor: pointer"
              src="./../../assets/SKicons/closeWhite.svg"
              :width="gMDynamicSize(32) + 'px'"
              :height="gMDynamicSize(32) + 'px'"
              @click="changeShowMenu(false)"
            />
          </div>
        </div>
        <div
          :style="{
            paddingLeft: gMDynamicSize(63) + 'px',
            paddingRight: gMDynamicSize(33) + 'px',
            paddingTop: gMDynamicSize(120) + 'px',
            paddingBottom: gMDynamicSize(11) + 'px'
          }"
        >
          <H2SK
            :inverted="true"
            :style="{
              cursor: 'pointer',
              marginBottom: 20 + 'px',
              marginRight: 20 + 'px'
            }"
            @click="goToProjectInfoPage(), changeShowMenu(false)"
            :text="'skHeaderAbout'"
          />
          <H2SK
            :inverted="true"
            :style="{
              cursor: 'pointer',
              marginBottom: 20 + 'px',
              marginRight: 20 + 'px'
            }"
            @click="goToFAQPage(), changeShowMenu(false)"
            :text="'FAQ'"
          />
          <H2SK
            :inverted="true"
            :style="{
              cursor: 'pointer',
              marginBottom: 20 + 'px',
              marginRight: 20 + 'px'
            }"
            @click="changeShowEmailFeedbackModal(true), changeShowMenu(false)"
            :text="'skHeaderFeedback'"
          />
          <H2SK
            :inverted="true"
            v-if="signedIn"
            :style="{
              cursor: 'pointer',
              marginBottom: 20 + 'px',
              marginRight: 20 + 'px'
            }"
            :text="'skHeaderEditProfile'"
            @click="goToProfilePage(), changeShowMenu(false)"
          />
          <H2SK
            :inverted="true"
            v-else
            :style="{
              cursor: 'pointer',
              marginBottom: 20 + 'px',
              marginRight: 20 + 'px'
            }"
            :text="'skHeaderCreateProfile'"
            @click="signIn(), changeShowMenu(false)"
          />
          <H2SK
            :inverted="true"
            v-if="signedIn"
            :style="{
              cursor: 'pointer',
              marginBottom: 20 + 'px',
              marginRight: 30 + 'px'
            }"
            :text="'skHeaderLogout'"
            @click="signOut(), changeShowMenu(false)"
          />
          <H2SK
            :inverted="true"
            v-else
            :style="{
              cursor: 'pointer',
              marginBottom: 20 + 'px',
              marginRight: 30 + 'px'
            }"
            :text="'skHeaderLogin'"
            @click="signIn(), changeShowMenu(false)"
          />
          <div
            :style="{
              marginLeft: 'auto',
              display: 'flex',
              flexWrap: 'wrap'
            }"
          >
            <div>
              <H2SK
                :inverted="true"
                :style="{
                  cursor: 'pointer',
                  marginBottom: 20 + 'px',
                  marginRight: 30 + 'px'
                }"
                :text="'skHeaderGerman'"
                @click="changeLanguage(1), changeShowMenu(false)"
              />
            </div>
            <div>
              <H2SK
                :inverted="true"
                :style="{
                  cursor: 'pointer',
                  marginBottom: 20 + 'px',
                  marginRight: 30 + 'px'
                }"
                :text="'skHeaderFrench'"
                @click="changeLanguage(2), changeShowMenu(false)"
              />
            </div>
            <div>
              <H2SK
                :inverted="true"
                :style="{
                  cursor: 'pointer',
                  marginBottom: 20 + 'px',
                  marginRight: 30 + 'px'
                }"
                :text="'skHeaderItalian'"
                @click="changeLanguage(3), changeShowMenu(false)"
              />
            </div>
            <div>
              <H2SK
                :inverted="true"
                :style="{
                  cursor: 'pointer',
                  marginBottom: 20 + 'px',
                  marginRight: 30 + 'px'
                }"
                :text="'skHeaderEnglish'"
                @click="changeLanguage(4), changeShowMenu(false)"
              />
            </div>
          </div>
        </div>
      </b-sidebar>
    </ion-content>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  data() {
    return {
      languageNr: 1
    }
  },
  computed: {
    ...mapGetters([
      'userFullName',
      'canTranslate',
      'signedIn',
      'gMDynamicSize',
      'showMenu',
      'appWidth'
    ]),
    open: {
      get() {
        return this.showMenu
      },
      set(bool) {
        this.changeShowMenu(bool)
      }
    }
  },
  methods: {
    ...mapActions(['changeLanguageWithReload']),
    ...mapMutations(['changeShowEmailFeedbackModal', 'changeShowMenu', 'signOut', 'signIn']),
    changeLanguage(languageNr) {
      this.changeLanguageWithReload({ newLanguageNr: languageNr })
    },
    goToProfilePage() {
      if (this.$route.name !== 'profilePage') {
        this.$router.push({
          name: 'profilePage',
          params: {
            userFullName: this.userFullName
          }
        })
      } else {
        this.$router.replace({
          name: 'profilePage',
          params: {
            userFullName: this.userFullName
          }
        })
      }
    },
    goToProjectInfoPage() {
      if (this.$route.name !== 'projectInfoPage') {
        this.$router.push({
          name: 'projectInfoPage'
        })
      }
    },
    goToFAQPage() {
      if (this.$route.name !== 'faqPage') {
        this.$router.push({
          name: 'faqPage'
        })
      }
    }
  }
}
</script>

<style>
.b-sidebar .sidebar-content.is-fullheight {
  background: #f95268;
  min-width: 400px;
}
a:link {
  color: black;
}
a:visited {
  color: black;
}

a:hover {
  color: black;
}

a:active {
  color: black;
}
</style>
