<template>
  <div v-if="editorItem && editingItem" class="ion-page shsViewPort">
    <ion-content fullscreen>
      <headerSHS
        class="shs-header"
        :show-back="currentIndex !== 1 && this.$route.params.editingItemIsInvalid === 'true'"
        :show-cancel="this.$route.params.editingItemIsInvalid === 'false'"
        @backClick="setTransition('amasysGoBack'), $router.go(-1)"
      />
      <textContainerSHS>
        <titleSHS
          :text="
            this.$route.params.editingItemIsInvalid === 'true'
              ? getDixTranslation('editorTitleOnboarding') +
                ' ' +
                currentIndex +
                '/' +
                editorList.length
              : editorItem.toolText
          "
        />
        <textSHS :text="editorItem.info" />
      </textContainerSHS>
      <linkContainerSHS>
        <ion-list v-if="currentDropdowns.length > 0 && currentDropdowns">
          <ion-radio-group :value="currentValue" @ionChange="setEditing($event.detail.value)">
            <ion-item v-for="(item, index) in currentDropdowns" :key="index" color="secondary">
              <ion-label :style="{ minHeight: (appWidth - 45) / 5.7 + 'px' }">
                <div
                  class="container"
                  :style="{
                    fontSize: appWidth / (375 / 22) + 'px',
                    lineHeight: appWidth / (375 / 27) + 'px'
                  }"
                >
                  <div :style="textStyle">{{ getDixTranslation(item.userLanguage) }}</div>
                </div>
              </ion-label>
              <ion-radio slot="start" color="success" :value="item.nr"></ion-radio>
            </ion-item>
          </ion-radio-group>
        </ion-list>
      </linkContainerSHS>
      <buttonContainerSHS :style="{ paddingTop: appWidth / (375 / 30) + 'px' }">
        <buttonSHS
          :disabled="$v.editing.$invalid"
          :text="this.$route.params.editingItemIsInvalid === 'true' ? 'buttonNext' : 'buttonOk'"
          @click="emitEditing()"
        />
      </buttonContainerSHS>
      <linkContainerSHS
        v-if="
          $route.params.editorName == 'firstLogin' ||
          $route.params.editorName == 'assessmentSettings'
        "
      >
        <linkSHS
          :text="'editorLinkGeneralExplanation'"
          @click="
            setTransition('amasysForward'),
              $router.push({
                name: 'faqOverlay',
                params: {
                  toolText: 'editorLinkGeneralExplanation'
                }
              })
          "
        />
        <linkSHS
          :text="'editorLinkDataUsage'"
          @click="
            setTransition('amasysForward'),
              $router.push({
                name: 'faqOverlay',
                params: {
                  toolText: 'editorLinkDataUsage'
                }
              })
          "
        />
      </linkContainerSHS>
      <linkContainerSHS v-if="$route.params.editorName == 'mainInfoAdd'">
        <linkSHS
          :text="'mainInfoAddCancelLink'"
          @click="
            setTransition('amasysGoBack'),
              $router.push({
                name: 'dashboardCounterspeech'
              })
          "
        />
      </linkContainerSHS>
    </ion-content>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { numeric } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      editing: null,
      textStyle: {
        display: '-webkit-box',
        webkitLineClamp: 5,
        webkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'left',
        whiteSpace: 'normal'
      }
    }
  },
  validations() {
    return {
      editing: {
        numeric
      }
    }
  },
  mounted() {
    if (this.editingItem === null) {
      this.$router.replace({ path: '/' })
      /* this.initEditingItem({ editorType: this.$route.params.editorName });
      if (this.currentIndex !== 1) {
        const firstEditorItem = this.getEditors(
          this.$route.params.editorName,
        )[0];
        const name = firstEditorItem.inputType;
        this.$router.replace({
          name: name,
          params: {
            guid: firstEditorItem.guid,
            editorName: this.$route.params.editorName,
            editingItemIsInvalid: true,
          },
        });
      } */
    }
  },
  computed: {
    ...mapGetters([
      'isFirstLogin',
      'getDixTranslation',
      'getDropdowns',
      'editingItem',
      'getEditors',
      'appWidth'
    ]),
    currentIndex() {
      if (this.editorList) {
        const index = this.editorList
          .map((item) => {
            return item.guid
          })
          .indexOf(this.$route.params.guid)
        return index + 1
      }
    },
    editorItem() {
      if (this.editorList) {
        return this.editorList.filter((e) => {
          return e.guid === this.$route.params.guid
        })[0]
      }
    },
    editorList() {
      if (
        this.getEditors(this.$route.params.editorName) &&
        this.getEditors(this.$route.params.editorName).length > 0 &&
        this.$route.params.editorName
      ) {
        return this.getEditors(this.$route.params.editorName)
      }
    },
    currentValue() {
      if (this.editing === null && this.editingItem && this.editorItem) {
        this.editing = this.editingItem[this.editorItem.field]
        return this.editingItem[this.editorItem.field]
      }
      if (this.editing) {
        return this.editing
      }
    },
    currentDropdowns() {
      if (this.editorItem && this.getDropdowns(this.editorItem.dropDown)) {
        return this.getDropdowns(this.editorItem.dropDown)
      }
    }
  },
  methods: {
    ...mapActions(['confirmSingleEditUser', 'initEditingItem']),
    ...mapMutations(['editEditingItem', 'setTransition']),
    goToNextEdit() {
      const index = this.editorList
        .map((e) => {
          return e.guid
        })
        .indexOf(this.editorItem.guid)

      if (index + 1 === this.editorList.length) {
        this.setTransition('amasysForward')
        this.$router.push({
          name: this.$route.params.editorName
        })
      } else {
        const nextEditorItem = this.editorList[index + 1]

        const inputType = nextEditorItem.inputType
        this.setTransition('amasysForward')
        this.$router.push({
          name: inputType,
          params: {
            guid: nextEditorItem.guid,
            editorName: this.$route.params.editorName,
            editingItemIsInvalid: this.$route.params.editingItemIsInvalid.toString()
          }
        })
      }
    },
    setEditing(checked) {
      if (checked) {
        this.editing = checked
      }
    },
    emitEditing() {
      if (this.$route.params.singleEdit) {
        const data = {}
        data.fieldName = this.editorItem.field
        data.ShortOld = this.editingItem[this.editorItem.field]
        data.ShortNew = Math.floor(this.editing)
        if (this.editorItem.tableName.toLowerCase() === 'users') {
          this.confirmSingleEditUser({
            item: data,
            itemGuid: this.editingItem.guid,
            dataType: 'short'
          })
        }
      } else {
        const data = {}
        data.value = Math.floor(this.editing)
        data.field = this.editorItem.field
        this.editEditingItem(data)
        if (this.$route.params.editingItemIsInvalid === 'true') {
          this.goToNextEdit()
        } else {
          this.setTransition('amasysGoBack')
          this.$router.go(-1)
        }
      }
    }
  }
}
</script>
