<template>
  <div
    class="card"
    :style="{
      height: appWidth < 484 ? 100 + '%' : 700 - 2 + 'px',
      flexDirection: 'column',
      display: 'flex'
    }"
  >
    <div
      class="modal-card-head"
      :style="{
        paddingLeft: gMDynamicSize(33) + 'px',
        paddingRight: gMDynamicSize(33) + 'px',
        background: 'white',
        borderBottom: 'none'
      }"
    >
      <div
        class="media is-mobile"
        :style="{
          width: 100 + '%',
          marginBottom: gMDynamicSize(27, 960, 600) + 'px'
        }"
      >
        <div
          class="media-left"
          :style="{
            marginRight: 0 + 'px'
          }"
        >
          <p
            class="sk-font-bold"
            :style="{
              marginBottom: gMDynamicSize(27, 960, 600) + 'px',
              fontSize: gMDynamicSize(20) + 'px',
              lineHeight: gMDynamicSize(20) + 'px',
              color: '#1a304c'
            }"
          >
            {{ getDixTranslation('skEditTranslationTitel') }}
          </p>
        </div>
        <div class="media-content level-item"></div>
        <div
          class="media-right"
          :style="{
            marginLeft: 0 + 'px'
          }"
        >
          <img
            style="cursor: pointer"
            @click="changeShowEditTranslationModal(false)"
            src="./../../assets/SKicons/closeBlue.svg"
            :width="gMDynamicSize(20, 960, 600) + 'px'"
            :height="gMDynamicSize(20, 960, 600) + 'px'"
          />
        </div>
      </div>
    </div>
    <div
      v-if="editors && this.editingItem"
      class="modal-card-body"
      :style="{
        padding: 0 + 'px',
        marginLeft: appWidth > 414 ? gMDynamicSize(30, 960, 600) + 'px' : 20 + 'px',
        marginRight: appWidth > 414 ? gMDynamicSize(30, 960, 600) + 'px' : 20 + 'px',
        marginBottom: appWidth > 414 ? gMDynamicSize(30, 960, 600) + 'px' : 20 + 'px'
      }"
    >
      <p
        class="sk-font-bold"
        :style="{
          marginBottom: 0 + 'px',
          fontSize: gMDynamicSize(16) + 'px',
          lineHeight: gMDynamicSize(20) + 'px',
          color: '#1a304c'
        }"
      >
        german
      </p>
      <textInputSK :editorItem="getEditorItem('german')" />
      <p
        class="sk-font-bold"
        :style="{
          marginBottom: 0 + 'px',
          fontSize: gMDynamicSize(16) + 'px',
          lineHeight: gMDynamicSize(20) + 'px',
          color: '#1a304c'
        }"
      >
        french
      </p>
      <textInputSK :editorItem="getEditorItem('french')" />
      <p
        class="sk-font-bold"
        :style="{
          marginBottom: 0 + 'px',
          fontSize: gMDynamicSize(16) + 'px',
          lineHeight: gMDynamicSize(20) + 'px',
          color: '#1a304c'
        }"
      >
        italian
      </p>
      <textInputSK :editorItem="getEditorItem('italian')" />
      <p
        class="sk-font-bold"
        :style="{
          marginBottom: 0 + 'px',
          fontSize: gMDynamicSize(16) + 'px',
          lineHeight: gMDynamicSize(20) + 'px',
          color: '#1a304c'
        }"
      >
        english
      </p>
      <textInputSK :editorItem="getEditorItem('english')" />
    </div>
    <div
      class="modal-card-foot"
      :style="{
        padding: '0 0 0 0px'
      }"
    >
      <div
        :style="{
          width: 100 + '%',
          textAlign: 'center',
          height: gMDynamicSize(70, 670, 670) + 'px'
        }"
      >
        <b-button
          class="sk-button"
          @click="confirmTranslationEditor()"
          :style="{
            display: 'inline-flex',
            marginTop: gMDynamicSize(14, 670, 670) + 'px',
            background: '#3E7291',
            color: 'white',
            fontSize: gMDynamicSize(16, 670, 670) + 'px',
            lineHeight: gMDynamicSize(16, 670, 670) + 'px'
          }"
          >{{ getDixTranslation('skEditTranslationButton') }}</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import axiosCRUD from '../../axios/axiosCRUD'

export default {
  data() {
    return {
      editors: null
    }
  },
  mounted() {
    axiosCRUD
      .get(`/sheknowsprivate/dix/${this.toolTextToBeTranslated}`, {
        headers: { Authorization: this.bearerToken }
      })
      .then((dix) => {
        this.setEditingItem({ editing: dix.data })
        this.editors = [
          { field: 'german', placeholder: 'german', format: '' },
          { field: 'french', placeholder: 'french', format: '' },
          { field: 'italian', placeholder: 'italian', format: '' },
          { field: 'english', placeholder: 'english', format: '' }
        ]
      })
  },
  beforeDestroy() {
    this.deleteEditingItem()
  },
  computed: {
    ...mapGetters([
      'toolTextToBeTranslated',
      'userLanguageDd',
      'userGuid',
      'bearerToken',
      'editingItem',
      'appHeight',
      'appWidth',
      'getDynamicSize',
      'gMDynamicSize'
    ])
  },
  methods: {
    ...mapMutations([
      'editDix',
      'changeShowEditTranslationModal',
      'setEditingItem',
      'deleteEditingItem'
    ]),
    getEditorItem(field) {
      if (this.editors) {
        return this.editors.find((editorItem) => {
          return editorItem.field.toLowerCase() === field.toLowerCase()
        })
      }
    },
    confirmTranslationEditor() {
      this.editingItem.languageDd = this.userLanguageDd
      this.editingItem.lastWho = this.userGuid
      console.log(this.editingItem)
      axiosCRUD
        .put(`/sheknowsprivate/dix`, this.editingItem, {
          headers: { Authorization: this.bearerToken }
        })
        .then((response) => {
          if (response.status === 200) {
            this.editDix(response.data)
            this.changeShowEditTranslationModal(false)
          }
        })
    }
  }
}
</script>
