<template>
  <div
    class="card"
    :style="{
      height: appWidth < 484 ? 100 + '%' : 700 - 2 + 'px',
      flexDirection: 'column',
      display: 'flex'
    }"
  >
    <div
      class="modal-card-head"
      :style="{
        paddingLeft: gMDynamicSize(33) + 'px',
        paddingRight: gMDynamicSize(33) + 'px',
        background: 'white',
        borderBottom: 'none'
      }"
    >
      <div
        class="media is-mobile"
        :style="{
          width: 100 + '%',
          marginBottom: gMDynamicSize(27, 960, 600) + 'px'
        }"
      >
        <div
          class="media-content level-item"
          :style="{
            marginRight: 0 + 'px'
          }"
        >
          <H2SK :text="'skSendEmailSendFeedback'" :bold="true" />
        </div>
        <div
          class="media-right"
          :style="{
            marginLeft: 0 + 'px'
          }"
        >
          <img
            style="cursor: pointer"
            @click="changeShowEmailFeedbackModal(false)"
            src="./../../assets/SKicons/closeBlue.svg"
            :width="gMDynamicSize(20) + 'px'"
            :height="gMDynamicSize(20) + 'px'"
          />
        </div>
      </div>
    </div>
    <div
      v-if="editors"
      class="modal-card-body"
      :style="{
        padding: 0 + 'px',
        marginLeft: appWidth > 414 ? gMDynamicSize(30, 960, 600) + 'px' : 20 + 'px',
        marginRight: appWidth > 414 ? gMDynamicSize(30, 960, 600) + 'px' : 20 + 'px',
        marginBottom: appWidth > 414 ? gMDynamicSize(30, 960, 600) + 'px' : 20 + 'px'
      }"
    >
      <div
        :style="{
          marginBottom: gMDynamicSize(27, 960, 600) + 'px',
          minHeight: gMDynamicSize(20) + 'px'
        }"
      >
        <div v-if="$v.editingItem.subject.$invalid">
          <P2SK :style="{ color: 'red' }" :text="'skFeedbackModalMissingSubjectError'" />
        </div>
        <div v-else-if="$v.editingItem.text.$invalid">
          <P2SK :style="{ color: 'red' }" :text="'skFeedbackModalMissingTextDdError'" />
        </div>
        <div v-else-if="$v.editingItem.email.$invalid">
          <P2SK :style="{ color: 'red' }" :text="'skFeedbackModalMissingEmailError'" />
        </div>
      </div>
      <P2SK
        :style="{ marginBottom: gMDynamicSize(17) + 'px' }"
        :text="'skSendEmailBetreff:'"
        :bold="true"
      />
      <textInputSK :editorItem="getEditorItem('subject')" />
      <P2SK
        :style="{ marginBottom: gMDynamicSize(10) + 'px' }"
        :text="'skSendEmailText:'"
        :bold="true"
      />
      <textareaInputSK :editorItem="getEditorItem('text')" />

      <P2SK
        :style="{ marginBottom: gMDynamicSize(17) + 'px' }"
        :text="'skSendEmailE-Mail-Adresse:'"
        :bold="true"
      />
      <textInputSK :editorItem="getEditorItem('email')" />
    </div>
    <div
      class="modal-card-foot"
      :style="{
        padding: '0 0 0 0px'
      }"
    >
      <div
        :style="{
          width: 100 + '%',
          textAlign: 'center',
          height: gMDynamicSize(70, 670, 670) + 'px'
        }"
      >
        <buttonElementSK
          :style="{
            display: 'inline-flex',
            marginTop: gMDynamicSize(14, 670, 670) + 'px'
          }"
          :disabled="
            $v.editingItem.subject.$invalid ||
            $v.editingItem.text.$invalid ||
            $v.editingItem.email.$invalid
          "
          :text="'skFeedbackSendEmail'"
          @click="confirmFeedbackEditor()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'
import axiosCRUD from '../../axios/axiosCRUD'

export default {
  data() {
    return {
      editors: null
    }
  },
  validations() {
    return {
      editingItem: {
        subject: {
          required
        },
        text: this.getRules('text'),
        email: {
          email,
          required
        }
      }
    }
  },
  mounted() {
    const obj = {}
    obj.subject = ''
    obj.text = ''
    obj.email = ''

    this.setEditingItem({ editing: obj })

    axiosCRUD.get(`/sheknows/feedbackeditors`).then((feedbackeditors) => {
      this.editors = feedbackeditors.data
    })
  },
  beforeDestroy() {
    this.deleteEditingItem()
  },
  computed: {
    ...mapGetters(['editingItem', 'appHeight', 'appWidth', 'getDynamicSize', 'gMDynamicSize'])
  },
  methods: {
    ...mapMutations(['changeShowEmailFeedbackModal', 'setEditingItem', 'deleteEditingItem']),
    getRules(field) {
      if (this.getEditorItem(field)) {
        return {
          required,
          minLength: minLength(this.getEditorItem(field).minValue),
          maxLength: maxLength(this.getEditorItem(field).maxValue)
        }
      }
    },
    getEditorItem(field) {
      if (this.editors) {
        return this.editors.find((editorItem) => {
          return editorItem.field.toLowerCase() === field.toLowerCase()
        })
      }
    },
    confirmFeedbackEditor() {
      axiosCRUD.put(`/sheknows/sendFeedback`, this.editingItem).then((response) => {
        if (response.status === 204) {
          this.changeShowEmailFeedbackModal(false)
        }
      })
    }
  }
}
</script>
