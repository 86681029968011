<template>
  <div class="ion-page skViewPort">
    <ion-header v-if="!isMobile" no-border>
      <RegularHeaderSK :show-menu="true"> </RegularHeaderSK>
    </ion-header>
    <ion-content :class="contentIsHidden ? 'hide-content-sk' : 'show-content-sk'">
      <div
        :style="{
          background: 'rgba(169, 206, 162, 0.12)',
          paddingBottom: gMDynamicSize(109) + 'px'
        }"
      >
        <div
          class="flex-wrap-container"
          :style="{
            marginTop: gMDynamicSize(48) + 'px'
          }"
        >
          <div
            v-if="appWidth > 1230"
            :style="{
              background: 'white',
              flex: 1,
              height: gMDynamicSize(273, 1441, 1000) + 'px'
            }"
          />

          <div
            :style="{
              background: 'white',
              width: 100 + '%',
              maxWidth: 1180 + 'px',
              position: 'relative'
            }"
          >
            <img
              :style="{
                cursor: 'pointer',
                position: 'absolute',
                right: gMDynamicSize(15) + 'px'
              }"
              @click="goToHomePage()"
              src="../assets/SKicons/closeBlue.svg"
              :width="gMDynamicSize(30) + 'px'"
              :height="gMDynamicSize(30) + 'px'"
            />
            <H1SK
              :style="{
                marginLeft: isMobile
                  ? gMDynamicSize(17) + 'px'
                  : gMDynamicSize(164, 1180, 414) + 'px',
                marginBottom: gMDynamicSize(46) + 'px'
              }"
              :bold="true"
              :text="'skFAQTitle'"
            />
            <H2SK
              class="sk-hover-red"
              :style="{
                marginLeft: isMobile
                  ? gMDynamicSize(17) + 'px'
                  : gMDynamicSize(164, 1180, 414) + 'px',
                marginBottom: gMDynamicSize(13) + 'px'
              }"
              :italic="true"
              :text="'skFAQProfileCreationTitle'"
            />
            <P1SK
              :style="{
                marginLeft: isMobile
                  ? gMDynamicSize(17) + 'px'
                  : gMDynamicSize(273, 1180, 414) + 'px',
                marginRight: isMobile
                  ? gMDynamicSize(17) + 'px'
                  : gMDynamicSize(273, 1180, 414) + 'px',
                marginBottom: gMDynamicSize(46) + 'px'
              }"
              :text="'skFAQProfileCreationText'"
            />
            <H2SK
              class="sk-hover-red"
              :style="{
                marginLeft: isMobile
                  ? gMDynamicSize(17) + 'px'
                  : gMDynamicSize(164, 1180, 414) + 'px',
                marginBottom: gMDynamicSize(13) + 'px'
              }"
              :italic="true"
              :text="'skFAQPhoneTitle'"
            />
            <P1SK
              :style="{
                marginLeft: isMobile
                  ? gMDynamicSize(17) + 'px'
                  : gMDynamicSize(273, 1180, 414) + 'px',
                marginRight: isMobile
                  ? gMDynamicSize(17) + 'px'
                  : gMDynamicSize(273, 1180, 414) + 'px',
                marginBottom: gMDynamicSize(46) + 'px'
              }"
              :text="'skFAQPhoneText'"
            />
            <H2SK
              class="sk-hover-red"
              :style="{
                marginLeft: isMobile
                  ? gMDynamicSize(17) + 'px'
                  : gMDynamicSize(164, 1180, 414) + 'px',
                marginBottom: gMDynamicSize(13) + 'px'
              }"
              :italic="true"
              :text="'skFAQCategoryTitle'"
            />
            <P1SK
              :style="{
                marginLeft: isMobile
                  ? gMDynamicSize(17) + 'px'
                  : gMDynamicSize(273, 1180, 414) + 'px',
                marginRight: isMobile
                  ? gMDynamicSize(17) + 'px'
                  : gMDynamicSize(273, 1180, 414) + 'px',
                marginBottom: gMDynamicSize(46) + 'px'
              }"
              :text="'skFAQCategoryText'"
            />
            <H2SK
              class="sk-hover-red"
              :style="{
                marginLeft: isMobile
                  ? gMDynamicSize(17) + 'px'
                  : gMDynamicSize(164, 1180, 414) + 'px',
                marginBottom: gMDynamicSize(13) + 'px'
              }"
              :italic="true"
              :text="'skFAQProfileContentTitle'"
            />
            <P1SK
              :style="{
                marginLeft: isMobile
                  ? gMDynamicSize(17) + 'px'
                  : gMDynamicSize(273, 1180, 414) + 'px',
                marginRight: isMobile
                  ? gMDynamicSize(17) + 'px'
                  : gMDynamicSize(273, 1180, 414) + 'px',
                marginBottom: gMDynamicSize(46) + 'px'
              }"
              :text="'skFAQProfileContentText'"
            />
            <H2SK
              class="sk-hover-red"
              :style="{
                marginLeft: isMobile
                  ? gMDynamicSize(17) + 'px'
                  : gMDynamicSize(164, 1180, 414) + 'px',
                marginBottom: gMDynamicSize(13) + 'px'
              }"
              :italic="true"
              :text="'skFAQFindExpertTitle'"
            />
            <P1SK
              :style="{
                marginLeft: isMobile
                  ? gMDynamicSize(17) + 'px'
                  : gMDynamicSize(273, 1180, 414) + 'px',
                marginRight: isMobile
                  ? gMDynamicSize(17) + 'px'
                  : gMDynamicSize(273, 1180, 414) + 'px',
                marginBottom: gMDynamicSize(46) + 'px'
              }"
              :text="'skFAQFindExpertText'"
            />
            <H2SK
              class="sk-hover-red"
              :style="{
                marginLeft: isMobile
                  ? gMDynamicSize(17) + 'px'
                  : gMDynamicSize(164, 1180, 414) + 'px',
                marginBottom: gMDynamicSize(13) + 'px'
              }"
              :italic="true"
              :text="'skFAQWhoTitle'"
            />
            <P1SK
              :style="{
                marginLeft: isMobile
                  ? gMDynamicSize(17) + 'px'
                  : gMDynamicSize(273, 1180, 414) + 'px',
                marginRight: isMobile
                  ? gMDynamicSize(17) + 'px'
                  : gMDynamicSize(273, 1180, 414) + 'px',
                marginBottom: gMDynamicSize(46) + 'px'
              }"
              :text="'skFAQWhoText'"
            />
            <H2SK
              class="sk-hover-red"
              :style="{
                marginLeft: isMobile
                  ? gMDynamicSize(17) + 'px'
                  : gMDynamicSize(164, 1180, 414) + 'px',
                marginBottom: gMDynamicSize(13) + 'px'
              }"
              :italic="true"
              :text="'skFAQNoAnswerTitle'"
            />
            <P1SK
              :style="{
                marginLeft: isMobile
                  ? gMDynamicSize(17) + 'px'
                  : gMDynamicSize(273, 1180, 414) + 'px',
                marginRight: isMobile
                  ? gMDynamicSize(17) + 'px'
                  : gMDynamicSize(273, 1180, 414) + 'px',
                marginBottom: gMDynamicSize(75) + 'px'
              }"
              :text="'skFAQNoAnswerText'"
            />
          </div>
          <div
            v-if="appWidth > 1230"
            :style="{
              background: 'white',
              flex: 1,
              height: gMDynamicSize(273, 1441, 1000) + 'px'
            }"
          />
        </div>
      </div>
      <ImpressumElementSK />
    </ion-content>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import RegularHeaderSK from '../components/bodies/RegularHeaderSK.vue'
import ImpressumElementSK from '../components/elements/ImpressumElementSK.vue'

export default {
  components: {
    RegularHeaderSK,
    ImpressumElementSK
  },
  computed: {
    ...mapGetters(['contentIsHidden', 'isMobile', 'appWidth', 'gMDynamicSize'])
  },
  methods: {
    goToHomePage() {
      this.$router.push({
        name: 'homePage'
      })
    }
  }
}
</script>

<style scoped>
.flex-wrap-container {
  display: flex;
  flex-wrap: wrap;
}
</style>
