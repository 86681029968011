<template>
  <div class="ion-page skViewPort">
    <ion-header v-if="!isMobile" no-border>
      <RegularHeaderSK :show-menu="true"> </RegularHeaderSK>
    </ion-header>
    <ion-content :class="contentIsHidden ? 'hide-content-sk' : 'show-content-sk'">
      <div
        :style="{
          background: 'rgba(169, 206, 162, 0.12)',
          height: appHeight - 100 - 48 + 'px'
        }"
      >
        <div
          class="flex-wrap-container"
          :style="{
            marginTop: isMobile ? 0 + 'px' : gMDynamicSize(48) + 'px'
          }"
        >
          <div
            v-if="appWidth > 1230"
            :style="{
              background: 'white',
              flex: 1,
              height: gMDynamicSize(273, 1441, 1000) + 'px'
            }"
          />

          <div
            v-if="currentProfile"
            class="flex-wrap-container"
            :style="{
              border: 'solid 15px rgb(252,185,194,0.8)',
              background: 'white',
              width: 100 + '%',
              maxWidth: 1180 + 'px'
            }"
          >
            <div
              :style="{
                width: 100 + '%',
                marginTop: gMDynamicSize(20, 375, 280) + 'px',
                marginLeft: gMDynamicSize(15, 375, 280) + 'px',
                marginRight: gMDynamicSize(15, 375, 280) + 'px'
              }"
            >
              <div
                class="flex-wrap-container"
                :style="{
                  float: 'right'
                }"
              >
                <P1SK
                  v-if="isOwnProfile"
                  @click="openEditor()"
                  :style="{
                    width: appWidth < 375 ? 80 + 'px' : 'auto',
                    marginTop: 4 + 'px',
                    cursor: 'pointer',
                    display: 'inline',
                    marginRight: gMDynamicSize(20, 375, 280) + 'px'
                  }"
                  :text="'skProfileEdit'"
                />
                <P1SK
                  v-if="isOwnProfile && !isFirstEdit"
                  @click="publishProfile()"
                  :style="{
                    width: appWidth < 375 ? 80 + 'px' : 'auto',
                    marginTop: 4 + 'px',
                    cursor: 'pointer',
                    display: 'inline',
                    marginRight: gMDynamicSize(20, 375, 280) + 'px'
                  }"
                  :text="currentProfile.profileIsPublic ? 'skProfileHide' : 'skProfilePublish'"
                />
                <div
                  :style="{
                    flex: 1
                  }"
                >
                  <img
                    :style="{
                      cursor: 'pointer',
                      display: 'inline',
                      verticalAlign: 'bottom',
                      height: 100 + '%'
                    }"
                    @click="goToHomePage()"
                    src="../assets/SKicons/closeBlue.svg"
                    :width="gMDynamicSize(30, 375, 280) + 'px'"
                    :height="gMDynamicSize(30, 375, 280) + 'px'"
                  />
                </div>
              </div>
            </div>
            <div
              class="flex-wrap-container"
              :style="{
                maxWidth: appWidth > 927 ? 312 + 'px' : 100 + '%'
              }"
            >
              <div
                class="thumbnail"
                :style="{
                  background: showDefaultImage ? '#FFF2B0' : 'white',
                  marginTop: appWidth > 927 ? gMDynamicSize(28, 375, 280) + 'px' : 15 + 'px',
                  marginLeft: appWidth > 927 ? gMDynamicSize(28, 375, 280) + 'px' : 15 + 'px',
                  marginRight: gMDynamicSize(14, 375, 280) + 'px',
                  width: isMobile ? 197 + 'px' : gMDynamicSize(256, 375, 280) + 'px',
                  height: isMobile ? 197 + 'px' : gMDynamicSize(256, 375, 280) + 'px'
                }"
              >
                <img
                  v-if="showDefaultImage"
                  class="portrait"
                  :src="imgPath"
                  :style="{
                    height: 50 + '%'
                  }"
                />
                <img v-else class="portrait" :src="imgPath" />
              </div>

              <div
                v-if="appWidth > 641"
                :style="{
                  height: 100 + '%',
                  maxHeight: appWidth > 927 ? 385 + 'px' : 'auto',
                  marginTop: 45 + 'px',
                  marginLeft: 47 + 'px',
                  marginRight: 14 + 'px'
                }"
              >
                <H2SK
                  :style="{
                    marginBottom: 31 + 'px'
                  }"
                  :text="'skProfileContact'"
                />
                <div
                  :style="{
                    marginBottom: 14 + 'px'
                  }"
                >
                  <mediaEditLinkElementSK
                    :icon="'Mail'"
                    :text="visibleEmail === null ? 'skProfileShowEmail' : visibleEmail"
                    @openCaptcha="showCaptchaModal = true"
                    :captchaCheck="true"
                    :mailIsVisible="visibleEmail === null ? false : true"
                  />
                </div>
                <div
                  v-if="currentProfile.url !== ''"
                  :style="{
                    marginBottom: 14 + 'px'
                  }"
                >
                  <mediaEditLinkElementSK :icon="'Web'" :text="currentProfile.url" />
                </div>
                <div
                  v-if="currentProfile.hasPhone || isOwnProfile"
                  :style="{
                    marginBottom: 14 + 'px'
                  }"
                >
                  <mediaEditLinkElementSK
                    :icon="'Tel'"
                    :text="visiblePhone === null ? 'skProfileShowPhone' : visiblePhone"
                    @openCaptcha="showCaptchaModal = true"
                    :captchaCheck="true"
                    :selectable="visiblePhone === null ? false : true"
                  />
                </div>
                <div
                  v-if="currentProfile.twitterLink !== ''"
                  :style="{
                    marginBottom: 14 + 'px'
                  }"
                >
                  <mediaEditLinkElementSK :icon="'Twitter'" :text="currentProfile.twitterLink" />
                </div>
                <div
                  v-if="currentProfile.instaLink !== ''"
                  :style="{
                    marginBottom: 14 + 'px'
                  }"
                >
                  <mediaEditLinkElementSK :icon="'Insta'" :text="currentProfile.instaLink" />
                </div>
                <div
                  v-if="currentProfile.linkedInLink !== ''"
                  :style="{
                    marginBottom: 14 + 'px'
                  }"
                >
                  <mediaEditLinkElementSK :icon="'Edin'" :text="currentProfile.linkedInLink" />
                </div>
                <div
                  v-if="currentProfile.fbLink !== ''"
                  :style="{
                    marginBottom: 14 + 'px'
                  }"
                >
                  <mediaEditLinkElementSK :icon="'Fb'" :text="currentProfile.fbLink" />
                </div>
                <div
                  v-if="currentProfile.xingLink !== ''"
                  :style="{
                    marginBottom: 14 + 'px'
                  }"
                >
                  <mediaEditLinkElementSK :icon="'Xing'" :text="currentProfile.xingLink" />
                </div>
                <div
                  v-if="currentProfile.pinterestLink !== ''"
                  :style="{
                    marginBottom: 14 + 'px'
                  }"
                >
                  <mediaEditLinkElementSK
                    :icon="'Pinterest'"
                    :text="currentProfile.pinterestLink"
                  />
                </div>
              </div>
            </div>
            <div
              class="flex-wrap-container"
              :style="{
                minWidth: appWidth > 641 ? 565 + 'px' : 'auto',
                flex: 1
              }"
            >
              <div
                :style="{
                  flex: 1,
                  marginLeft: isMobile ? 17 + 'px' : 14 + 'px',
                  marginRight: 14 + 'px',
                  minWidth: appWidth > 434 ? 319 + 'px' : 189 + 'px'
                }"
              >
                <div
                  :style="{
                    marginTop: appWidth > 927 ? gMDynamicSize(160, 375, 280) + 'px' : 10 + 'px'
                  }"
                >
                  <H1SK
                    :text="
                      currentProfile.title +
                      ' ' +
                      currentProfile.firstName +
                      ' ' +
                      currentProfile.lastName
                    "
                    :bold="true"
                    :selectable="true"
                  />
                </div>
                <div>
                  <H2SK :text="currentProfile.jobTitle" :selectable="true" />
                </div>
                <div>
                  <H2SK :text="currentProfile.employer" :selectable="true" />
                </div>
                <div
                  v-if="isMobile"
                  :style="{
                    marginTop: 9 + 'px'
                  }"
                >
                  <div>
                    <P2SK
                      :style="{ display: 'inline' }"
                      v-for="location in getLocations(currentProfile.locations)"
                      :key="location.nr"
                      :text="location.userLanguage"
                    />
                  </div>
                  <div>
                    <P2SK
                      :style="{ display: 'inline' }"
                      v-for="language in getLanguages(currentProfile.languagesDd)"
                      :key="language.nr"
                      :text="language.userLanguage"
                    />
                  </div>
                </div>
                <div
                  v-else
                  :style="{
                    marginTop: 27 + 'px'
                  }"
                >
                  <div>
                    <P1SK
                      :style="{ display: 'inline' }"
                      v-for="location in getLocations(currentProfile.locations)"
                      :key="location.nr"
                      :text="location.userLanguage"
                    />
                  </div>
                  <div>
                    <P1SK
                      :style="{ display: 'inline' }"
                      v-for="language in getLanguages(currentProfile.languagesDd)"
                      :key="language.nr"
                      :text="language.userLanguage"
                    />
                  </div>
                </div>
                <div
                  v-if="appWidth <= 641"
                  :style="{
                    marginTop: gMDynamicSize(40, 375, 280) + 'px',
                    marginBottom: gMDynamicSize(40, 375, 280) + 'px'
                  }"
                >
                  <H2SK
                    :style="{
                      marginBottom: gMDynamicSize(31, 375, 280) + 'px'
                    }"
                    :text="'skProfileContact'"
                  />
                  <div
                    :style="{
                      marginBottom: gMDynamicSize(14, 375, 280) + 'px'
                    }"
                  >
                    <mediaEditLinkElementSK
                      :icon="'Mail'"
                      :text="visibleEmail === null ? 'skProfileShowEmail' : visibleEmail"
                      @openCaptcha="showCaptchaModal = true"
                      :captchaCheck="true"
                      :mailIsVisible="visibleEmail === null ? false : true"
                    />
                  </div>
                  <div
                    v-if="currentProfile.url !== ''"
                    :style="{
                      marginBottom: gMDynamicSize(14, 375, 280) + 'px'
                    }"
                  >
                    <mediaEditLinkElementSK :icon="'Web'" :text="currentProfile.url" />
                  </div>
                  <div
                    v-if="currentProfile.hasPhone || isOwnProfile"
                    :style="{
                      marginBottom: gMDynamicSize(14, 375, 280) + 'px'
                    }"
                  >
                    <mediaEditLinkElementSK
                      :icon="'Tel'"
                      :text="visiblePhone === null ? 'skProfileShowPhone' : visiblePhone"
                      @openCaptcha="showCaptchaModal = true"
                      :captchaCheck="true"
                      :selectable="visiblePhone === null ? false : true"
                    />
                  </div>
                  <div
                    v-if="currentProfile.twitterLink !== ''"
                    :style="{
                      marginBottom: gMDynamicSize(14, 375, 280) + 'px'
                    }"
                  >
                    <mediaEditLinkElementSK :icon="'Twitter'" :text="currentProfile.twitterLink" />
                  </div>
                  <div
                    v-if="currentProfile.instaLink !== ''"
                    :style="{
                      marginBottom: gMDynamicSize(14, 375, 280) + 'px'
                    }"
                  >
                    <mediaEditLinkElementSK :icon="'Insta'" :text="currentProfile.instaLink" />
                  </div>
                  <div
                    v-if="currentProfile.linkedInLink !== ''"
                    :style="{
                      marginBottom: gMDynamicSize(14, 375, 280) + 'px'
                    }"
                  >
                    <mediaEditLinkElementSK :icon="'Edin'" :text="currentProfile.linkedInLink" />
                  </div>
                  <div
                    v-if="currentProfile.fbLink !== ''"
                    :style="{
                      marginBottom: gMDynamicSize(14, 375, 280) + 'px'
                    }"
                  >
                    <mediaEditLinkElementSK :icon="'Fb'" :text="currentProfile.fbLink" />
                  </div>
                  <div
                    v-if="currentProfile.xingLink !== ''"
                    :style="{
                      marginBottom: gMDynamicSize(14, 375, 280) + 'px'
                    }"
                  >
                    <mediaEditLinkElementSK :icon="'Xing'" :text="currentProfile.xingLink" />
                  </div>
                  <div
                    v-if="currentProfile.pinterestLink !== ''"
                    :style="{
                      marginBottom: gMDynamicSize(14, 375, 280) + 'px'
                    }"
                  >
                    <mediaEditLinkElementSK
                      :icon="'Pinterest'"
                      :text="currentProfile.pinterestLink"
                    />
                  </div>
                </div>
                <div
                  :style="{
                    marginTop: 39 + 'px',
                    marginBottom: isMobile ? 12 + 'px' : 23 + 'px'
                  }"
                >
                  <H2SK v-if="currentProfile.bio !== ''" :text="'skProfileCuriculumBreve'" />
                </div>
                <div
                  :style="{
                    marginBottom: isMobile ? 0 + 'px' : 62 + 'px'
                  }"
                >
                  <P1SK :text="currentProfile.bio" :selectable="true" />
                </div>
                <div>
                  <P1SK v-if="isFirstEdit" :text="'skProfileNoProfileYet'" />
                </div>
              </div>
              <div
                :style="{
                  marginLeft: appWidth > 660 ? 14 + 'px' : 17 + 'px',
                  marginRight: 14 + 'px',
                  flex: isMobile ? 1 : 0
                }"
              >
                <div
                  :style="{
                    marginTop: appWidth > 927 ? 165 + 'px' : 20 + 'px',
                    marginBottom: isMobile ? 20 + 'px' : 0 + 'px'
                  }"
                >
                  <div
                    :style="{
                      marginBottom: 5 + 'px'
                    }"
                    v-if="getCategories(currentProfile.categories).length > 0"
                  >
                    <chipElementSK
                      v-for="item in getCategories(currentProfile.categories)"
                      :key="item.nr"
                      :style="{
                        display: 'inline-flex',
                        marginBottom: isMobile ? 5 + 'px' : 20 + 'px'
                      }"
                      :text="item.userLanguage"
                      :hovorable="true"
                      @click="
                        updateSelectedCategories({
                          categoryObj: item,
                          maxSelection: 1
                        }),
                          $router.push({ name: 'homePage' })
                      "
                    />
                  </div>
                  <div
                    v-if="
                      getExpertises(currentProfile.expertise).length > 0 &&
                      getExpertises(currentProfile.expertise)[0] !== ''
                    "
                  >
                    <chipElementSK
                      v-for="(expertise, index) in getExpertises(currentProfile.expertise)"
                      :key="index"
                      :style="{
                        display: 'inline-flex',
                        marginBottom: isMobile ? 5 + 'px' : 20 + 'px',
                        cursor: 'pointer'
                      }"
                      :text="expertise"
                      :isExpertise="true"
                      @click="searchExpertise(expertise)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="appWidth > 1230"
            :style="{
              background: 'white',
              flex: 1,
              height: gMDynamicSize(273, 1441, 1000) + 'px'
            }"
          />
        </div>
      </div>
    </ion-content>
    <b-modal
      :style="{
        maxWidth: 780 + 'px',
        height: appHeight - 2 + 'px',
        margin: 'auto',
        border: '1px solid'
      }"
      full-screen
      :active.sync="showEditorModal"
      scroll="keep"
      :can-cancel="false"
    >
      <EditorModalSK :isFirstEdit="isFirstEdit" />
    </b-modal>
    <b-modal
      :style="{
        maxWidth: 600 + 'px',
        height: appWidth < 484 ? appHeight - 2 + 'px' : 655 + 'px',
        margin: 'auto',
        border: '1px solid'
      }"
      full-screen
      :active.sync="showCaptchaModal"
      scroll="keep"
      :can-cancel="false"
    >
      <CaptchaModalSK @close="showCaptchaModal = false" @captchaSolved="resolveCaptcha($event)" />
    </b-modal>
    <b-modal
      :style="{
        maxWidth: 416 + 'px',
        height: appWidth < 484 ? appHeight - 2 + 'px' : 655 + 'px',
        margin: 'auto',
        border: '1px solid'
      }"
      full-screen
      :active.sync="showPictureUploadModal"
      scroll="keep"
      :can-cancel="false"
      :has-modal-card="true"
    >
      <PictureUploadModalSK />
    </b-modal>
    <b-modal
      :style="{
        maxWidth: 718 + 'px',
        height: appHeight - 2 + 'px',
        margin: 'auto',
        border: '1px solid'
      }"
      full-screen
      :active.sync="showPrivacyPolicyModal"
      scroll="keep"
      :can-cancel="false"
      :has-modal-card="true"
    >
      <PrivacyPolicyModalSK />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { LoadingProgrammatic as Loading } from 'buefy'
import axiosCRUD from '../axios/axiosCRUD'
import RegularHeaderSK from '../components/bodies/RegularHeaderSK.vue'
import EditorModalSK from '../components/modals/EditorModalSK.vue'
import CaptchaModalSK from '../components/modals/CaptchaModalSK.vue'
import PictureUploadModalSK from '../components/modals/PictureUploadModalSK.vue'
import PrivacyPolicyModalSK from '../components/modals/PrivacyPolicyModalSK.vue'

export default {
  components: {
    RegularHeaderSK,
    EditorModalSK,
    CaptchaModalSK,
    PictureUploadModalSK,
    PrivacyPolicyModalSK
  },
  data() {
    return {
      isEmailCaptcha: false,
      isPhoneCaptcha: false,
      showCaptchaModal: false,
      visibleEmail: null,
      visiblePhone: null,
      showDefaultImage: false
    }
  },
  watch: {
    currentProfile(profile) {
      if (profile && this.isOwnProfile) {
        this.visibleEmail = profile.email
        this.visiblePhone = profile.phone
      }
    }
  },
  computed: {
    ...mapGetters([
      'currentProfile',
      'isMobile',
      'isFirstEdit',
      'userFullName',
      'showPictureUploadModal',
      'showPrivacyPolicyModal',
      'contentIsHidden',
      'appHeight',
      'appWidth',
      'bearerToken',
      'userGuid',
      'signedIn',
      'currentProfile',
      'editingItem',
      'showEditorModal',
      'getLocations',
      'getLanguages',
      'getCategories',
      'gMDynamicSize',
      'getDynamicSize'
    ]),
    imgPath() {
      let path = require('@/assets/sheknows_logo.png')

      if (this.currentProfile.picData) {
        path = `data:image/jpg;base64,${this.currentProfile.picData}`
      } else if (
        this.currentProfile &&
        this.currentProfile.pictureName &&
        this.currentProfile.pictureName !== ''
      ) {
        try {
          path = require(`@/assets/portraits/${this.currentProfile.pictureName}`)
        } catch (e) {
          console.log(this.currentProfile.pictureName, ': old picture corrupted')
        }
      }

      if (path.includes('sheknows')) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.showDefaultImage = true
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.showDefaultImage = false
      }
      return path
    },
    isOwnProfile() {
      let bool = false
      if (this.signedIn && this.currentProfile && this.currentProfile.guid === this.userGuid) {
        bool = true
      }
      return bool
    }
  },
  methods: {
    ...mapActions(['checkAuth', 'initCurrentProfile', 'updateSelectedCategories']),
    ...mapMutations([
      'resetCurrentProfile',
      'resetSelectedCategories',
      'changeSearchString',
      'resetSelectedLocations',
      'resetSelectedLanguages',
      'signIn',
      'changeShowEditorModal',
      'setCurrentProfile',
      'addExpert',
      'deleteExpert'
    ]),
    searchExpertise(expertise) {
      this.changeSearchString(expertise)
      this.resetSelectedLocations()
      this.resetSelectedLanguages()
      this.$router.push({ name: 'homePage' })
    },
    resolveCaptcha(obj) {
      this.visiblePhone = obj.phone
      this.visibleEmail = obj.email
      this.showCaptchaModal = false
    },
    goToHomePage() {
      this.$router.push({
        name: 'homePage'
      })
    },
    openEditor() {
      if (this.editingItem === null) {
        this.changeShowEditorModal(true)
      }
    },
    publishProfile() {
      const waiting = Loading.open({
        isFullPage: true,
        canCancel: false
      })
      axiosCRUD
        .put('/SheKnowsPrivate/toggleIsPublicProfile', null, {
          headers: { Authorization: this.bearerToken }
        })
        .then((response) => {
          waiting.close()

          if (response.status === 200) {
            this.setCurrentProfile({ profile: response.data })
            if (response.data.profileIsPublic) {
              this.addExpert(response.data)
            } else {
              this.deleteExpert(response.data)
            }
          }
        })
    },
    getExpertises(expertise) {
      const arr = expertise.split(',')
      return arr
    }
  }
}
</script>

<style scoped>
.flex-wrap-container {
  display: flex;
  flex-wrap: wrap;
}
.thumbnail {
  position: relative;
  overflow: hidden;
}
.thumbnail img {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: auto;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.thumbnail img.portrait {
  /* width: 100%; */
  height: auto;
}
</style>
