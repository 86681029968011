// tslint:disable: no-shadowed-variable
import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex'
import { LoadingProgrammatic as Loading } from 'buefy'
import localforage from 'localforage'
import { Istate } from '../types/userTypes'
import axiosCRUD from '../../axios/axiosCRUD'
import router from '../../router'

const state: Istate = {
  user: {},
  userLanguageDd: 1,
  editingItem: null,
  currentProfile: null,
  pictureFormFile: null,
  isFirstEdit: false,
  waiting: null
}

const actions: ActionTree<Istate, any> = {
  initCurrentProfile: ({ state, rootGetters }, toParamsUserFullName) => {
    if (toParamsUserFullName === rootGetters.userFullName) {
      const waiting = Loading.open({
        isFullPage: true,
        canCancel: false
      })
      axiosCRUD
        .get('/sheknowsprivate/mypublicprofile', {
          headers: { Authorization: rootGetters.bearerToken }
        })
        .then((response) => {
          waiting.close()

          state.currentProfile = response.data
        })
    } else {
      const waiting = Loading.open({
        isFullPage: true,
        canCancel: false
      })
      axiosCRUD.get(`/sheknows/publicprofile/${toParamsUserFullName}`).then((response) => {
        waiting.close()
        if (response.data === 'notpublic') {
          router.replace({
            name: 'homePage'
          })
        } else {
          state.currentProfile = response.data
        }
      })
    }
  },
  confirmEditProfile: ({ commit, dispatch, rootGetters }) => {
    const editingProfile = rootGetters.editingItem
    if (rootGetters.selectedCategories.length > 0) {
      const categories = `|${rootGetters.selectedCategories
        .map((obj: any) => {
          return obj.nr
        })
        .join('|')}|`
      editingProfile.categories = categories
    }

    axiosCRUD
      .put(`/SheKnowsPrivate/editprofile`, editingProfile, {
        headers: { Authorization: rootGetters.bearerToken }
      })
      .then((response: any) => {
        state.waiting.close()
        if (response.status === 200) {
          commit('changeShowEditorModal', false, { root: true })
          commit('setCurrentProfile', { profile: response.data }, { root: true })
          if (state.isFirstEdit) {
            state.isFirstEdit = false

            const newExpert: any = {}

            newExpert.title = response.data.title
            newExpert.firstName = response.data.firstName
            newExpert.lastName = response.data.lastName
            newExpert.languagesDd = response.data.languagesDd
            newExpert.jobTitle = response.data.jobTitle
            newExpert.expertise = response.data.expertise
            newExpert.categories = response.data.categories
            newExpert.locations = response.data.locations
            newExpert.bio = response.data.bio
            newExpert.hasPhone = response.data.hasPhone
            newExpert.pictureName = response.data.pictureName
            newExpert.guid = response.data.guid

            rootGetters.experts.push(newExpert)
            commit('setUserFullName', response.data.userFullName, { root: true })
            router.replace({
              name: 'profilePage',
              params: {
                userFullName: response.data.userFullName
              }
            })
          } else if (response.data.profileIsPublic) {
            const expert = rootGetters.experts.find((expert: any) => {
              return expert.guid === response.data.guid
            })
            if (expert) {
              expert.title = response.data.title
              expert.firstName = response.data.firstName
              expert.lastName = response.data.lastName
              expert.languagesDd = response.data.languagesDd
              expert.jobTitle = response.data.jobTitle
              expert.expertise = response.data.expertise
              expert.categories = response.data.categories
              expert.locations = response.data.locations
              expert.bio = response.data.bio
              expert.hasPhone = response.data.hasPhone
              expert.pictureName = response.data.pictureName
              expert.guid = response.data.guid
            }
          }
        }
      })
  },
  confirmProfileEditor: ({ commit, dispatch, rootGetters }: ActionContext<Istate, any>) => {
    state.waiting = Loading.open({
      isFullPage: true,
      canCancel: false
    })
    if (state.pictureFormFile !== null) {
      const formData = new FormData()
      formData.append('formFile', state.pictureFormFile)
      formData.append('expertGuid', state.currentProfile.guid)
      axiosCRUD
        .post('/SheKnowsPrivate/uploadPictureData', formData, {
          headers: { Authorization: rootGetters.bearerToken }
        })
        .then(() => {
          state.pictureFormFile = null
          dispatch('confirmEditProfile', null, { root: true })
        })
    } else {
      dispatch('confirmEditProfile', null, { root: true })
    }
  },
  changeLanguageWithReload: (
    { commit, dispatch, rootGetters }: ActionContext<Istate, any>,
    { newLanguageNr }: { newLanguageNr: number }
  ) => {
    // commit('setUserLanguageDd', { languageDd: newLanguageNr }, { root: true })
    localforage.setItem('userlanguagenr', newLanguageNr).then(() => {
      window.location.reload()
    })
  }
}

const mutations: MutationTree<Istate> = {
  setUser: (state, { user }: { user: any }) => {
    state.user = user
  },
  setUserLanguageDd: (state, { languageDd }: { languageDd: number }) => {
    state.userLanguageDd = languageDd
  },
  setEditingItem: (state, { editing }: { editing: any }) => {
    state.editingItem = editing
  },
  editEditingItem: (state, data) => {
    if (state.editingItem !== null) {
      state.editingItem[data.field] = data.value
    }
  },
  deleteEditingItem: (state) => {
    state.editingItem = null
  },
  setCurrentProfile: (state, { profile }: { profile: any }) => {
    state.currentProfile = profile
  },
  resetCurrentProfile: (state) => {
    state.currentProfile = null
  },
  setPictureFormFile: (state, { file }: { file: any }) => {
    state.pictureFormFile = file
  },
  deletePictureFormFile: (state) => {
    state.pictureFormFile = null
  },
  changeIsFirstEdit: (state, { bool }: { bool: boolean }) => {
    state.isFirstEdit = bool
  }
}

const getters: GetterTree<Istate, any> = {
  user: (state) => {
    return state.user
  },
  userLanguageDd: (state) => {
    return state.userLanguageDd
  },
  editingItem: (state) => {
    return state.editingItem
  },
  currentProfile: (state) => {
    return state.currentProfile
  },
  pictureFormFile: (state) => {
    return state.pictureFormFile
  },
  isFirstEdit: (state) => {
    return state.isFirstEdit
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
