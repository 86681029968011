var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-card",style:({
    height: _vm.appWidth < 484 ? 100 + '%' : 655 + 'px',
    margin: 'auto',
    flexDirection: 'column',
    display: 'flex'
  })},[_c('div',{staticClass:"modal-card-head",style:({
      paddingLeft: _vm.gMDynamicSize(33) + 'px',
      paddingRight: _vm.gMDynamicSize(33) + 'px',
      background: 'white',
      borderBottom: 'none'
    })},[_c('div',{staticClass:"media is-mobile",style:({
        width: 100 + '%',
        marginBottom: 0 + 'px'
      })},[_c('div',{staticClass:"media-content level-item"}),_c('div',{staticClass:"media-left"}),_c('div',{staticClass:"media-right",style:({
          marginLeft: 0 + 'px'
        })},[_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"src":require("./../../assets/SKicons/closeBlue.svg"),"width":_vm.gMDynamicSize(20) + 'px',"height":_vm.gMDynamicSize(20) + 'px'},on:{"click":function($event){return _vm.changeShowPictureUploadModal(false)}}})])])]),_c('div',{staticClass:"modal-card-body"},[_c('div',{staticClass:"media"},[_c('div',{staticClass:"media-content"},[_c('div',{staticClass:"content",attrs:{"id":"amasysImageCropper"}},[(_vm.selectedFile !== null && _vm.currentViewMode !== -1)?_c('vue-cropper',{key:_vm.componentKey,ref:"cropper",style:({
              height: _vm.appWidth + 'px',
              maxHeight: 414 + 'px',
              maxWidth: 414 + 'px',
              margin: 'auto'
            }),attrs:{"responsive":false,"src":_vm.imgSrc,"autoCropArea":1,"alt":"Source Image","drag-mode":"move","view-mode":_vm.currentViewMode,"movable":_vm.isMovable,"crop-box-movable":false,"crop-box-resizable":false,"modal":false,"ready":_vm.initEventListener(),"preview":".preview","zoomOnWheel":false,"zoomOnTouch":false}}):_c('div',{staticClass:"level is-mobile box",staticStyle:{"padding":"0px 0px"},style:({
              height: _vm.appWidth + 'px',
              maxHeight: 414 + 'px',
              maxWidth: 414 + 'px',
              margin: 'auto'
            })},[_c('div',{staticClass:"level-item"},[_c('input',{staticStyle:{"display":"none"},attrs:{"type":"file","name":"pictureData","id":"pictureData","accept":"image/gif, image/jpg, image/jpeg, image/png"},on:{"change":_vm.onFileSelected}}),_c('a',{staticClass:"button is-big"},[_c('label',{staticStyle:{"cursor":"pointer"},attrs:{"for":"pictureData"}},[_vm._v(_vm._s(_vm.getDixTranslation('Select Image')))])])])])],1)])]),_c('div',{staticClass:"amasys-flex-row-container",style:({
        width: 100 + '%',
        margin: 'auto'
      })},[_c('div',{staticClass:"amasys-flex-button-container"},[_c('div',{staticClass:"button is-white",staticStyle:{"background":"inherit"},on:{"click":function($event){return _vm.zoomIn(0.1)}}},[_c('b-icon',{attrs:{"pack":"fas","icon":'search-plus',"size":"is-small"}})],1)]),_c('div',{staticClass:"amasys-flex-button-container"},[_c('div',{staticClass:"button is-white",staticStyle:{"background":"inherit"},on:{"click":function($event){_vm.centerImage(), _vm.zoomOut(-0.1)}}},[_c('b-icon',{attrs:{"pack":"fas","icon":'search-minus',"size":"is-small"}})],1)]),_c('div',{staticClass:"amasys-flex-button-container"},[_c('div',{staticClass:"button is-white",staticStyle:{"background":"inherit"},on:{"click":function($event){return _vm.rotate(-90)}}},[_c('b-icon',{attrs:{"pack":"fas","icon":'undo',"size":"is-small"}})],1)]),_c('div',{staticClass:"amasys-flex-button-container"},[_c('div',{staticClass:"button is-white",staticStyle:{"background":"inherit"},on:{"click":_vm.reset}},[_vm._v("reset")])])]),_c('div',{staticClass:"level-item",style:({ marginTop: 10 + 'px' })},[(_vm.selectedFile)?_c('chipElementSK',{attrs:{"button":true,"hovorable":true,"text":'skSavePicture'},on:{"click":function($event){_vm.uploadFile(), _vm.changeShowPictureUploadModal(false)}}}):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }