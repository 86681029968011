<template>
  <div
    class="card"
    :style="{
      border: 'solid 15px rgb(252,185,194,0.8)',
      flexDirection: 'column',
      display: 'flex',
      height: appWidth < 484 ? appHeight - 20 + 'px' : 860 + 'px'
    }"
  >
    <div
      class="modal-card-head"
      :style="{
        paddingLeft: gMDynamicSize(33) + 'px',
        paddingRight: gMDynamicSize(33) + 'px',
        paddingBottom: 0 + 'px',
        background: 'white',
        borderBottom: 'none'
      }"
    >
      <div
        class="media is-mobile"
        :style="{
          width: 100 + '%',
          marginBottom: gMDynamicSize(27) + 'px'
        }"
      >
        <div class="media-content level-item">
          <P2SK :text="'skSelectModalMainOrSub'" />
        </div>
        <div class="media-right">
          <img
            style="cursor: pointer"
            @click="changeShowCategorySelectModal(false)"
            src="./../../assets/SKicons/closeBlue.svg"
            :width="gMDynamicSize(20) + 'px'"
            :height="gMDynamicSize(20) + 'px'"
          />
        </div>
      </div>
    </div>
    <div
      class="modal-card-body"
      :style="{
        padding: 0 + 'px',
        marginLeft: gMDynamicSize(33) + 'px',
        marginRight: gMDynamicSize(2) + 'px'
      }"
    >
      <CategorySelectBodySK :maxSelection="3" :isSearch="true" />
    </div>
    <div
      class="modal-card-foot"
      :style="{
        padding: '0 0 0 0px',
        background: 'white'
      }"
    >
      <div
        :style="{
          width: 100 + '%',
          textAlign: 'center',
          height: gMDynamicSize(70, 960, 960) + 'px'
        }"
      >
        <buttonElementSK
          :style="{
            display: 'inline-flex',
            height: gMDynamicSize(30, 670, 670) + 'px',
            marginTop: gMDynamicSize(14, 960, 960) + 'px'
          }"
          :disabled="selectedCategories.length === 0"
          :text="'skSelectModalShowResult'"
          @click="changeShowCategorySelectModal(false)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import CategorySelectBodySK from '../bodies/CategorySelectBodySK.vue'

export default {
  components: {
    CategorySelectBodySK
  },
  computed: {
    ...mapGetters(['appHeight', 'gMDynamicSize', 'appWidth', 'selectedCategories'])
  },
  methods: {
    ...mapMutations(['changeShowCategorySelectModal'])
  }
}
</script>
