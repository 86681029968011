import axios, { AxiosInstance } from 'axios'
import { DialogProgrammatic as Dialog } from 'buefy'
import { logOutLogIn } from '../functions/auth'
import store from '../store'
import router from '../router'

const axiosCRUD: AxiosInstance = axios.create({
  baseURL: `${process.env.VUE_APP_ORIGIN_API}/api`
})

axiosCRUD.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      if (process.env.VUE_APP_ENVIRONMENT !== 'production') {
        console.log('ok')
      }
    } else if (response.status === 201) {
      if (process.env.VUE_APP_ENVIRONMENT !== 'production') {
        console.log('created')
      }
    } else if (response.status === 204) {
      if (process.env.VUE_APP_ENVIRONMENT !== 'production') {
        console.log('noContent/deleted')
      }
    }
    return response
  },
  function (error: any): any {
    if (error.response === undefined) {
      if (store.getters.signedIn) {
        Dialog.alert({
          title: 'no connection',
          message: 'please check your internet connection',
          confirmText: 'OK'
        })
      }
      if (process.env.VUE_APP_ENVIRONMENT !== 'production') {
        console.log('error response undefined')
      }
      return error
    }
    if (error.response.status === 400) {
      if (process.env.VUE_APP_ENVIRONMENT !== 'production') {
        console.log('400 bad request')
      }
      return error
    }
    if (error.response.status === 401) {
      logOutLogIn()
      if (process.env.VUE_APP_ENVIRONMENT !== 'production') {
        console.log('401 unauthorized: logOutLogIn is running')
      }
      return error
    }
    if (error.response.status === 404) {
      Dialog.alert({
        title: 'error 404',
        message: 'requested content was not found',
        confirmText: 'OK'
      })
      router.replace({
        name: 'homePage'
      })

      if (process.env.VUE_APP_ENVIRONMENT !== 'production') {
        console.log('404 not found')
      }
      return error
    }
    if (error.response.status === 409) {
      if (process.env.VUE_APP_ENVIRONMENT !== 'production') {
        console.log('409 item has been updated in the meantime')
      }
      return error
    }
    if (error.response.status === 410) {
      Dialog.alert({
        title: 'error 410',
        message: 'requested content has been deleted in the meantime',
        confirmText: 'OK'
      })
      router.replace({
        name: 'homePage'
      })

      if (process.env.VUE_APP_ENVIRONMENT !== 'production') {
        console.log('410 item has been deleted in the meantime')
      }
      return error
    }
    if (error.response.status === 500) {
      if (process.env.VUE_APP_ENVIRONMENT !== 'production') {
        console.log('500 internal server error')
      }
      return error
    }
    return Promise.reject(error)
  }
)

export default axiosCRUD
