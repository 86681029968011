<template>
  <div
    class="card"
    :style="{
      height: 100 + '%',
      flexDirection: 'column',
      display: 'flex'
    }"
  >
    <div
      class="modal-card-head"
      :style="{
        paddingLeft: gMDynamicSize(33) + 'px',
        paddingRight: gMDynamicSize(33) + 'px',
        background: 'white',
        borderBottom: 'none'
      }"
    >
      <div
        class="media is-mobile"
        :style="{
          width: 100 + '%',
          marginBottom: 0 + 'px'
        }"
      >
        <div
          class="media-left"
          :style="{
            marginRight: 0 + 'px'
          }"
        >
          <H2SK
            :style="{
              marginBottom: gMDynamicSize(20) + 'px'
            }"
            :text="'skEditorModalCreateProfile'"
            :bold="true"
          />
          <div v-if="isFirstEdit === false">
            <P2SK
              @click="currentActiveIndex = 0"
              :style="{
                display: 'inline',
                cursor: 'pointer',
                marginRight: gMDynamicSize(14) + 'px'
              }"
              :text="'skEditorModalProfile'"
              :bold="currentActiveIndex === 0 ? true : false"
            />
            <P2SK
              @click="currentActiveIndex = 1"
              :style="{
                display: 'inline',
                cursor: 'pointer',
                marginRight: gMDynamicSize(14) + 'px'
              }"
              :text="'skEditorModalCategory'"
              :bold="currentActiveIndex === 1 ? true : false"
            />
            <P2SK
              @click="currentActiveIndex = 2"
              :style="{
                display: 'inline',
                cursor: 'pointer',
                marginRight: gMDynamicSize(14) + 'px'
              }"
              :text="'skEditorModalJob'"
              :bold="currentActiveIndex === 2 ? true : false"
            />
            <P2SK
              @click="currentActiveIndex = 3"
              :style="{
                display: 'inline',
                cursor: 'pointer',
                marginRight: gMDynamicSize(14) + 'px'
              }"
              :text="'skEditorModalCV'"
              :bold="currentActiveIndex === 3 ? true : false"
            />
            <P2SK
              @click="currentActiveIndex = 4"
              :style="{
                display: 'inline',
                cursor: 'pointer'
              }"
              :text="'skEditorModalContact'"
              :bold="currentActiveIndex === 4 ? true : false"
            />
          </div>
          <div>
            <div
              v-if="
                currentActiveIndex === 0 &&
                ($v.editingItem.firstName.$invalid || $v.editingItem.lastName.$invalid)
              "
            >
              <P2SK :style="{ color: 'red' }" :text="'skEditorModalMissingNameError'" />
            </div>
            <div v-else-if="currentActiveIndex === 0 && $v.editingItem.languagesDd.$invalid">
              <P2SK :style="{ color: 'red' }" :text="'skEditorModalMissingLanguagesDdError'" />
            </div>
            <div v-else-if="currentActiveIndex === 0 && $v.editingItem.locations.$invalid">
              <P2SK :style="{ color: 'red' }" :text="'skEditorModalMissingLocationsError'" />
            </div>
            <div
              v-else-if="currentActiveIndex === 0 && $v.editingItem.privacyPolicyAccepted.$invalid"
            >
              <P2SK :style="{ color: 'red' }" :text="'skEditorModalMissingPrivacyPolicyError'" />
            </div>
            <div v-else-if="currentActiveIndex === 1 && $v.editingItem.categories.$invalid">
              <P2SK :style="{ color: 'red' }" :text="'skEditorModalMissingCategoriesError'" />
            </div>
            <div v-else-if="currentActiveIndex === 2 && $v.editingItem.jobTitle.$invalid">
              <P2SK :style="{ color: 'red' }" :text="'skEditorModalMissingJobTitleError'" />
            </div>
            <div v-else :class="'hide-content-sk'">
              <P2SK :style="{ color: 'red' }" :text="'placeholder error message'" />
            </div>
          </div>
        </div>
        <div class="media-content level-item"></div>
        <div
          class="media-right"
          :style="{
            marginLeft: 0 + 'px'
          }"
        >
          <img
            style="cursor: pointer"
            @click="changeShowEditorModal(false)"
            src="./../../assets/SKicons/closeBlue.svg"
            :width="gMDynamicSize(20) + 'px'"
            :height="gMDynamicSize(20) + 'px'"
          />
        </div>
      </div>
    </div>
    <div
      class="modal-card-body"
      :style="{
        padding: 0 + 'px',
        marginLeft: gMDynamicSize(33) + 'px',
        marginRight: gMDynamicSize(2) + 'px'
      }"
    >
      <EditorProfileBodySK v-show="currentActiveIndex === 0" :editors="editors" />
      <div v-show="currentActiveIndex === 1">
        <P2SK
          :style="{ marginBottom: gMDynamicSize(10) + 'px' }"
          :text="'skEditorProfileDescription'"
          :bold="true"
        />
        <CategorySelectBodySK :maxSelection="3" />
      </div>
      <EditorJobBodySK v-show="currentActiveIndex === 2" :editors="editors" />
      <EditorCVBodySK v-show="currentActiveIndex === 3" :editors="editors" />
      <EditorContactBodySK v-show="currentActiveIndex === 4" :editors="editors" />
    </div>
    <div
      class="modal-card-foot"
      :style="{
        padding: '0 0 0 0px'
      }"
    >
      <div
        :style="{
          width: 100 + '%',
          textAlign: 'center',
          height: gMDynamicSize(70, 960, 960) + 'px'
        }"
      >
        <buttonElementSK
          v-if="(isFirstEdit && currentActiveIndex === 4) || isFirstEdit === false"
          :style="{
            display: 'inline-flex',
            height: gMDynamicSize(30, 670, 670) + 'px',
            marginTop: gMDynamicSize(14, 960, 960) + 'px'
          }"
          :disabled="
            $v.editingItem.languagesDd.$invalid ||
            $v.editingItem.locations.$invalid ||
            $v.editingItem.privacyPolicyAccepted.$invalid ||
            $v.editingItem.firstName.$invalid ||
            $v.editingItem.lastName.$invalid ||
            $v.editingItem.categories.$invalid ||
            $v.editingItem.jobTitle.$invalid
          "
          :text="'skEditorProfileSave'"
          @click="confirmProfileEditor()"
        />
        <buttonElementSK
          v-if="isFirstEdit && currentActiveIndex !== 4"
          :style="{
            display: 'inline-flex',
            height: gMDynamicSize(30, 670, 670) + 'px',
            marginTop: gMDynamicSize(14, 960, 960) + 'px'
          }"
          :disabled="
            ($v.editingItem.languagesDd.$invalid && currentActiveIndex === 0) ||
            ($v.editingItem.locations.$invalid && currentActiveIndex === 0) ||
            ($v.editingItem.privacyPolicyAccepted.$invalid && currentActiveIndex === 0) ||
            (($v.editingItem.firstName.$invalid || $v.editingItem.lastName.$invalid) &&
              currentActiveIndex === 0) ||
            ($v.editingItem.categories.$invalid && currentActiveIndex === 1) ||
            ($v.editingItem.jobTitle.$invalid && currentActiveIndex === 2)
          "
          :text="'skEditorProfileNext'"
          @click="currentActiveIndex += 1"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { required, minLength, maxLength, sameAs } from 'vuelidate/lib/validators'
import axiosCRUD from '../../axios/axiosCRUD'
import EditorProfileBodySK from '../bodies/EditorProfileBodySK.vue'
import CategorySelectBodySK from '../bodies/CategorySelectBodySK.vue'
import EditorJobBodySK from '../bodies/EditorJobBodySK.vue'
import EditorCVBodySK from '../bodies/EditorCVBodySK.vue'
import EditorContactBodySK from '../bodies/EditorContactBodySK.vue'

export default {
  components: {
    EditorProfileBodySK,
    CategorySelectBodySK,
    EditorJobBodySK,
    EditorCVBodySK,
    EditorContactBodySK
  },
  data() {
    return {
      currentActiveIndex: 0,
      editors: []
    }
  },
  watch: {
    selectedCategories(categories) {
      let categoriesString = ''
      if (categories.length > 0) {
        categoriesString = `|${categories
          .map((obj) => {
            return obj.nr
          })
          .join('|')}|`
      }
      const data = {}
      data.value = categoriesString
      data.field = 'categories'
      this.editEditingItem(data)
    }
  },
  validations() {
    return {
      editingItem: {
        firstName: this.getRules('firstName'),
        lastName: this.getRules('lastName'),
        jobTitle: this.getRules('jobTitle'),
        categories: {
          required
        },
        locations: {
          required
        },
        languagesDd: {
          required
        },
        privacyPolicyAccepted: {
          sameAs: sameAs(() => true)
        }
      }
    }
  },
  mounted() {
    const categories = this.getCategories(this.currentProfile.categories)
    if (categories.length > 0) {
      categories.forEach((category) => {
        this.updateSelectedCategories({
          categoryObj: category,
          maxSelection: 3
        })
      })
    }
    axiosCRUD
      .get('/sheknowsPrivate/editingprofile', {
        headers: { Authorization: this.bearerToken }
      })
      .then((editing) => {
        this.setEditingItem({ editing: editing.data })
      })
    axiosCRUD
      .get('/sheknowsPrivate/editors', {
        headers: { Authorization: this.bearerToken }
      })
      .then((editors) => {
        this.editors = editors.data
      })
  },
  beforeDestroy() {
    this.deleteEditingItem()
    this.resetSelectedCategories()
  },
  computed: {
    ...mapGetters([
      'isFirstEdit',
      'currentProfile',
      'getCategories',
      'appHeight',
      'selectedCategories',
      'bearerToken',
      'appWidth',
      'getDynamicSize',
      'gMDynamicSize',
      'editingItem'
    ])
  },
  methods: {
    ...mapActions(['updateSelectedCategories', 'confirmProfileEditor']),
    ...mapMutations([
      'resetSelectedCategories',
      'editEditingItem',
      'changeShowEditorModal',
      'setEditingItem',
      'deleteEditingItem'
    ]),
    getRules(field) {
      if (this.getEditorItem(field)) {
        return {
          required,
          minLength: minLength(this.getEditorItem(field).minValue),
          maxLength: maxLength(this.getEditorItem(field).maxValue)
        }
      }
      return {}
    },
    getEditorItem(field) {
      return this.editors.find((editorItem) => {
        return editorItem.field.toLowerCase() === field.toLowerCase()
      })
    }
  }
}
</script>
