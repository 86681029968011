var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('P2SK',{style:({ marginBottom: _vm.gMDynamicSize(10) + 'px' }),attrs:{"text":'skEditorJobBeruf',"bold":true}}),_c('P2SK',{style:({ marginBottom: _vm.gMDynamicSize(17) + 'px' }),attrs:{"text":'skEditorJobBerufsbezeichnung'}}),_c('div',{style:({
      maxWidth: 472 + 'px',
      marginBottom: _vm.gMDynamicSize(10, 414, 280) + 'px',
      marginRight: _vm.gMDynamicSize(20, 414, 280) + 'px'
    })},[_c('textInputSK',{attrs:{"editorItem":_vm.getEditorItem('jobTitle')}})],1),_c('P2SK',{style:({ marginBottom: _vm.gMDynamicSize(17) + 'px' }),attrs:{"text":'skEditorJobArbeitgeber'}}),_c('div',{style:({
      maxWidth: 472 + 'px',
      marginBottom: _vm.gMDynamicSize(10, 414, 280) + 'px',
      marginRight: _vm.gMDynamicSize(20, 414, 280) + 'px'
    })},[_c('textInputSK',{attrs:{"editorItem":_vm.getEditorItem('employer')}})],1),_c('P2SK',{style:({
      marginBottom: _vm.gMDynamicSize(17) + 'px',
      maxWidth: _vm.gMDynamicSize(520) + 'px'
    }),attrs:{"text":'skEditorJobTätigkeit'}}),_c('div',{style:({
      maxWidth: 472 + 'px',
      marginBottom: _vm.gMDynamicSize(10, 414, 280) + 'px',
      marginRight: _vm.gMDynamicSize(20, 414, 280) + 'px'
    })},[_c('textInputSK',{attrs:{"editorItem":_vm.getEditorItem('expertise')}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }