import store from '../store'

export function logOutLogIn(): any {
  store.getters.mgr
    .signoutRedirect()
    .then(function (): any {
      store.state.token = ''
      store.state.signedIn = false
      console.log('signed out')
    })
    .catch(function (err: any): any {
      console.log(err)
    })
    .then(function (): any {
      store.getters.mgr
        .signinRedirect()
        .then(() => {
          console.log('signinRedirect done')
        })
        .catch(function (err: any): any {
          console.log(err)
        })
    })
}
