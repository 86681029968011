<template>
  <div ref="container" class="dynamic-scroller-demo">
    <RecycleScroller
      v-if="currentVirtualList.length > 0"
      id="selectExpertList"
      ref="scroller"
      class="scroller"
      :emit-update="allowScrollerUpdate"
      @update="onScrollListUpdate()"
      :items="currentVirtualList"
      :item-size="isMobile ? gMDynamicSize(386) : 445"
      :buffer="buffer"
    >
      <template #before>
        <div
          v-if="isMobile"
          :style="{
            position: 'fixed',
            top: 0 + 'px',
            zIndex: 3,
            width: 100 + '%',
            background: 'white'
          }"
        >
          <img
            :style="{
              paddingTop: 15 + 'px',
              paddingLeft: 15 + 'px'
            }"
            src="./../../assets/sheknows_logo.png"
            :width="gMDynamicSize(34) + 'px'"
            :height="gMDynamicSize(23) + 'px'"
          />
          <svg
            :style="{
              float: 'right',
              paddingTop: 27 + 'px',
              paddingRight: 22 + 'px',
              paddingBottom: 8 + 'px'
            }"
            width="19"
            height="16"
            style="cursor: pointer"
            @click="changeShowMenu(true)"
          >
            <rect width="19" height="2"></rect>
            <rect y="7" width="19" height="2"></rect>
            <rect y="14" width="19" height="2"></rect>
          </svg>
        </div>
        <IntroductionSearchBodySK />
        <FilterSearchBodySK
          v-if="!isMobile"
          :style="{
            marginTop: 60 + 'px'
          }"
        />

        <div v-if="isMobile">
          <FilterSearchBodySK v-show="showScrollToTopButton" />
          <transition name="slidedown">
            <FilterSearchBodySK
              :showLanguageLocationFilter="!showScrollToTopButton"
              v-show="showFilter || !showScrollToTopButton"
              :style="{
                paddingTop: !showScrollToTopButton ? 20 + 'px' : 5 + 'px',
                position: showScrollToTopButton ? 'fixed' : 'static',
                top: gMDynamicSize(46) + 'px',
                zIndex: 1,
                background: 'white'
              }"
            />
          </transition>
        </div>
        <div class="level-item">
          <chipElementSK
            v-show="showScrollToTopButton && showFilter"
            :style="{ position: 'fixed', bottom: 10 + 'px', zIndex: 2 }"
            :button="true"
            :parentHover="true"
            :text="'skSearchScrollUp'"
            @click="
              scrollToPosition(0),
                (currentLastScrollTop = 0),
                (showScrollToTopButton = false),
                (showFilter = true)
            "
          />
        </div>
        <div
          :style="{
            background: 'rgba(169, 206, 162, 0.12)',
            height: isMobile ? gMDynamicSize(24) + 'px' : gMDynamicSize(85) + 'px'
          }"
        />
      </template>

      <template v-slot="{ item }">
        <VirtualListRowItem
          :class="contentIsHidden ? 'hide-content-sk' : 'show-content-sk'"
          :key="item.id"
          :current-virtual-list="item.list"
          :current-column-count="currentColumnCount"
          :defaultPicture="defaultPicture"
        />
      </template>
      <template #after
        ><div
          :style="{
            background: 'rgba(169, 206, 162, 0.12)',
            height: isMobile ? gMDynamicSize(24) + 'px' : gMDynamicSize(85) + 'px'
          }"
        />
      </template>
    </RecycleScroller>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { RecycleScroller } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import VirtualListRowItem from './VirtualListRowItem.vue'
import IntroductionSearchBodySK from '../bodies/IntroductionSearchBodySK.vue'

import FilterSearchBodySK from '../bodies/FilterSearchBodySK.vue'

export default {
  components: {
    RecycleScroller,
    VirtualListRowItem,
    IntroductionSearchBodySK,
    FilterSearchBodySK
  },
  props: ['currentList'],
  data() {
    return {
      currentBuffer: 1124,
      currentColumnCount: 1,
      currentVirtualList: [],
      allowScrollerUpdate: false,
      showScrollToTopButton: false,
      currentLastScrollTop: -1,
      showFilter: true,
      isScrolling: null,
      defaultPicture: ''
    }
  },
  watch: {
    appWidth() {
      if (this.currentList !== undefined) {
        this.setCurrentColumnCount()
        if (this.isMobile) {
          this.allowScrollerUpdate = true
        }
      }
    },
    currentList(list) {
      if (list && list.length > 0) {
        // this.setCurrentColumnCount()
        this.scrollToPosition(0)
        this.setVirtualColumns()
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.defaultPicture = require('@/assets/sheknows_logo.png')
    })

    if (this.isMobile) {
      // this.currentBuffer = 2 * this.gMDynamicSize(386)
      this.allowScrollerUpdate = true
    } else {
      this.$refs.container.addEventListener('wheel', this.handleScroll)
    }
    // this.scrollToPosition(0)
    // setTimeout(() => {
    //   this.scrollToPosition(this.lastScrollTop)
    // }, 100)

    this.setCurrentColumnCount()
  },
  beforeDestroy() {
    this.$refs.container.removeEventListener('wheel', this.handleScroll)
    this.changeLastScrollTop(this.$refs.scroller.$el.scrollTop)
  },
  computed: {
    ...mapGetters([
      'currentExperts',
      'isMobile',
      'selectedCategories',
      'contentIsHidden',
      'lastScrollTop',
      'appWidth',
      'appHeight',
      'gMDynamicSize',
      'buffer',
      'scrollingHasStopped'
    ])
  },
  methods: {
    ...mapMutations([
      'changeShowMenu',
      'changeLastScrollTop',
      'changeBuffer',
      'changeScrollingHasStopped'
    ]),
    handleIsScrolling() {
      if (this.isScrolling) {
        window.clearTimeout(this.isScrolling)
      }

      // Set a timeout to run after scrolling ends

      this.isScrolling = setTimeout(() => {
        // Run the callback
        this.changeScrollingHasStopped(true)
      }, 550)

      if (this.scrollingHasStopped) {
        this.changeScrollingHasStopped(false)
      }
    },
    handleScroll(event) {
      if (event) {
        this.handleIsScrolling()

        const el = this.$refs.scroller.$el

        this.currentLastScrollTop = el.scrollTop
        if (event.deltaY < 0) {
          this.showFilter = true
        } else if (event.deltaY > 0) {
          this.showFilter = false
        }
        if (this.currentLastScrollTop > this.appHeight * 2) {
          this.showScrollToTopButton = true
        } else if (this.currentLastScrollTop < this.appHeight * 2) {
          this.showScrollToTopButton = false
        }
      }
    },
    onScrollListUpdate() {
      this.handleIsScrolling()

      if (!this.$refs.scroller) {
        return
      }

      this.showScrollToTopButton = false

      const scrollDiff = this.currentLastScrollTop - this.$refs.scroller.$el.scrollTop

      if (scrollDiff === 0 || scrollDiff === -1) {
        return
      }

      this.currentLastScrollTop = this.$refs.scroller.$el.scrollTop
      if (scrollDiff > 0) {
        this.showFilter = true
      } else {
        this.showFilter = false
      }
      if (this.currentLastScrollTop > this.appHeight * 3) {
        this.showScrollToTopButton = true
      }
      // if (this.currentLastScrollTop < this.appHeight * 2) {
      //   this.showScrollToTopButton = false
      // }
    },
    setVirtualListToIndex(index) {
      if (this.$refs.scroller) {
        this.$refs.scroller.$refs.scroller.scrollToItem(index)
      }
    },
    scrollToPosition(position) {
      // if (this.$refs.scroller) {
      //   this.$refs.scroller.$refs.scroller.scrollToPosition(position)
      //   requestAnimationFrame(() => {
      //     this.$refs.scroller.$refs.scroller.scrollToPosition(position)
      //     setTimeout(() => {
      //       this.$refs.scroller.$refs.scroller.scrollToPosition(position)
      //     }, 50)
      //   })
      // }
      const ref = document.getElementById('selectExpertList')
      if (ref) {
        ref.scrollTop = position
        requestAnimationFrame(() => {
          ref.scrollTop = position
          setTimeout(() => {
            ref.scrollTop = position
          }, 50)
        })
      }
    },
    setVirtualColumns() {
      const column = this.currentList
      const objOfLists = []
      for (let j = 0; j < column.length; j += this.currentColumnCount) {
        const listArr = column.slice(j, j + this.currentColumnCount)
        objOfLists.push({ id: column[j].guid, list: listArr })
      }
      this.currentVirtualList = objOfLists

      setTimeout(() => {
        if (this.currentColumnCount === 4) {
          // this.changeBuffer(2500)
          // const buffer = 5 * 445
          const buffer = this.appHeight + 445
          this.changeBuffer(buffer)
        } else if (this.currentColumnCount === 3) {
          // this.changeBuffer(2500)
          // const buffer = 4 * 445
          const buffer = this.appHeight + 445
          this.changeBuffer(buffer)
        } else {
          this.changeBuffer(1124)
        }
      }, 50)
    },
    setCurrentColumnCount() {
      // if (this.appWidth > 4 * 404) {
      //   this.currentColumnCount = 4
      //   if (this.currentBuffer !== 4 * this.appHeight) {
      //     this.currentBuffer = 8 * this.appHeight
      //   }
      // } else
      if (this.appWidth > 4 * 404) {
        this.currentColumnCount = 4
      } else if (this.appWidth > 3 * 404) {
        this.currentColumnCount = 3
      } else if (this.appWidth > 2 * 404) {
        this.currentColumnCount = 2
      } else {
        this.currentColumnCount = 1
      }
      this.setVirtualColumns()
    }
  }
}
</script>

<style scoped>
.slidedown-enter-active {
  transition: 0.65s;
}
.slidedown-leave-active {
  transition: 0s;
}
/* .slidedown-enter-active,
.slidedown-leave-active {
  transition: transform 150ms;
} */
.slidedown-enter {
  transform: translate3d(0, -100%, 0);
}
.slidedown-leave-to {
  transform: translate3d(0, -100%, 0);
}

.dynamic-scroller-demo,
.scroller {
  height: 100%;
  width: 100%;
}

.dynamic-scroller-demo {
  overflow: hidden;
}
.before-slot-sk {
  position: 'sticky';
  z-index: 2;
}
</style>
