var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ion-page skViewPort"},[(!_vm.isMobile)?_c('ion-header',{attrs:{"no-border":""}},[_c('RegularHeaderSK',{attrs:{"show-menu":true}})],1):_vm._e(),_c('ion-content',{class:_vm.contentIsHidden ? 'hide-content-sk' : 'show-content-sk'},[_c('div',{style:({
        background: 'rgba(169, 206, 162, 0.12)',
        paddingBottom: _vm.gMDynamicSize(109) + 'px'
      })},[_c('div',{staticClass:"flex-wrap-container",style:({
          marginTop: _vm.gMDynamicSize(48) + 'px'
        })},[(_vm.appWidth > 1230)?_c('div',{style:({
            background: 'white',
            flex: 1,
            height: _vm.gMDynamicSize(273, 1441, 1000) + 'px'
          })}):_vm._e(),_c('div',{style:({
            background: 'white',
            width: 100 + '%',
            maxWidth: 1180 + 'px',
            position: 'relative'
          })},[_c('img',{style:({
              cursor: 'pointer',
              position: 'absolute',
              right: _vm.gMDynamicSize(15) + 'px'
            }),attrs:{"src":require("../assets/SKicons/closeBlue.svg"),"width":_vm.gMDynamicSize(30) + 'px',"height":_vm.gMDynamicSize(30) + 'px'},on:{"click":function($event){return _vm.goToHomePage()}}}),_c('H1SK',{style:({
              marginLeft: _vm.isMobile
                ? _vm.gMDynamicSize(17) + 'px'
                : _vm.gMDynamicSize(164, 1180, 414) + 'px',
              marginBottom: _vm.gMDynamicSize(46) + 'px'
            }),attrs:{"bold":true,"text":'skProjectIntoTitle'}}),_c('H2SK',{staticClass:"sk-hover-red",style:({
              marginLeft: _vm.isMobile
                ? _vm.gMDynamicSize(17) + 'px'
                : _vm.gMDynamicSize(164, 1180, 414) + 'px',
              marginBottom: _vm.gMDynamicSize(13) + 'px'
            }),attrs:{"italic":true,"text":'skProjectIntoWhyTitle'}}),_c('P1SK',{style:({
              marginLeft: _vm.isMobile
                ? _vm.gMDynamicSize(17) + 'px'
                : _vm.gMDynamicSize(273, 1180, 414) + 'px',
              marginRight: _vm.isMobile
                ? _vm.gMDynamicSize(17) + 'px'
                : _vm.gMDynamicSize(273, 1180, 414) + 'px',
              marginBottom: _vm.gMDynamicSize(16) + 'px'
            }),attrs:{"text":'skProjectIntoWhyTextOne'}}),_c('P1SK',{style:({
              marginLeft: _vm.isMobile
                ? _vm.gMDynamicSize(17) + 'px'
                : _vm.gMDynamicSize(273, 1180, 414) + 'px',
              marginRight: _vm.isMobile
                ? _vm.gMDynamicSize(17) + 'px'
                : _vm.gMDynamicSize(273, 1180, 414) + 'px',
              marginBottom: _vm.gMDynamicSize(16) + 'px'
            }),attrs:{"text":'skProjectIntoWhyTextTwo'}}),_c('P1SK',{style:({
              marginLeft: _vm.isMobile
                ? _vm.gMDynamicSize(17) + 'px'
                : _vm.gMDynamicSize(273, 1180, 414) + 'px',
              marginRight: _vm.isMobile
                ? _vm.gMDynamicSize(17) + 'px'
                : _vm.gMDynamicSize(273, 1180, 414) + 'px',
              marginBottom: _vm.gMDynamicSize(16) + 'px'
            }),attrs:{"text":'skProjectIntoWhyTextThree'}}),_c('P1SK',{style:({
              marginLeft: _vm.isMobile
                ? _vm.gMDynamicSize(17) + 'px'
                : _vm.gMDynamicSize(273, 1180, 414) + 'px',
              marginRight: _vm.isMobile
                ? _vm.gMDynamicSize(17) + 'px'
                : _vm.gMDynamicSize(273, 1180, 414) + 'px',
              marginBottom: _vm.gMDynamicSize(16) + 'px'
            }),attrs:{"text":'skProjectIntoWhyTextFour'}}),_c('P1SK',{style:({
              marginLeft: _vm.isMobile
                ? _vm.gMDynamicSize(17) + 'px'
                : _vm.gMDynamicSize(273, 1180, 414) + 'px',
              marginRight: _vm.isMobile
                ? _vm.gMDynamicSize(17) + 'px'
                : _vm.gMDynamicSize(273, 1180, 414) + 'px',
              marginBottom: _vm.gMDynamicSize(30) + 'px'
            }),attrs:{"text":'skProjectIntoWhyTextFive'}}),_c('div',{style:({
              marginLeft: _vm.isMobile
                ? _vm.gMDynamicSize(17) + 'px'
                : _vm.gMDynamicSize(273, 1180, 414) + 'px',
              marginRight: _vm.isMobile
                ? _vm.gMDynamicSize(17) + 'px'
                : _vm.gMDynamicSize(273, 1180, 414) + 'px',
              marginBottom: _vm.gMDynamicSize(86) + 'px'
            })},[_c('img',{style:({
                marginRight: _vm.gMDynamicSize(34) + 'px',
                marginTop: _vm.gMDynamicSize(16) + 'px'
              }),attrs:{"src":_vm.currentPostImg}}),_c('img',{style:({
                marginTop: _vm.gMDynamicSize(16) + 'px'
              }),attrs:{"src":_vm.currentMigrosImg}})]),_c('H2SK',{staticClass:"sk-hover-red",style:({
              marginLeft: _vm.isMobile ? _vm.gMDynamicSize(17) + 'px' : 164 + 'px',
              marginBottom: _vm.gMDynamicSize(13) + 'px'
            }),attrs:{"italic":true,"text":'skProjectIntoWhoTitle'}}),_c('P1SK',{style:({
              marginLeft: _vm.isMobile
                ? _vm.gMDynamicSize(17) + 'px'
                : _vm.gMDynamicSize(273, 1180, 414) + 'px',
              marginRight: _vm.isMobile
                ? _vm.gMDynamicSize(17) + 'px'
                : _vm.gMDynamicSize(273, 1180, 414) + 'px',
              marginBottom: _vm.gMDynamicSize(30) + 'px'
            }),attrs:{"text":'skProjectIntoWhoTextOne'}}),_c('div',{style:({
              marginLeft: _vm.isMobile
                ? _vm.gMDynamicSize(17) + 'px'
                : _vm.gMDynamicSize(273, 1180, 414) + 'px',
              marginRight: _vm.isMobile
                ? _vm.gMDynamicSize(17) + 'px'
                : _vm.gMDynamicSize(273, 1180, 414) + 'px',
              marginBottom: _vm.gMDynamicSize(86) + 'px'
            })},[_c('img',{attrs:{"src":require("../assets/SKpngs/allianceF_Logo.png")}})])],1),(_vm.appWidth > 1230)?_c('div',{style:({
            background: 'white',
            flex: 1,
            height: _vm.gMDynamicSize(273, 1441, 1000) + 'px'
          })}):_vm._e()])]),_c('ImpressumElementSK')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }