import Vue from 'vue'
import { IonicVueRouter } from '@ionic/vue'
import store from './store'
import HomePage from './views/HomePageSK.vue'
import ProfilePage from './views/ProfilePageSK.vue'
import ProjectInfoPage from './views/ProjectInfoPageSK.vue'
import FAQPage from './views/FAQPageSK.vue'

Vue.use(IonicVueRouter)

const router = new IonicVueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'homePage',
      component: HomePage
    },
    {
      path: '/Info',
      name: 'projectInfoPage',
      component: ProjectInfoPage
    },
    {
      path: '/FAQ',
      name: 'faqPage',
      component: FAQPage
    },
    {
      path: '/:userFullName',
      name: 'profilePage',
      component: ProfilePage
    },

    {
      path: '*',
      redirect: '/'
    }
  ]
})

router.beforeEach((to, from, next) => {
  // if (from.name === null) {
  //   if (to.name === 'profilePage') {
  //     store.dispatch('checkAuth', to.params.userFullName)
  //   } else {
  //     store.dispatch('checkAuth', null)
  //   }
  // } else if (from.name !== null && to.name === 'profilePage') {
  //   store.dispatch('initCurrentProfile', to.params.userFullName)
  // }
  next()
})
router.afterEach((to, from) => {
  if (from.name === null) {
    if (to.name === 'profilePage') {
      store.dispatch('checkAuth', to.params.userFullName)
    } else {
      store.dispatch('checkAuth', null)
    }
  } else if (from.name !== null && to.name === 'profilePage') {
    store.dispatch('initCurrentProfile', to.params.userFullName)
  }
  if (from.name === 'profilePage') {
    store.commit('resetCurrentProfile')
  }

  setTimeout(() => {
    store.commit('changeContentIsHidden', false)
  }, 1)
})

export default router
