var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{style:({
      width: 100 + '%',
      textAlign: 'center',
      minHeight: _vm.gMDynamicSize(39) + 'px'
    })},_vm._l((_vm.selectedCategories),function(item){return _c('chipElementSK',{key:item.nr,style:({
        display: 'inline-flex',
        marginBottom: _vm.gMDynamicSize(15) + 'px'
      }),attrs:{"text":item.userLanguage,"hasIcon":true},on:{"click":function($event){return _vm.updateSelectedCategories({
          categoryObj: item,
          maxSelection: _vm.maxSelection
        })}}})}),1),_c('div',{staticClass:"flex-wrap-container",style:({
      margin: 'auto',
      width: 100 + '%'
    })},[_c('div',{style:({ width: _vm.appWidth > 770 ? 100 / 3 + '%' : 50 + '%' })},_vm._l((_vm.currentMainCategories),function(item){return _c('categorySelectElementSK',{key:item.nr,attrs:{"categoryObj":item,"isMainCategory":true,"selectedMainCategory":_vm.selectedMainCategory,"maxSelection":_vm.maxSelection},on:{"mainCategorySelection":function($event){return _vm.updateSelectedMainCategory($event)}}})}),1),_c('div',{style:({ width: _vm.appWidth > 770 ? 100 / 3 + '%' : 50 + '%' })},[_vm._l((_vm.currentSubCategories.slice(0, _vm.mainCategories.length)),function(item){return _c('categorySelectElementSK',{key:item.nr,attrs:{"categoryObj":item,"maxSelection":_vm.maxSelection}})}),(_vm.appWidth < 770)?_c('div',_vm._l((_vm.currentSubCategories.slice(_vm.mainCategories.length, _vm.subCategories.length)),function(item){return _c('categorySelectElementSK',{key:item.nr,attrs:{"categoryObj":item,"maxSelection":_vm.maxSelection}})}),1):_vm._e()],2),(_vm.appWidth > 770)?_c('div',{style:({ width: 100 / 3 + '%' })},_vm._l((_vm.currentSubCategories.slice(_vm.mainCategories.length, _vm.subCategories.length)),function(item){return _c('categorySelectElementSK',{key:item.nr,attrs:{"categoryObj":item,"maxSelection":_vm.maxSelection}})}),1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }