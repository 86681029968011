<template>
  <div :style="{ maxWidth: 1118 + 'px', margin: 'auto' }">
    <h1
      :class="[isMobile ? 'sk-font-bold' : 'sk-font-regular']"
      :style="{
        marginRight: isMobile ? gMDynamicSize(17) + 'px' : 54 + 'px',
        marginLeft: isMobile ? gMDynamicSize(15) + 'px' : 54 + 'px',
        marginTop: 69 + 'px',
        marginBottom: isMobile ? gMDynamicSize(14) + 'px' : 20 + 'px',
        fontSize: isMobile ? gMDynamicSize(30) + 'px' : 60 + 'px',
        lineHeight: isMobile ? gMDynamicSize(30) + 'px' : 70 + 'px',
        textAlign: 'center',
        color: '#F95268',
        cursor: translateMode ? 'pointer' : 'default'
      }"
      @click="translateMode ? openEditTranslationModal('skIntroductionExpertPool') : ''"
    >
      {{ getDixTranslation('skIntroductionExpertPool') }}
    </h1>
    <H2SK
      :style="{
        marginRight: isMobile ? gMDynamicSize(17) + 'px' : 54 + 'px',
        marginLeft: isMobile ? gMDynamicSize(17) + 'px' : 54 + 'px',
        marginBottom: isMobile ? gMDynamicSize(9) + 'px' : 25 + 'px'
      }"
      :text="'skIntroductionTypes'"
      :text-align-center="true"
    />
    <div
      :style="{
        marginRight: isMobile ? gMDynamicSize(17) + 'px' : 90 + 'px',
        marginLeft: isMobile ? gMDynamicSize(15) + 'px' : 90 + 'px',
        textAlign: 'center',
        lineHeight: 0 + 'px'
      }"
    >
      <H2SK
        :style="{
          display: 'inline'
        }"
        :text="'skIntroductionFindOne'"
      />
      <H2SK
        class="selected-category"
        @click="signIn()"
        :style="{
          display: 'inline',
          cursor: 'pointer'
        }"
        :text="'skIntroductionFindTwo'"
      />
      <H2SK
        :style="{
          display: 'inline'
        }"
        :text="'skIntroductionFindThree'"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapGetters(['isMobile', 'translateMode', 'gMDynamicSize', 'getDixTranslation'])
  },
  methods: {
    ...mapMutations(['signIn', 'changeShowEditTranslationModal', 'setToolTextToBeTranslated']),
    openEditTranslationModal(text) {
      if (text.startsWith('sk')) {
        this.setToolTextToBeTranslated(text)
        this.changeShowEditTranslationModal(true)
      }
    }
  }
}
</script>

<style scoped>
.selected-category {
  border-bottom: 2px solid;
  border-color: #f95268;
}
</style>
