<template>
  <div
    :style="{
      display: 'flex'
    }"
  >
    <img
      :style="{
        verticalAlign: 'middle',
        height: 100 + '%',
        marginRight: editorItem ? gMDynamicSize(20) + 'px' : gMDynamicSize(10) + 'px'
      }"
      :src="currentIcon"
      :width="editorItem ? gMDynamicSize(30) + 'px' : gMDynamicSize(22.5) + 'px'"
      :height="editorItem ? gMDynamicSize(30) + 'px' : gMDynamicSize(22.5) + 'px'"
    />
    <div
      v-if="editorItem"
      :style="{
        width: gMDynamicSize(290, 414, 280) + 'px'
      }"
    >
      <textInputSK :editorItem="editorItem" />
    </div>
    <P2SK
      v-else
      :style="{
        cursor: selectable ? 'auto' : 'pointer'
      }"
      @click="openRequestedSinglePopup(text)"
      :text="currentLinkText"
      :selectable="selectable"
      :lineClamp="1"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  data() {
    return {
      windowObjectReference: null,
      previousUrl: ''
    }
  },
  props: {
    text: {
      type: String,
      default: 'text missing'
    },
    editorItem: {
      type: Object,
      default: null
    },
    icon: {
      type: String,
      default: 'Web'
    },
    selectable: {
      type: Boolean,
      default: false
    },
    captchaCheck: {
      type: Boolean,
      default: false
    },
    mailIsVisible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['gMDynamicSize', 'translateMode']),
    currentIcon() {
      if (this.icon) {
        // eslint-disable-next-line import/no-dynamic-require
        return require(`./../../assets/SKicons/link${this.icon}.svg`)
      }
    },
    currentLinkText() {
      let link = this.text
      if (this.text.includes('/')) {
        const arr = this.text.split('/')
        link = arr[arr.length - 1]
        if (link === '') {
          link = arr[arr.length - 2]
        }
      }

      return link
    }
  },
  methods: {
    ...mapMutations(['changeShowEditTranslationModal', 'setToolTextToBeTranslated']),
    openRequestedSinglePopup(url) {
      if (this.selectable === false && !this.captchaCheck) {
        window.open(url)

        //     if (this.windowObjectReference == null || this.windowObjectReference.closed) {
        //       this.windowObjectReference = window.open(
        //         url,
        //         'SingleSecondaryWindowName',
        //         'resizable,scrollbars,status'
        //       )
        //     } else if (this.previousUrl !== url) {
        //       this.windowObjectReference = window.open(
        //         url,
        //         'SingleSecondaryWindowName',
        //         'resizable=yes,scrollbars=yes,status=yes'
        //       )
        //       /* if the resource to load is different,
        //    then we load it in the already opened secondary window and then
        //    we bring such window back on top/in front of its parent window. */
        //       this.windowObjectReference.focus()
        //     } else {
        //       this.windowObjectReference.focus()
        //     }

        //     this.previousUrl = url
        //     /* explanation: we store the current url in order to compare url
        //  in the event of another call of this function. */
      } else if (this.selectable === false && this.captchaCheck) {
        if (this.translateMode) {
          if (this.text.startsWith('sk')) {
            this.setToolTextToBeTranslated(this.text)
            this.changeShowEditTranslationModal(true)
          }
        } else if (this.mailIsVisible) {
          window.open(`mailto:${url}`)
        } else {
          this.$emit('openCaptcha')
        }
      }
    }
  }
}
</script>
