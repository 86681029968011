<template>
  <div
    :style="{
      background: 'rgba(169, 206, 162, 0.12)',
      minHeight: isMobile ? gMDynamicSize(386) + 'px' : 445 + 'px'
    }"
  >
    <div
      v-if="!isMobile"
      :style="{
        display: 'flex',
        justifyContent: 'center'
      }"
    >
      <div v-for="(item, index) in currentVirtualList" :key="index">
        <SelectExpertListItem
          :current-item="item"
          :currentColumnCount="currentColumnCount"
          :defaultPicture="defaultPicture"
        />
      </div>
    </div>
    <div v-else>
      <div :style="{ height: gMDynamicSize(12) + 'px' }" />
      <div :style="{ height: gMDynamicSize(362) + 'px', overflow: 'hidden' }">
        <SelectExpertListItem
          :current-item="currentVirtualList[0]"
          :currentColumnCount="currentColumnCount"
          :defaultPicture="defaultPicture"
        />
      </div>
      <div :style="{ height: gMDynamicSize(12) + 'px' }" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SelectExpertListItem from './SelectExpertListItem.vue'

export default {
  components: {
    SelectExpertListItem
  },
  props: ['currentVirtualList', 'currentColumnCount', 'defaultPicture'],
  computed: {
    ...mapGetters(['isMobile', 'gMDynamicSize', 'appWidth'])
  }
}
</script>

<style scoped>
.flex-wrap-container {
  display: flex;
  flex-wrap: wrap;
}
</style>
