var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h1',{class:[
    { 'sk-font-bold': _vm.bold },
    { 'sk-font-regular': !_vm.bold },
    { 'sk-selectable': _vm.selectable },
    { 'sk-user-select-none': !_vm.selectable },
    { 'sk-hover-red': _vm.hover }
  ],style:({
    display: '-webkit-box',
    webkitBoxOrient: 'vertical',
    overflow: 'hidden',
    webkitLineClamp: _vm.lineClamp !== 0 ? _vm.lineClamp : 'none',
    fontSize: _vm.isMobile ? 24 + 'px' : 30 + 'px',
    fontStyle: _vm.italic ? 'italic' : 'normal',
    lineHeight: _vm.isMobile ? 26 + 'px' : 32 + 'px',
    color: _vm.inverted ? 'white' : '#1a304c',
    textAlign: _vm.textAlignCenter ? 'center' : 'left',
    transition: _vm.hover ? 'color ease-out 0.4s' : 'none',
    cursor: this.text.startsWith('sk') && _vm.translateMode ? 'pointer' : ''
  }),on:{"click":function($event){_vm.translateMode ? _vm.openEditTranslationModal() : _vm.$emit('click')}}},[_vm._v(" "+_vm._s(_vm.getDixTranslation(_vm.text))+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }