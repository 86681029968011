<template>
  <div
    class="modal-card"
    :style="{
      background: 'white',
      height: 100 + '%',
      flexDirection: 'column',
      display: 'flex'
    }"
  >
    <div
      class="modal-card-head"
      :style="{
        paddingLeft: gMDynamicSize(33) + 'px',
        paddingRight: gMDynamicSize(33) + 'px',
        background: 'white',
        borderBottom: 'none'
      }"
    >
      <div
        class="media is-mobile"
        :style="{
          width: 100 + '%',
          marginBottom: 0 + 'px'
        }"
      >
        <div
          class="media-left"
          :style="{
            marginRight: 0 + 'px'
          }"
        >
          <H2SK
            :selectable="true"
            :style="{
              marginBottom: gMDynamicSize(27) + 'px'
            }"
            :text="'skprivacyPolicyTitel'"
            :bold="true"
          />
        </div>
        <div class="media-content level-item"></div>
        <div
          class="media-right"
          :style="{
            marginLeft: 0 + 'px'
          }"
        >
          <img
            style="cursor: pointer"
            @click="changeShowPrivacyPolicyModal(false)"
            src="./../../assets/SKicons/closeBlue.svg"
            :width="gMDynamicSize(20) + 'px'"
            :height="gMDynamicSize(20) + 'px'"
          />
        </div>
      </div>
    </div>
    <div
      class="modal-card-body"
      :style="{
        padding: 0 + 'px',
        marginLeft: gMDynamicSize(33) + 'px',
        marginRight: gMDynamicSize(33) + 'px'
      }"
    >
      <P2SK
        :selectable="true"
        :style="{ marginBottom: gMDynamicSize(4) + 'px' }"
        :bold="true"
        :text="'skprivacyPolicyInfo'"
      />
      <P2SK
        :selectable="true"
        :style="{ marginBottom: gMDynamicSize(10) + 'px' }"
        :text="'skprivacyPolicyWir'"
      />
      <P2SK :selectable="true" :text="'skprivacyPolicyIstWichtig'" />

      <P2SK
        :selectable="true"
        :style="{
          marginBottom: gMDynamicSize(4) + 'px',
          marginTop: gMDynamicSize(6) + 'px'
        }"
        :bold="true"
        :text="'skprivacyPolicyAnliegenTitle'"
      />
      <P2SK :selectable="true" :text="'skprivacyPolicyAnliegenText'" />

      <P2SK
        :selectable="true"
        :style="{ marginTop: gMDynamicSize(6) + 'px' }"
        :bold="true"
        :text="'skprivacyPolicyPerson'"
      />
      <P2SK :selectable="true" :text="'skprivacyPolicyPersonTitle'" />
      <P2SK :selectable="true" :text="'skprivacyPolicyStrasse'" />
      <P2SK :selectable="true" :text="'skprivacyPolicyPLZ'" />
      <P2SK :selectable="true" :text="'skprivacyPolicyTelefon'" />
      <P2SK :selectable="true" :text="'skprivacyPolicyMail'" />

      <P2SK
        :selectable="true"
        :style="{
          marginBottom: gMDynamicSize(4) + 'px',
          marginTop: gMDynamicSize(6) + 'px'
        }"
        :text="'skprivacyPolicyErhebungTitle'"
        :bold="true"
      />
      <P2SK :selectable="true" :text="'skprivacyPolicyErhebungText'" />

      <P2SK
        :selectable="true"
        :style="{
          marginBottom: gMDynamicSize(4) + 'px',
          marginTop: gMDynamicSize(6) + 'px'
        }"
        :bold="true"
        :text="'skprivacyPolicyZweckTitle'"
      />
      <P2SK :selectable="true" :text="'skprivacyPolicyZweckText'" />

      <P2SK
        :selectable="true"
        :style="{
          marginBottom: gMDynamicSize(4) + 'px',
          marginTop: gMDynamicSize(6) + 'px'
        }"
        :bold="true"
        :text="'skprivacyPolicyDritteTitle'"
      />
      <P2SK :selectable="true" :text="'skprivacyPolicyDritteText'" />

      <P2SK
        :selectable="true"
        :style="{
          marginBottom: gMDynamicSize(4) + 'px',
          marginTop: gMDynamicSize(6) + 'px'
        }"
        :bold="true"
        :text="'skprivacyPolicyAufbewahrungTitle'"
      />
      <P2SK :selectable="true" :text="'skprivacyPolicyAufbewahrungText'" />

      <P2SK
        :selectable="true"
        :style="{
          marginBottom: gMDynamicSize(4) + 'px',
          marginTop: gMDynamicSize(6) + 'px'
        }"
        :bold="true"
        :text="'skprivacyPolicyEinwilligungTitle'"
      />
      <P2SK
        :selectable="true"
        :style="{ marginBottom: gMDynamicSize(10) + 'px' }"
        :text="'skprivacyPolicyEinwilligungText'"
      />
      <P2SK
        :style="{ marginBottom: gMDynamicSize(10) + 'px' }"
        :selectable="true"
        :text="'skprivacyPolicyRückzugText'"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  data() {
    return {
      updateKey: 1
    }
  },
  computed: {
    ...mapGetters(['appHeight', 'appWidth', 'gMDynamicSize'])
  },
  methods: {
    ...mapMutations(['changeShowPrivacyPolicyModal'])
  }
}
</script>
