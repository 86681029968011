<template>
  <div>
    <P2SK
      :style="{ marginBottom: gMDynamicSize(10) + 'px' }"
      :text="'skEditorJobBeruf'"
      :bold="true"
    />

    <P2SK
      :style="{ marginBottom: gMDynamicSize(17) + 'px' }"
      :text="'skEditorJobBerufsbezeichnung'"
    />
    <div
      :style="{
        maxWidth: 472 + 'px',
        marginBottom: gMDynamicSize(10, 414, 280) + 'px',
        marginRight: gMDynamicSize(20, 414, 280) + 'px'
      }"
    >
      <textInputSK :editorItem="getEditorItem('jobTitle')" />
    </div>
    <P2SK :style="{ marginBottom: gMDynamicSize(17) + 'px' }" :text="'skEditorJobArbeitgeber'" />
    <div
      :style="{
        maxWidth: 472 + 'px',
        marginBottom: gMDynamicSize(10, 414, 280) + 'px',
        marginRight: gMDynamicSize(20, 414, 280) + 'px'
      }"
    >
      <textInputSK :editorItem="getEditorItem('employer')" />
    </div>
    <P2SK
      :style="{
        marginBottom: gMDynamicSize(17) + 'px',
        maxWidth: gMDynamicSize(520) + 'px'
      }"
      :text="'skEditorJobTätigkeit'"
    />
    <div
      :style="{
        maxWidth: 472 + 'px',
        marginBottom: gMDynamicSize(10, 414, 280) + 'px',
        marginRight: gMDynamicSize(20, 414, 280) + 'px'
      }"
    >
      <textInputSK :editorItem="getEditorItem('expertise')" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  props: {
    editors: {
      type: Array
    }
  },
  computed: {
    ...mapGetters(['gMDynamicSize'])
  },
  methods: {
    getEditorItem(field) {
      if (this.editors) {
        return this.editors.find((editorItem) => {
          return editorItem.field.toLowerCase() === field.toLowerCase()
        })
      }
    }
  }
}
</script>
