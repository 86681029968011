<template>
  <div class="ion-page skViewPort">
    <ion-header v-if="!isMobile" no-border>
      <RegularHeaderSK :show-menu="true" :style="{ paddingBottom: gMDynamicSize(18) + 'px' }" />
    </ion-header>
    <ion-content>
      <SelectExpertList
        v-if="currentList.length > 0"
        :is-search="false"
        :currentList="currentList"
      />
    </ion-content>
    <b-modal
      :style="{
        maxWidth: 790 + 'px',
        height: appWidth < 484 ? appHeight + 'px' : 890 + 'px',
        margin: 'auto',
        outline: 'none'
      }"
      full-screen
      :active.sync="showCategorySelectModal"
      scroll="keep"
      :can-cancel="false"
    >
      <CategorySelectModalSK />
    </b-modal>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { LoadingProgrammatic as Loading } from 'buefy'
import RegularHeaderSK from '../components/bodies/RegularHeaderSK.vue'
import SelectExpertList from '../components/virtualscroller/SelectExpertList.vue'
import CategorySelectModalSK from '../components/modals/CategorySelectModalSK.vue'

export default {
  components: {
    RegularHeaderSK,
    SelectExpertList,
    CategorySelectModalSK
  },
  computed: {
    ...mapGetters([
      'buffer',
      'isMobile',
      'gMDynamicSize',
      'contentIsHidden',
      'appWidth',
      'appHeight',
      'showCategorySelectModal',
      'searchString',
      'selectedCategories',
      'experts',
      'mainCategories',
      'subCategories',
      'selectedLanguages',
      'selectedLocations',
      'filterHasPhone'
    ]),
    firstFilterList() {
      let list = this.experts

      this.changeBuffer(0)

      this.changeContentIsHidden(true)

      if (list.length > 0 && this.selectedCategories.length > 0) {
        const filtered = []
        list.forEach((expert) => {
          const categories = `|${expert.categories}|`

          const foundCategories = this.selectedCategories.filter((category) => {
            return categories.includes(`|${category.nr.toString()}|`)
          })
          if (foundCategories.length > 0) {
            filtered.push({ expert, nrOfOverlappingCategories: foundCategories.length })
          }
        })
        filtered.sort((a, b) =>
          a.nrOfOverlappingCategories < b.nrOfOverlappingCategories ? 1 : -1
        )

        list = filtered.map((item) => {
          return item.expert
        })
      }

      return list
    },
    currentList() {
      let list = this.firstFilterList
      if (list.length > 0 && this.filterHasPhone) {
        list = list.filter((expert) => {
          return expert.hasPhone === true
        })
      }

      if (
        list.length > 0 &&
        ((this.selectedLanguages.length > 0 && this.selectedLocations.length > 0) ||
          this.selectedLanguages.length > 0 ||
          this.selectedLocations.length > 0)
      ) {
        let filteredLanguages = []
        if (this.selectedLanguages.length > 0 && this.selectedLocations.length === 0) {
          filteredLanguages = list.filter((expert) => {
            return expert.languagesDd.includes(`|${this.selectedLanguages[0].nr.toString()}|`)
          })
        }

        let filteredLocations = []
        if (this.selectedLocations.length > 0 && this.selectedLanguages.length === 0) {
          filteredLocations = list.filter((expert) => {
            return expert.locations.includes(`|${this.selectedLocations[0].userLanguage}|`)
          })
        }

        let filteredLanguagesAndLocations = []
        if (this.selectedLanguages.length > 0 && this.selectedLocations.length > 0) {
          filteredLanguagesAndLocations = list.filter((expert) => {
            return (
              expert.languagesDd.includes(`|${this.selectedLanguages[0].nr.toString()}|`) &&
              expert.locations.includes(`|${this.selectedLocations[0].userLanguage}|`)
            )
          })
        }

        list = filteredLanguages.concat(filteredLocations).concat(filteredLanguagesAndLocations)
      }

      if (list.length > 0 && this.searchString.length > 0) {
        const allCategories = this.mainCategories.concat(this.subCategories)
        const possibleCategories = allCategories.filter((categorie) => {
          return categorie.userLanguage.toLowerCase().includes(this.searchString.toLowerCase())
        })
        const filteredStrings = list.filter((expert) => {
          let expertWithCategory = []
          if (possibleCategories.length > 0) {
            expertWithCategory = possibleCategories.filter((category) => {
              const categories = `|${expert.categories}|`
              return categories.includes(`|${category.nr.toString()}|`)
            })
          }
          return (
            expertWithCategory.length > 0 ||
            expert.expertise.toLowerCase().includes(this.searchString.toLowerCase()) ||
            expert.firstName.toLowerCase().includes(this.searchString.toLowerCase()) ||
            expert.lastName.toLowerCase().includes(this.searchString.toLowerCase()) ||
            expert.jobTitle.toLowerCase().includes(this.searchString.toLowerCase()) ||
            expert.title.toLowerCase().includes(this.searchString.toLowerCase())
          )
        })
        list = filteredStrings
      }
      this.setCurrentExperts(list)
      if (list.length === 0) {
        list = [{ guid: '00000000-0000-0000-0000-000000000000' }]
      }

      // eslint-disable-next-line vue/no-async-in-computed-properties
      setTimeout(() => {
        this.changeContentIsHidden(false)
      }, 1)

      return list
    }
  },
  methods: {
    ...mapMutations(['setCurrentExperts', 'changeContentIsHidden', 'changeBuffer'])
  }
}
</script>
