var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card",style:({
    border: 'solid 15px rgb(252,185,194,0.8)',
    flexDirection: 'column',
    display: 'flex',
    height: _vm.appWidth < 484 ? _vm.appHeight - 20 + 'px' : 860 + 'px'
  })},[_c('div',{staticClass:"modal-card-head",style:({
      paddingLeft: _vm.gMDynamicSize(33) + 'px',
      paddingRight: _vm.gMDynamicSize(33) + 'px',
      paddingBottom: 0 + 'px',
      background: 'white',
      borderBottom: 'none'
    })},[_c('div',{staticClass:"media is-mobile",style:({
        width: 100 + '%',
        marginBottom: _vm.gMDynamicSize(27) + 'px'
      })},[_c('div',{staticClass:"media-content level-item"},[_c('P2SK',{attrs:{"text":'skSelectModalMainOrSub'}})],1),_c('div',{staticClass:"media-right"},[_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"src":require("./../../assets/SKicons/closeBlue.svg"),"width":_vm.gMDynamicSize(20) + 'px',"height":_vm.gMDynamicSize(20) + 'px'},on:{"click":function($event){return _vm.changeShowCategorySelectModal(false)}}})])])]),_c('div',{staticClass:"modal-card-body",style:({
      padding: 0 + 'px',
      marginLeft: _vm.gMDynamicSize(33) + 'px',
      marginRight: _vm.gMDynamicSize(2) + 'px'
    })},[_c('CategorySelectBodySK',{attrs:{"maxSelection":3,"isSearch":true}})],1),_c('div',{staticClass:"modal-card-foot",style:({
      padding: '0 0 0 0px',
      background: 'white'
    })},[_c('div',{style:({
        width: 100 + '%',
        textAlign: 'center',
        height: _vm.gMDynamicSize(70, 960, 960) + 'px'
      })},[_c('buttonElementSK',{style:({
          display: 'inline-flex',
          height: _vm.gMDynamicSize(30, 670, 670) + 'px',
          marginTop: _vm.gMDynamicSize(14, 960, 960) + 'px'
        }),attrs:{"disabled":_vm.selectedCategories.length === 0,"text":'skSelectModalShowResult'},on:{"click":function($event){return _vm.changeShowCategorySelectModal(false)}}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }