<template>
  <b-button
    class="sk-button"
    @click="translateMode ? openEditTranslationModal() : $emit('click')"
    :style="{
      background: '#3E7291',
      color: 'white',
      fontSize: gMDynamicSize(16) + 'px',
      lineHeight: gMDynamicSize(16) + 'px'
    }"
    :disabled="disabled"
    >{{ getDixTranslation(text) }}</b-button
  >
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  props: {
    text: {
      type: String,
      default: 'missing'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['translateMode', 'appWidth', 'gMDynamicSize', 'getDixTranslation'])
  },
  methods: {
    ...mapMutations(['changeShowEditTranslationModal', 'setToolTextToBeTranslated']),
    openEditTranslationModal() {
      if (this.text.startsWith('sk')) {
        this.setToolTextToBeTranslated(this.text)
        this.changeShowEditTranslationModal(true)
      }
    }
  }
}
</script>
