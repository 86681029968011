// tslint:disable: no-shadowed-variable
import { GetterTree, MutationTree } from 'vuex'
import { Istate } from '../types/statesTypes'

const state: Istate = {
  maxAppWidth: 2000,
  transition: '',
  showMenu: false,
  isPhone: true,
  appHeight: -1,
  appWidth: -1,
  showCategorySelectModal: false,
  showEditorModal: false,
  showEmailFeedbackModal: false,
  showPictureUploadModal: false,
  showPrivacyPolicyModal: false,
  showEditTranslationModal: false,
  searchString: '',
  lastScrollTop: 0,
  buffer: 1335,
  contentIsHidden: false,
  translateMode: false,
  scrollingHasStopped: true
}

const mutations: MutationTree<Istate> = {
  setTransition: (state, transition: string) => {
    state.transition = transition
  },
  resetTransition: (state) => {
    state.transition = ''
  },
  changeShowMenu: (state, showMenu: boolean) => {
    state.showMenu = showMenu
  },
  changeIsPhone: (state, isPhone: boolean) => {
    state.isPhone = isPhone
  },
  changeMaxAppWidth: (state, maxWidth: number) => {
    state.maxAppWidth = maxWidth
  },
  changeAppHeight: (state, AppHeight: number) => {
    state.appHeight = AppHeight
  },
  changeAppWidth: (state, AppWidth: number) => {
    state.appWidth = AppWidth
  },
  changeShowCategorySelectModal: (state, show: boolean) => {
    state.showCategorySelectModal = show
  },
  changeShowEditorModal: (state, show: boolean) => {
    state.showEditorModal = show
  },
  changeShowEmailFeedbackModal: (state, show: boolean) => {
    state.showEmailFeedbackModal = show
  },
  changeShowPictureUploadModal: (state, show: boolean) => {
    state.showPictureUploadModal = show
  },
  changeShowPrivacyPolicyModal: (state, show: boolean) => {
    state.showPrivacyPolicyModal = show
  },
  changeShowEditTranslationModal: (state, show: boolean) => {
    state.showEditTranslationModal = show
  },
  changeSearchString: (state, search: string) => {
    state.searchString = search
  },
  changeLastScrollTop: (state, scrollTop: number) => {
    state.lastScrollTop = scrollTop
  },
  changeBuffer: (state, buffer: number) => {
    state.buffer = buffer
  },
  changeContentIsHidden: (state, bool: boolean) => {
    state.contentIsHidden = bool
  },
  toggleTranslateMode: (state) => {
    state.translateMode = !state.translateMode
  },
  changeScrollingHasStopped: (state, bool: boolean) => {
    state.scrollingHasStopped = bool
  }
}

const getters: GetterTree<Istate, any> = {
  transition() {
    return state.transition
  },
  showMenu() {
    return state.showMenu
  },
  isPhone: () => {
    return state.isPhone
  },
  maxAppWidth: () => {
    return state.maxAppWidth
  },
  appHeight: () => {
    return state.appHeight
  },
  appWidth: () => {
    return state.appWidth
  },
  isMobile: () => {
    let isMobile = false
    if (state.appWidth < 414) {
      isMobile = true
    }
    if (
      /Android|Mobi|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    ) {
      // true for mobile device
      isMobile = true
    }
    return isMobile
  },
  showCategorySelectModal: () => {
    return state.showCategorySelectModal
  },
  showEditorModal: () => {
    return state.showEditorModal
  },
  showEmailFeedbackModal: () => {
    return state.showEmailFeedbackModal
  },
  showPictureUploadModal: () => {
    return state.showPictureUploadModal
  },
  showPrivacyPolicyModal: () => {
    return state.showPrivacyPolicyModal
  },
  showEditTranslationModal: () => {
    return state.showEditTranslationModal
  },
  searchString: () => {
    return state.searchString
  },
  lastScrollTop: () => {
    return state.lastScrollTop
  },
  buffer: () => {
    return state.buffer
  },
  contentIsHidden: () => {
    return state.contentIsHidden
  },
  translateMode: () => {
    return state.translateMode
  },
  getDynamicSize: (state, rootGetters) => (size: number) => {
    const referenceWidth = 375

    let scalingWidth = 375

    if (rootGetters.appWidth < scalingWidth) {
      scalingWidth = rootGetters.appWidth
    }

    const dynamicSize = scalingWidth / (referenceWidth / size)

    return dynamicSize
  },
  gMDynamicSize: (state, rootGetters) => (size: number, scaling: number, minScaling: number) => {
    let referenceWidth = 375

    let scalingWidth = 375

    let min = 280
    if (scaling) {
      scalingWidth = scaling
      referenceWidth = scaling
    }
    if (minScaling) {
      min = minScaling
    }
    if (rootGetters.appWidth < scalingWidth) {
      scalingWidth = rootGetters.appWidth
      if (min && min > rootGetters.appWidth) {
        scalingWidth = min
      }
    }

    const dynamicSize = scalingWidth / (referenceWidth / size)

    return dynamicSize
  },
  scrollingHasStopped: () => {
    return state.scrollingHasStopped
  }
}

export default {
  state,
  mutations,
  getters
}
