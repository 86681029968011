var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    marginTop: _vm.isMobile ? 0 + 'px' : 20 + 'px',
    marginBottom: _vm.isMobile ? 0 + 'px' : 20 + 'px',
    marginLeft: _vm.isMobile ? 0 + 'px' : 20 + 'px',
    marginRight: _vm.isMobile ? 0 + 'px' : 20 + 'px',
    position: 'relative',
    background: 'white',
    overflow: 'hidden',
    height: _vm.isMobile ? 362 + 'px' : 405 + 'px',
    width: _vm.isMobile ? 100 + '%' : 344 + 'px',
    outline:
      _vm.hover && !_vm.isMobile
        ? 'solid 15px rgb(252,185,194,0.8)'
        : 'solid 15px rgba(169, 206, 162, 0)',
    transition: 'outline ease-out 0.4s'
  }),on:{"mouseover":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}},[_c('router-link',{attrs:{"to":{
      name: 'profilePage',
      params: {
        userFullName: _vm.currentItem.userFullName
      }
    }}},[(_vm.currentItem.firstName)?_c('div',{style:({
        position: 'absolute',
        left: _vm.gMDynamicSize(14) + 'px',
        top: _vm.gMDynamicSize(7) + 'px'
      })},_vm._l((_vm.getLanguages(_vm.currentItem.languagesDd)),function(language){return _c('P2SK',{key:language.nr,style:({ display: 'inline' }),attrs:{"text":language.userLanguage.slice(0, 2)}})}),1):_vm._e(),(_vm.currentItem.firstName)?_c('div',{style:({
        position: 'absolute',
        maxWidth: 90 + 'px',
        right: _vm.gMDynamicSize(14) + 'px',
        top: _vm.gMDynamicSize(7) + 'px'
      })},_vm._l((_vm.currentLocations),function(location){return _c('P2SK',{key:location.nr,style:({ display: 'inline' }),attrs:{"lineClamp":2,"text":location.userLanguage}})}),1):_vm._e(),(_vm.currentItem.firstName)?_c('div',{staticClass:"level-item",style:({
        marginBottom: 0 + 'px',
        paddingTop: _vm.gMDynamicSize(27) + 'px',
        paddingBottom: _vm.gMDynamicSize(12) + 'px'
      })},[_c('div',{staticClass:"thumbnail",style:({
          height: 50 + '%',
          background:
            _vm.picturePath.includes('sheknows') || _vm.picturePath === '' ? '#FFF2B0' : 'white',
          width: _vm.gMDynamicSize(96) + 'px',
          height: _vm.gMDynamicSize(96) + 'px'
        })},[_c('img',{staticClass:"portrait",attrs:{"src":_vm.picturePath === '' ? _vm.defaultPicture : _vm.picturePath}})])]):_vm._e(),(_vm.currentItem.firstName)?_c('div',[_c('H1SK',{style:({
          paddingRight: _vm.gMDynamicSize(14) + 'px',
          paddingLeft: _vm.gMDynamicSize(14) + 'px'
        }),attrs:{"hover":_vm.hover,"text":_vm.currentItem.title + ' ' + _vm.currentItem.firstName + ' ' + _vm.currentItem.lastName,"bold":true,"text-align-center":true,"line-clamp":2}}),_c('H2SK',{style:({
          paddingRight: _vm.gMDynamicSize(14) + 'px',
          paddingLeft: _vm.gMDynamicSize(14) + 'px',
          marginBottom: _vm.isMobile ? _vm.gMDynamicSize(13) + 'px' : _vm.gMDynamicSize(17) + 'px'
        }),attrs:{"hover":_vm.hover,"text":_vm.currentItem.jobTitle,"text-align-center":true,"line-clamp":2}}),_c('div',{style:({
          width: 100 + '%',
          textAlign: 'center',
          marginBottom: _vm.isMobile ? _vm.gMDynamicSize(23) + 'px' : _vm.gMDynamicSize(27) + 'px'
        })},_vm._l((_vm.getCategories(_vm.currentItem.categories)),function(item){return _c('chipElementSK',{key:item.nr,staticStyle:{"display":"inline"},style:({
            display: 'inline-flex',
            marginBottom: _vm.gMDynamicSize(7) + 'px'
          }),attrs:{"text":item.userLanguage,"hovorable":true},on:{"click":function($event){return _vm.updateSelectedCategories({
              categoryObj: item,
              maxSelection: 3
            })}}})}),1),_c('div',{class:_vm.isMobile ? 'textGradientMobileSK' : 'textGradientSK'},[_c('P1SK',{style:({
            paddingRight: _vm.gMDynamicSize(14) + 'px',
            paddingLeft: _vm.gMDynamicSize(14) + 'px'
          }),attrs:{"text":_vm.currentItem.bio}})],1)],1):_c('div',[_c('P2SK',{style:({
          marginTop: 50 + 'px',
          paddingRight: _vm.gMDynamicSize(30) + 'px',
          paddingLeft: _vm.gMDynamicSize(30) + 'px',
          marginBottom: _vm.gMDynamicSize(25) + 'px'
        }),attrs:{"text":'skSearchNoProfileFound',"bold":true,"text-align-center":true,"line-clamp":2}}),_c('P2SK',{style:({
          paddingRight: _vm.gMDynamicSize(30) + 'px',
          paddingLeft: _vm.gMDynamicSize(30) + 'px',
          marginBottom: _vm.gMDynamicSize(25) + 'px'
        }),attrs:{"text":'skSearchYouFillTheGap',"bold":true,"text-align-center":true,"line-clamp":2}})],1),(_vm.currentItem.firstName)?_c('div',{staticClass:"level-item"},[_c('chipElementSK',{style:({
          position: 'fixed',
          bottom: _vm.isMobile ? _vm.gMDynamicSize(25) + 'px' : _vm.gMDynamicSize(45) + 'px',
          zIndex: 2
        }),attrs:{"button":true,"parentHover":_vm.hover,"text":'skSearchCVButtonText'}})],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }