<template>
  <div>
    <div
      :style="{
        width: 100 + '%',
        textAlign: 'center',
        minHeight: gMDynamicSize(39) + 'px'
      }"
    >
      <chipElementSK
        :style="{
          display: 'inline-flex',
          marginBottom: gMDynamicSize(15) + 'px'
        }"
        v-for="item in selectedCategories"
        :key="item.nr"
        @click="
          updateSelectedCategories({
            categoryObj: item,
            maxSelection: maxSelection
          })
        "
        :text="item.userLanguage"
        :hasIcon="true"
      />
    </div>
    <div
      class="flex-wrap-container"
      :style="{
        margin: 'auto',
        width: 100 + '%'
      }"
    >
      <div :style="{ width: appWidth > 770 ? 100 / 3 + '%' : 50 + '%' }">
        <categorySelectElementSK
          v-for="item in currentMainCategories"
          :key="item.nr"
          :categoryObj="item"
          :isMainCategory="true"
          :selectedMainCategory="selectedMainCategory"
          @mainCategorySelection="updateSelectedMainCategory($event)"
          :maxSelection="maxSelection"
        />
      </div>
      <div :style="{ width: appWidth > 770 ? 100 / 3 + '%' : 50 + '%' }">
        <categorySelectElementSK
          v-for="item in currentSubCategories.slice(0, mainCategories.length)"
          :key="item.nr"
          :categoryObj="item"
          :maxSelection="maxSelection"
        />
        <div v-if="appWidth < 770">
          <categorySelectElementSK
            v-for="item in currentSubCategories.slice(mainCategories.length, subCategories.length)"
            :key="item.nr"
            :categoryObj="item"
            :maxSelection="maxSelection"
          />
        </div>
      </div>
      <div v-if="appWidth > 770" :style="{ width: 100 / 3 + '%' }">
        <categorySelectElementSK
          v-for="item in currentSubCategories.slice(mainCategories.length, subCategories.length)"
          :key="item.nr"
          :categoryObj="item"
          :maxSelection="maxSelection"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    maxSelection: {
      type: Number,
      default: 3
    },
    isSearch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedMainCategory: -1
    }
  },
  mounted() {
    if (this.isSearch) {
      this.selectedMainCategory = this.currentAvailableMainCategories[0].nr
    } else {
      this.selectedMainCategory = this.mainCategories[0].nr
    }
  },
  computed: {
    ...mapGetters([
      'getMainCategories',
      'getSubCategories',
      'experts',
      'appWidth',
      'gMDynamicSize',
      'getDynamicSize',
      'mainCategories',
      'subCategories',
      'selectedCategories'
    ]),
    currentAvailableSubCategories() {
      let available = []
      if (this.subCategories.length > 0 && this.experts.length > 0) {
        const allSubCategorieNrs = []
        this.experts.forEach((expert) => {
          this.getSubCategories(expert.categories).forEach((category) => {
            allSubCategorieNrs.push(category.nr)
          })
        })
        const uniqueSubCategorieNrs = [...new Set(allSubCategorieNrs)]

        const availableSubCategories = this.subCategories.filter((category) => {
          return uniqueSubCategorieNrs.includes(category.nr)
        })

        available = availableSubCategories
      }
      return available
    },
    currentAvailableMainCategories() {
      let available = []
      if (this.mainCategories.length > 0 && this.experts.length > 0) {
        const allMainCategorieNrs = []
        this.experts.forEach((expert) => {
          this.getMainCategories(expert.categories).forEach((category) => {
            allMainCategorieNrs.push(category.nr)
          })
        })
        const uniqueMainCategorieNrs = [...new Set(allMainCategorieNrs)]

        const availableMainCategories = this.mainCategories.filter((category) => {
          return uniqueMainCategorieNrs.includes(category.nr)
        })

        available = availableMainCategories
      }
      return available
    },
    currentSubCategories() {
      let subCategories = []
      if (this.isSearch && this.currentAvailableSubCategories.length > 0) {
        subCategories = this.currentAvailableSubCategories.filter((obj) => {
          return Number(obj.mapping) === this.selectedMainCategory
        })
      } else if (this.subCategories.length > 0) {
        subCategories = this.subCategories.filter((obj) => {
          return Number(obj.mapping) === this.selectedMainCategory
        })
      }
      return subCategories
    },
    currentMainCategories() {
      let mainCategories = []
      if (this.isSearch && this.currentAvailableMainCategories.length > 0) {
        mainCategories = this.currentAvailableMainCategories
      } else if (this.mainCategories.length > 0) {
        mainCategories = this.mainCategories
      }
      return mainCategories
    }
  },
  methods: {
    ...mapActions(['updateSelectedCategories']),
    updateSelectedMainCategory(categoryObj) {
      this.selectedMainCategory = categoryObj.nr
    }
  }
}
</script>

<style scoped>
.flex-wrap-container {
  display: flex;
  flex-wrap: wrap;
}
</style>
