<template>
  <div
    :style="{
      whiteSpace: 'nowrap'
    }"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <div
      v-if="dropdown"
      class="chip"
      @click="!translateMode ? $emit('click') : ''"
      :style="{
        cursor: 'pointer',
        display: 'table',
        paddingTop: gMDynamicSize(7) + 'px',
        paddingBottom: gMDynamicSize(9) + 'px',
        paddingLeft: gMDynamicSize(22) + 'px',
        paddingRight: gMDynamicSize(22) + 'px',
        backgroundColor: '#efefef'
      }"
    >
      <P2SK
        :style="{
          display: 'inline',
          marginRight: gMDynamicSize(10) + 'px'
        }"
        :text="text"
      />
      <div :style="{ display: 'inline' }">
        <iconSK
          v-if="hasIcon"
          :style="{ cursor: 'pointer' }"
          :icon="'dropdown'"
          :width="10"
          :height="10"
        />
      </div>
    </div>
    <div
      v-else-if="button"
      class="chip"
      @click="!translateMode ? $emit('click') : ''"
      :style="{
        cursor: 'pointer',
        display: 'table',
        paddingTop: gMDynamicSize(7) + 'px',
        paddingBottom: gMDynamicSize(9) + 'px',
        paddingLeft: gMDynamicSize(22) + 'px',
        paddingRight: gMDynamicSize(22) + 'px',
        backgroundColor: '#D8DCE1',
        backgroundColor: parentHover && !translateMode ? '#f95268 ' : '#c7cfd9',
        transition: parentHover && !translateMode ? 'background ease-out 0.9s' : 'none',
        cursor: parentHover && !translateMode ? 'pointer' : 'auto'
      }"
    >
      <P1SK
        :bold="true"
        :style="{
          display: 'inline',
          marginRight: gMDynamicSize(2) + 'px'
        }"
        :text="text"
        :inverted="true"
      />
    </div>
    <div
      v-else-if="isExpertise"
      class="chip"
      @click="!translateMode ? $emit('click') : ''"
      :style="{
        display: 'table',
        paddingTop: 0 + 'px',
        paddingBottom: 0 + 'px',

        paddingLeft: gMDynamicSize(11) + 'px',
        paddingRight: gMDynamicSize(11) + 'px',
        marginRight: getDynamicSize(2.5) + 'px',
        marginLeft: getDynamicSize(2.5) + 'px',
        border: '0.5px solid #3E7291',
        backgroundColor: 'white'
      }"
    >
      <div
        :style="{
          display: 'table-cell'
        }"
      >
        <P3SK
          :style="{
            maxWidth: 145 + 'px',
            whiteSpace: 'normal' + '!important'
          }"
          :text="text"
        />
      </div>
    </div>
    <div
      v-else
      class="chip"
      @click.prevent.stop="
        hover && hovorable && !translateMode && !translateMode ? $emit('click') : ''
      "
      :style="{
        display: 'table',
        paddingTop: 0 + 'px',
        paddingBottom: 0 + 'px',
        paddingLeft: gMDynamicSize(11) + 'px',
        paddingRight: gMDynamicSize(11) + 'px',
        marginRight: gMDynamicSize(2.5) + 'px',
        marginLeft: gMDynamicSize(2.5) + 'px',
        backgroundColor:
          (hover && hovorable && !translateMode) || (isSelectedCategory && hovorable)
            ? '#f95268'
            : '#EFEFEF',
        transition: hover && hovorable && !translateMode ? 'background ease-out 0.9s' : 'none',
        cursor: hover && hovorable && !translateMode ? 'pointer' : 'auto'
      }"
    >
      <div
        :style="{
          display: 'table-row'
        }"
      >
        <P2SK
          :style="{
            display: 'table-cell',
            lineHeight: 25 + 'px'
          }"
          :text="text"
          :inverted="(hover && hovorable && !translateMode) || (isSelectedCategory && hovorable)"
        />
        <div
          :style="{
            display: 'table-cell'
          }"
        >
          <iconSK
            v-if="hasIcon"
            @click="!translateMode ? $emit('click') : ''"
            :style="{
              cursor: 'pointer',
              height: 25 + 'px',
              marginLeft: gMDynamicSize(5) + 'px'
            }"
            :icon="'closeBlue'"
            :width="12"
            :height="12"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      hover: false
    }
  },
  props: {
    text: {
      type: String,
      default: ''
    },
    button: {
      type: Boolean,
      default: false
    },
    dropdown: {
      type: Boolean,
      default: false
    },
    isExpertise: {
      type: Boolean,
      default: false
    },
    hasIcon: {
      type: Boolean,
      default: false
    },
    hovorable: {
      type: Boolean,
      default: false
    },
    parentHover: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['translateMode', 'getDynamicSize', 'gMDynamicSize', 'selectedCategories']),
    currentSelectedCategory() {
      let label = ''
      if (this.selectedCategories.length > 0) {
        label = this.selectedCategories[0].userLanguage
      }
      return label
    },
    isSelectedCategory() {
      let bool = false
      if (this.selectedCategories.length > 0) {
        this.selectedCategories.forEach((category) => {
          if (category.userLanguage === this.text) {
            bool = true
          }
        })
      }
      return bool
    }
  }
}
</script>

<style scoped>
.chip {
  text-align: left;
  text-decoration: none;
  border-radius: 20px;
}
</style>
