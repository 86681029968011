var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.editorItem && _vm.editingItem)?_c('div',{staticClass:"ion-page shsViewPort"},[_c('ion-content',{attrs:{"fullscreen":""}},[(_vm.$route.params)?_c('headerSHS',{staticClass:"shs-header",attrs:{"show-back":_vm.currentIndex !== 1 && this.$route.params.editingItemIsInvalid === 'true',"show-cancel":this.$route.params.editingItemIsInvalid === 'false'},on:{"backClick":function($event){_vm.setTransition('amasysGoBack'), _vm.$router.go(-1)}}}):_vm._e(),_c('textContainerSHS',[(
          _vm.$route.params.editorName !== 'authorNameAdd' &&
          _vm.$route.params.editorName !== 'authorNameEdit'
        )?_c('titleSHS',{attrs:{"text":this.$route.params.editingItemIsInvalid === 'true'
            ? _vm.getDixTranslation('editorTitleOnboarding') +
              ' ' +
              _vm.currentIndex +
              '/' +
              _vm.editorList.length
            : _vm.editorItem.toolText}}):_c('titleSHS',{attrs:{"text":_vm.getDropdownUL('sourceDD', _vm.editingItem.sourceDd)}}),_c('textSHS',{attrs:{"text":_vm.editorItem.info}})],1),(
        _vm.$route.params.editorName === 'authorNameAdd' ||
        _vm.$route.params.editorName === 'authorNameEdit'
      )?_c('textContainerSHS',[_c('textSHS',{attrs:{"text":'AuthorNamesHelpText' + _vm.editingItem.sourceDd}})],1):_vm._e(),_c('linkContainerSHS',[_c('div',{style:({ marginBottom: _vm.appWidth / (375 / 30) + 'px' })},[_c('v-text-field',{ref:"input",style:({
            fontSize: _vm.appWidth / (375 / 22) + 'px',
            lineHeight: _vm.appWidth / (375 / 27) + 'px'
          }),attrs:{"solo":"","flat":"","height":(_vm.appWidth - 45) / 5.7 + 'px',"background-color":"rgba(196, 196, 196, 0.15)","error-messages":_vm.debounce ? _vm.currentErrors : [],"placeholder":_vm.getDixTranslation(_vm.editorItem.toolText)},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.emitEditing()}},model:{value:(_vm.editing === null ? _vm.currentValue : _vm.editing),callback:function ($$v) {_vm.editing === null ? _vm.currentValue : _vm.editing=$$v},expression:"editing === null ? currentValue : editing"}})],1)]),_c('buttonContainerSHS',{style:({
        paddingTop: _vm.appWidth / (375 / 25.5) + 'px',
        paddingBottom: _vm.appWidth / (375 / 25.5) + 'px'
      })},[_c('buttonSHS',{attrs:{"disabled":_vm.$v.editing.$invalid,"text":this.$route.params.editingItemIsInvalid === 'true' ? 'buttonNext' : 'buttonOk'},on:{"click":function($event){return _vm.emitEditing()}}})],1),(
        _vm.$route.params.editorName == 'firstLogin' ||
        _vm.$route.params.editorName == 'assessmentSettings'
      )?_c('linkContainerSHS',[_c('linkSHS',{attrs:{"text":'editorLinkGeneralExplanation'},on:{"click":function($event){_vm.setTransition('amasysForward'),
            _vm.$router.push({
              name: 'faqOverlay',
              params: {
                toolText: 'editorLinkGeneralExplanation'
              }
            })}}}),_c('linkSHS',{attrs:{"text":'editorLinkDataUsage'},on:{"click":function($event){_vm.setTransition('amasysForward'),
            _vm.$router.push({
              name: 'faqOverlay',
              params: {
                toolText: 'editorLinkDataUsage'
              }
            })}}})],1):_vm._e(),(_vm.$route.params.editorName == 'mainInfoAdd')?_c('linkContainerSHS',[_c('linkSHS',{attrs:{"text":'mainInfoAddCancelLink'},on:{"click":function($event){_vm.setTransition('amasysGoBack'),
            _vm.$router.push({
              name: 'dashboardCounterspeech'
            })}}})],1):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }