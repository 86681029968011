// tslint:disable: no-shadowed-variable
import mgr from 'oidc-client'
import oidc from 'oidc-client'
import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'
import { LoadingProgrammatic as Loading } from 'buefy'
import { Istate } from '../types/authTypes'
import { logOutLogIn } from '../../functions/auth'
import axiosCRUD from '../../axios/axiosCRUD'

const state: Istate = {
  token: '',
  signedIn: false,
  userGuid: '',
  userRoles: '',
  userFullName: '',
  mgr: new mgr.UserManager({
    userStore: new mgr.WebStorageStateStore({}),
    authority: process.env.VUE_APP_ORIGIN_IDSERVER,
    client_id: 'pwa',
    redirect_uri: `${process.env.VUE_APP_ORIGIN_PWA}/static/callback.html`,
    response_type: 'id_token token',
    scope: 'openid sheknowsapi',
    post_logout_redirect_uri: `${process.env.VUE_APP_ORIGIN_PWA}/index.html`, // '/static/index.html'
    silent_redirect_uri: `${process.env.VUE_APP_ORIGIN_PWA}/static/silent.html`,
    automaticSilentRenew: true,
    revokeAccessTokenOnSignout: true,
    filterProtocolClaims: false
  }),
  waiting: null
}

const actions: ActionTree<Istate, any> = {
  async checkAuth(
    { state, dispatch, commit, rootGetters }: ActionContext<Istate, any>,
    toParamsUserFullName
  ): Promise<any> {
    await state.mgr
      .getUser()
      .then((user: any) => {
        if (user) {
          state.token = user.access_token
          state.signedIn = true
          axiosCRUD
            .get('/sheknowsprivate/myidentity', {
              headers: { Authorization: rootGetters.bearerToken }
            })
            .then((response) => {
              state.userGuid = response.data.guid
              state.userRoles = response.data.roles
              state.userFullName = response.data.userFullName

              if (state.userFullName === '') {
                commit('changeIsFirstEdit', { bool: true }, { root: true })
                state.userFullName = 'newExpert'
              }

              if (toParamsUserFullName) {
                dispatch('initCurrentProfile', toParamsUserFullName, { root: true })
              }
            })
        } else if (toParamsUserFullName) {
          dispatch('initCurrentProfile', toParamsUserFullName, { root: true })
        }
      })
      .catch((err: any) => {
        console.log('checkAuth error!!!', err)
      })
  }
}

if (process.env.NODE_ENV !== 'production') {
  oidc.Log.logger = console
  oidc.Log.level = oidc.Log.DEBUG

  state.mgr.events.addUserLoaded(function (): void {
    console.log('user loaded')
  })
  state.mgr.events.addUserUnloaded(function (): void {
    console.log('user unloaded')
  })
  state.mgr.events.addAccessTokenExpiring(function (): void {
    console.log('token expiring...')
  })

  state.mgr.events.addAccessTokenExpired(function (): void {
    console.log('token expired')
    logOutLogIn()
  })
  state.mgr.events.addSilentRenewError(function (): void {
    console.log('silent renew error')
    logOutLogIn()
  })
  state.mgr.events.addUserSignedOut(function (): void {
    console.log('user signed out')
  })
} else {
  state.mgr.events.addAccessTokenExpired(function (): void {
    logOutLogIn()
  })
  state.mgr.events.addSilentRenewError(function (): void {
    logOutLogIn()
  })
}

const mutations: MutationTree<Istate> = {
  signIn(): void {
    if (state.signedIn === false) {
      state.waiting = Loading.open({
        isFullPage: true,
        canCancel: false
      })
      state.mgr
        .signinRedirect()
        .then(() => {
          state.waiting.close()
          console.log('signinRedirect done')
        })
        .catch((err: any) => {
          console.log(err)
        })
    }
  },
  signOut(): void {
    state.mgr
      .signoutRedirect()
      .then((resp: any) => {
        state.token = ''
        state.signedIn = false
        console.log('signed out', resp)
      })
      .catch((err: any) => {
        console.log(err)
      })
  },
  setUserGuid: (state, { guid }: { guid: string }) => {
    state.userGuid = guid
  },
  setUserFullName: (state, name: string) => {
    state.userFullName = name
  }
}

const getters: GetterTree<Istate, any> = {
  bearerToken(): string {
    return 'Bearer '.concat(state.token)
  },
  signedIn: (state) => {
    return state.signedIn
  },
  mgr: (state) => {
    return state.mgr
  },
  token: (state) => {
    return state.token
  },
  userGuid: () => {
    return state.userGuid
  },
  userFullName: () => {
    return state.userFullName
  },
  userRoles: () => {
    return state.userRoles
  },
  canTranslate: (state, rootGetters) => {
    let can = false
    if (rootGetters.userRoles && rootGetters.userRoles.toLowerCase().indexOf('translator') !== -1) {
      can = true
    }
    return can
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
