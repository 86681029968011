var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    background: '#1A304C'
  })},[_c('div',{staticClass:"level-item",style:({ paddingTop: _vm.gMDynamicSize(53) + 'px', marginBottom: _vm.gMDynamicSize(22) + 'px' })},[_c('img',{style:({
        cursor: 'pointer',
        marginRight: _vm.gMDynamicSize(27) + 'px'
      }),attrs:{"src":_vm.getIconPath('fb'),"width":_vm.gMDynamicSize(20) + 'px',"height":_vm.gMDynamicSize(20) + 'px'},on:{"click":function($event){return _vm.openUrl('https://www.facebook.com/allianceFCH/')}}}),_c('img',{style:({
        cursor: 'pointer',
        marginRight: _vm.gMDynamicSize(27) + 'px'
      }),attrs:{"src":_vm.getIconPath('insta'),"width":_vm.gMDynamicSize(20) + 'px',"height":_vm.gMDynamicSize(20) + 'px'},on:{"click":function($event){return _vm.openUrl('https://www.instagram.com/alliancef.ch/')}}}),_c('img',{style:({
        cursor: 'pointer',
        marginRight: _vm.gMDynamicSize(27) + 'px'
      }),attrs:{"src":_vm.getIconPath('twitter'),"width":_vm.gMDynamicSize(20) + 'px',"height":_vm.gMDynamicSize(20) + 'px'},on:{"click":function($event){return _vm.openUrl('https://twitter.com/alliance_F')}}}),_c('img',{style:({
        cursor: 'pointer',
        marginRight: _vm.gMDynamicSize(27) + 'px'
      }),attrs:{"src":_vm.getIconPath('linkedin'),"width":_vm.gMDynamicSize(20) + 'px',"height":_vm.gMDynamicSize(20) + 'px'},on:{"click":function($event){return _vm.openUrl('https://www.linkedin.com/company/alliance-f/')}}})]),_c('div',{staticClass:"level-item",style:({
      marginLeft: _vm.gMDynamicSize(17) + 'px',
      marginRight: _vm.gMDynamicSize(17) + 'px',
      paddingBottom: _vm.gMDynamicSize(26) + 'px'
    })},[_c('P1SK',{attrs:{"textAlignCenter":true,"text":'skImpressumText',"inverted":true}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }