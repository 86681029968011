<template>
  <div
    @mouseover="hover = true"
    @mouseleave="hover = false"
    :style="{
      marginTop: isMobile ? 0 + 'px' : 20 + 'px',
      marginBottom: isMobile ? 0 + 'px' : 20 + 'px',
      marginLeft: isMobile ? 0 + 'px' : 20 + 'px',
      marginRight: isMobile ? 0 + 'px' : 20 + 'px',
      position: 'relative',
      background: 'white',
      overflow: 'hidden',
      height: isMobile ? 362 + 'px' : 405 + 'px',
      width: isMobile ? 100 + '%' : 344 + 'px',
      outline:
        hover && !isMobile
          ? 'solid 15px rgb(252,185,194,0.8)'
          : 'solid 15px rgba(169, 206, 162, 0)',
      transition: 'outline ease-out 0.4s'
    }"
  >
    <router-link
      :to="{
        name: 'profilePage',
        params: {
          userFullName: currentItem.userFullName
        }
      }"
    >
      <div
        v-if="currentItem.firstName"
        :style="{
          position: 'absolute',
          left: gMDynamicSize(14) + 'px',
          top: gMDynamicSize(7) + 'px'
        }"
      >
        <P2SK
          :style="{ display: 'inline' }"
          v-for="language in getLanguages(currentItem.languagesDd)"
          :key="language.nr"
          :text="language.userLanguage.slice(0, 2)"
        />
      </div>
      <div
        v-if="currentItem.firstName"
        :style="{
          position: 'absolute',
          maxWidth: 90 + 'px',
          right: gMDynamicSize(14) + 'px',
          top: gMDynamicSize(7) + 'px'
        }"
      >
        <P2SK
          :style="{ display: 'inline' }"
          v-for="location in currentLocations"
          :lineClamp="2"
          :key="location.nr"
          :text="location.userLanguage"
        />
      </div>
      <div
        v-if="currentItem.firstName"
        class="level-item"
        :style="{
          marginBottom: 0 + 'px',
          paddingTop: gMDynamicSize(27) + 'px',
          paddingBottom: gMDynamicSize(12) + 'px'
        }"
      >
        <div
          class="thumbnail"
          :style="{
            height: 50 + '%',
            background:
              picturePath.includes('sheknows') || picturePath === '' ? '#FFF2B0' : 'white',
            width: gMDynamicSize(96) + 'px',
            height: gMDynamicSize(96) + 'px'
          }"
        >
          <!-- <img
            v-if="picturePath.includes('sheknows')"
            class="portrait"
            :src="defaultPicture"
            :style="{
              height: 50 + '%'
            }"
          /> -->
          <img class="portrait" :src="picturePath === '' ? defaultPicture : picturePath" />
        </div>
      </div>
      <div v-if="currentItem.firstName">
        <H1SK
          :style="{
            paddingRight: gMDynamicSize(14) + 'px',
            paddingLeft: gMDynamicSize(14) + 'px'
          }"
          :hover="hover"
          :text="currentItem.title + ' ' + currentItem.firstName + ' ' + currentItem.lastName"
          :bold="true"
          :text-align-center="true"
          :line-clamp="2"
        />
        <H2SK
          :style="{
            paddingRight: gMDynamicSize(14) + 'px',
            paddingLeft: gMDynamicSize(14) + 'px',
            marginBottom: isMobile ? gMDynamicSize(13) + 'px' : gMDynamicSize(17) + 'px'
          }"
          :hover="hover"
          :text="currentItem.jobTitle"
          :text-align-center="true"
          :line-clamp="2"
        />
        <div
          :style="{
            width: 100 + '%',
            textAlign: 'center',
            marginBottom: isMobile ? gMDynamicSize(23) + 'px' : gMDynamicSize(27) + 'px'
          }"
        >
          <chipElementSK
            v-for="item in getCategories(currentItem.categories)"
            :key="item.nr"
            style="display: inline"
            @click="
              updateSelectedCategories({
                categoryObj: item,
                maxSelection: 3
              })
            "
            :style="{
              display: 'inline-flex',
              marginBottom: gMDynamicSize(7) + 'px'
            }"
            :text="item.userLanguage"
            :hovorable="true"
          />
        </div>
        <div :class="isMobile ? 'textGradientMobileSK' : 'textGradientSK'">
          <P1SK
            :style="{
              paddingRight: gMDynamicSize(14) + 'px',
              paddingLeft: gMDynamicSize(14) + 'px'
            }"
            :text="currentItem.bio"
          />
        </div>
      </div>

      <div v-else>
        <P2SK
          :style="{
            marginTop: 50 + 'px',
            paddingRight: gMDynamicSize(30) + 'px',
            paddingLeft: gMDynamicSize(30) + 'px',
            marginBottom: gMDynamicSize(25) + 'px'
          }"
          :text="'skSearchNoProfileFound'"
          :bold="true"
          :text-align-center="true"
          :line-clamp="2"
        />
        <P2SK
          :style="{
            paddingRight: gMDynamicSize(30) + 'px',
            paddingLeft: gMDynamicSize(30) + 'px',
            marginBottom: gMDynamicSize(25) + 'px'
          }"
          :text="'skSearchYouFillTheGap'"
          :bold="true"
          :text-align-center="true"
          :line-clamp="2"
        />
      </div>

      <div v-if="currentItem.firstName" class="level-item">
        <chipElementSK
          :style="{
            position: 'fixed',
            bottom: isMobile ? gMDynamicSize(25) + 'px' : gMDynamicSize(45) + 'px',
            zIndex: 2
          }"
          :button="true"
          :parentHover="hover"
          :text="'skSearchCVButtonText'"
        />
      </div>
    </router-link>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import axiosCRUD from '../../axios/axiosCRUD'

export default {
  props: ['currentItem', 'defaultPicture'],
  data() {
    return {
      picturePath: '',
      isOverflown: false,
      showExpertise: true,
      hover: false,
      categoryhover: false
    }
  },
  watch: {
    scrollingHasStopped(scrollingHasStopped) {
      setTimeout(() => {
        if (scrollingHasStopped && this.picturePath === '') {
          if (
            this.currentItem &&
            this.currentItem.guid !== '00000000-0000-0000-0000-000000000000'
          ) {
            if (this.picCacheArr && this.picCacheArr.length > 0) {
              const picObj = this.picCacheArr.find((obj) => {
                return obj.guid === this.currentItem.guid
              })
              if (picObj === undefined) {
                this.picturePath = this.defaultPicture
                axiosCRUD.get(`/sheknows/picture/${this.currentItem.guid}`).then((response) => {
                  if (response.data.picData) {
                    this.picturePath = `data:image/jpg;base64,${response.data.picData}`

                    const newObj = {}
                    newObj.guid = this.currentItem.guid
                    newObj.lastPictureUpdate = this.currentItem.lastPictureUpdate
                    newObj.picturePath = `data:image/jpg;base64,${response.data.picData}`
                    this.picCacheArr.push(newObj)
                    // this.setPicCacheArr(this.picCacheArr)
                  } else if (this.currentItem.pictureName !== '') {
                    this.picturePath = require(`@/assets/portraits/${this.currentItem.pictureName}`)
                  }
                })
              } else if (picObj.lastPictureUpdate !== this.currentItem.lastPictureUpdate) {
                this.picturePath = this.defaultPicture
                axiosCRUD.get(`/sheknows/picture/${this.currentItem.guid}`).then((response) => {
                  if (response.data.picData) {
                    this.picturePath = `data:image/jpg;base64,${response.data.picData}`

                    picObj.lastPictureUpdate = this.currentItem.lastPictureUpdate
                    picObj.picturePath = `data:image/jpg;base64,${response.data.picData}`
                    // this.setPicCacheArr(this.picCacheArr)
                  } else if (this.currentItem.pictureName !== '') {
                    this.picturePath = picObj.picturePath
                  }
                })
              } else {
                this.picturePath = picObj.picturePath
              }
            } else {
              this.picturePath = this.defaultPicture
              axiosCRUD.get(`/sheknows/picture/${this.currentItem.guid}`).then((response) => {
                if (response.data.picData) {
                  this.picturePath = `data:image/jpg;base64,${response.data.picData}`

                  const newObj = {}
                  newObj.guid = this.currentItem.guid
                  newObj.lastPictureUpdate = this.currentItem.lastPictureUpdate
                  newObj.picturePath = `data:image/jpg;base64,${response.data.picData}`
                  this.setPicCacheArr([newObj])
                } else if (this.currentItem.pictureName !== '') {
                  this.picturePath = require(`@/assets/portraits/${this.currentItem.pictureName}`)
                }
              })
            }
          }
        }
      })
    }
  },
  mounted() {
    if (this.picCacheArr && this.picCacheArr.length > 0) {
      const picObj = this.picCacheArr.find((obj) => {
        return obj.guid === this.currentItem.guid
      })
      if (picObj) {
        this.picturePath = picObj.picturePath
      } else if (
        this.scrollingHasStopped &&
        this.currentItem &&
        this.currentItem.guid !== '00000000-0000-0000-0000-000000000000'
      ) {
        this.picturePath = this.defaultPicture
        axiosCRUD.get(`/sheknows/picture/${this.currentItem.guid}`).then((response) => {
          if (response.data.picData) {
            this.picturePath = `data:image/jpg;base64,${response.data.picData}`

            const newObj = {}
            newObj.guid = this.currentItem.guid
            newObj.lastPictureUpdate = this.currentItem.lastPictureUpdate
            newObj.picturePath = `data:image/jpg;base64,${response.data.picData}`
            this.setPicCacheArr([newObj])
          } else if (this.currentItem.pictureName !== '') {
            this.picturePath = require(`@/assets/portraits/${this.currentItem.pictureName}`)
          }
        })
      }
    } else if (
      this.scrollingHasStopped &&
      this.currentItem &&
      this.currentItem.guid !== '00000000-0000-0000-0000-000000000000'
    ) {
      this.picturePath = this.defaultPicture
      axiosCRUD.get(`/sheknows/picture/${this.currentItem.guid}`).then((response) => {
        if (response.data.picData) {
          this.picturePath = `data:image/jpg;base64,${response.data.picData}`

          const newObj = {}
          newObj.guid = this.currentItem.guid
          newObj.lastPictureUpdate = this.currentItem.lastPictureUpdate
          newObj.picturePath = `data:image/jpg;base64,${response.data.picData}`
          this.setPicCacheArr([newObj])
        } else if (this.currentItem.pictureName !== '') {
          this.picturePath = require(`@/assets/portraits/${this.currentItem.pictureName}`)
        }
      })
    }

    // if (this.currentItem && this.currentItem.guid !== '00000000-0000-0000-0000-000000000000') {
    //   if (this.picCacheArr && this.picCacheArr.length > 0) {
    //     const picObj = this.picCacheArr.find((obj) => {
    //       return obj.guid === this.currentItem.guid
    //     })
    //     if (picObj === undefined) {
    //       axiosCRUD.get(`/sheknows/picture/${this.currentItem.guid}`).then((response) => {
    //         if (response.data.picData) {
    //           this.picturePath = `data:image/jpg;base64,${response.data.picData}`
    //           const newObj = {}
    //           newObj.guid = this.currentItem.guid
    //           newObj.lastPictureUpdate = this.currentItem.lastPictureUpdate
    //           newObj.picturePath = `data:image/jpg;base64,${response.data.picData}`
    //           this.picCacheArr.push(newObj)
    //           // this.setPicCacheArr(this.picCacheArr)
    //         } else if (this.currentItem.pictureName !== '') {
    //           this.picturePath = require(`@/assets/portraits/${this.currentItem.pictureName}`)
    //         } else {
    //           this.showDefaultImage = true
    //         }
    //       })
    //     } else if (picObj.lastPictureUpdate !== this.currentItem.lastPictureUpdate) {
    //       axiosCRUD.get(`/sheknows/picture/${this.currentItem.guid}`).then((response) => {
    //         if (response.data.picData) {
    //           this.picturePath = `data:image/jpg;base64,${response.data.picData}`
    //           picObj.lastPictureUpdate = this.currentItem.lastPictureUpdate
    //           picObj.picturePath = `data:image/jpg;base64,${response.data.picData}`
    //           // this.setPicCacheArr(this.picCacheArr)
    //         } else if (this.currentItem.pictureName !== '') {
    //           this.picturePath = picObj.picturePath
    //         } else {
    //           this.showDefaultImage = true
    //         }
    //       })
    //     } else {
    //       this.picturePath = picObj.picturePath
    //     }
    //   } else {
    //     axiosCRUD.get(`/sheknows/picture/${this.currentItem.guid}`).then((response) => {
    //       if (response.data.picData) {
    //         this.picturePath = `data:image/jpg;base64,${response.data.picData}`
    //         const newObj = {}
    //         newObj.guid = this.currentItem.guid
    //         newObj.lastPictureUpdate = this.currentItem.lastPictureUpdate
    //         newObj.picturePath = `data:image/jpg;base64,${response.data.picData}`
    //         this.setPicCacheArr([newObj])
    //       } else if (this.currentItem.pictureName !== '') {
    //         this.picturePath = require(`@/assets/portraits/${this.currentItem.pictureName}`)
    //       } else {
    //         this.showDefaultImage = true
    //       }
    //     })
    //   }
    // }
  },
  computed: {
    ...mapGetters([
      'picCacheArr',
      'selectedLocations',
      'isMobile',
      'appWidth',
      'gMDynamicSize',
      'mainCategories',
      'getCategories',
      'getLanguages',
      'getLocations',
      'scrollingHasStopped'
    ]),
    currentLocations() {
      let locations = []

      if (this.currentItem) {
        const locationsUL = this.getLocations(this.currentItem.locations)

        if (this.selectedLocations.length > 0) {
          this.selectedLocations.forEach((item) => {
            locations.push(item)
          })

          locationsUL.forEach((item) => {
            this.selectedLocations.forEach((inneritem) => {
              if (inneritem.nr !== item.nr) {
                locations.push(item)
              }
            })
          })
        } else {
          locations = locationsUL
        }

        if (locations.length > 3) {
          locations = locations.slice(0, 3)
          locations.push({ nr: 99, userLanguage: '...' })
        }
      }

      return locations
    }
  },
  methods: {
    ...mapActions(['updateSelectedCategories']),
    ...mapMutations(['setPicCacheArr']),
    goToProfile(expert) {
      this.$router.push({
        name: 'profilePage',
        params: {
          userFullName: expert.userFullName
        }
      })
    }
  }
}
</script>

<style scoped>
.thumbnail {
  position: relative;
  overflow: hidden;
}
.thumbnail img {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: auto;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.thumbnail img.portrait {
  /* width: 100%; */
  height: auto;
}
.textGradientSK {
  color: rgba(26, 48, 76, 0.293333);
}
@supports (background-clip: text) and (-webkit-text-fill-color: transparent) {
  .textGradientSK {
    background: -webkit-linear-gradient(
      #1a304c 12.5%,
      rgba(26, 48, 76, 0.293333) 48.96%,
      rgba(26, 48, 76, 0) 90.62%
    );
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.textGradientMobileSK {
  color: rgba(26, 48, 76, 0.293333);
}
@supports (background-clip: text) and (-webkit-text-fill-color: transparent) {
  .textGradientMobileSK {
    background: -webkit-linear-gradient(
      #1a304c 5.5%,
      rgba(26, 48, 76, 0.293333) 20.96%,
      rgba(26, 48, 76, 0) 70.62%
    );
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
</style>
