var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    display: 'flex',
    flexWrap: 'wrap',
    width: 100 + '%',
    height: _vm.appWidth > 860 ? _vm.gMDynamicSize(80) + 'px' : 'auto',
    paddingTop: _vm.gMDynamicSize(20) + 'px'
  })},[(_vm.appWidth > 860)?_c('div',{style:({
      display: 'flex',
      flexWrap: 'wrap'
    })},[_c('div',{style:({
        cursor: 'pointer',
        marginTop: _vm.gMDynamicSize(15) + 'px',
        marginLeft: _vm.gMDynamicSize(54) + 'px'
      }),on:{"click":function($event){return _vm.goToHomePage()}}},[_c('img',{attrs:{"src":require("./../../assets/sheknows_logo.png"),"width":_vm.gMDynamicSize(86) + 'px',"height":_vm.gMDynamicSize(59) + 'px'}})]),_c('div',[_c('P2SK',{style:({
          width: _vm.gMDynamicSize(100) + 'px',
          marginLeft: _vm.gMDynamicSize(15) + 'px'
        }),attrs:{"text":'skHeaderExpertPool'}})],1)]):_c('div',{style:({
      display: 'flex',
      flexWrap: 'wrap'
    })},[_c('div',{style:({
        cursor: 'pointer',
        marginLeft: _vm.gMDynamicSize(20) + 'px'
      }),on:{"click":function($event){return _vm.goToHomePage()}}},[_c('img',{attrs:{"src":require("./../../assets/sheknows_logo.png"),"width":_vm.gMDynamicSize(56) + 'px',"height":_vm.gMDynamicSize(29) + 'px'}})])]),(_vm.appWidth > 860)?_c('div',{style:({
      marginLeft: 'auto',
      display: 'flex',
      flexWrap: 'wrap'
    })},[_c('div',[_c('P2SK',{class:{ 'selected-category': _vm.$route.name === 'projectInfoPage' },style:({
          cursor: 'pointer',
          marginRight: 20 + 'px'
        }),attrs:{"text":'skHeaderAbout'},on:{"click":function($event){return _vm.goToProjectInfoPage()}}})],1),_c('div',[_c('P2SK',{class:{ 'selected-category': _vm.$route.name === 'faqPage' },style:({
          cursor: 'pointer',
          marginRight: 20 + 'px'
        }),attrs:{"text":'FAQ'},on:{"click":function($event){return _vm.goToFAQPage()}}})],1),_c('div',[_c('P2SK',{style:({
          cursor: 'pointer',
          marginRight: 20 + 'px'
        }),attrs:{"text":'skHeaderFeedback'},on:{"click":function($event){return _vm.showFeedbackModal()}}})],1),(_vm.signedIn)?_c('div',[_c('P2SK',{class:{ 'selected-category': _vm.$route.params.userFullName === _vm.userFullName },style:({
          cursor: 'pointer',
          marginRight: 20 + 'px'
        }),attrs:{"text":'skHeaderEditProfile'},on:{"click":function($event){return _vm.goToProfilePage()}}})],1):_c('div',[_c('P2SK',{style:({
          cursor: 'pointer',
          marginRight: 20 + 'px'
        }),attrs:{"text":'skHeaderCreateProfile'},on:{"click":function($event){return _vm.login()}}})],1),(_vm.signedIn)?_c('div',[_c('P2SK',{style:({
          cursor: 'pointer',
          marginRight: 30 + 'px'
        }),attrs:{"text":'skHeaderLogout'},on:{"click":function($event){return _vm.logout()}}})],1):_c('div',[_c('P2SK',{style:({
          cursor: 'pointer',
          marginRight: 30 + 'px'
        }),attrs:{"text":'skHeaderLogin'},on:{"click":function($event){return _vm.login()}}})],1),_c('div',[_c('P2SK',{class:{ 'selected-category': _vm.userLanguageDd === 1 },style:({
          cursor: 'pointer',
          marginRight: 16 + 'px'
        }),attrs:{"text":'skHeaderGerman'},on:{"click":function($event){return _vm.changeLanguage(1)}}})],1),_c('div',[_c('P2SK',{class:{ 'selected-category': _vm.userLanguageDd === 2 },style:({
          cursor: 'pointer',
          marginRight: 16 + 'px'
        }),attrs:{"text":'skHeaderFrench'},on:{"click":function($event){return _vm.changeLanguage(2)}}})],1),_c('div',[_c('P2SK',{class:{ 'selected-category': _vm.userLanguageDd === 3 },style:({
          cursor: 'pointer',
          marginRight: 16 + 'px'
        }),attrs:{"text":'skHeaderItalian'},on:{"click":function($event){return _vm.changeLanguage(3)}}})],1),_c('div',[_c('P2SK',{class:{ 'selected-category': _vm.userLanguageDd === 4 },style:({
          cursor: 'pointer',
          marginRight: 30 + 'px'
        }),attrs:{"text":'skHeaderEnglish'},on:{"click":function($event){return _vm.changeLanguage(4)}}})],1),(_vm.canTranslate)?_c('div',[_c('p',{staticClass:"sk-font-regular sk-user-select-none",class:{ 'selected-category': _vm.translateMode },style:({
          cursor: 'pointer',
          marginRight: 30 + 'px',
          fontSize: _vm.gMDynamicSize(16, 414, 280) + 'px',
          lineHeight: _vm.gMDynamicSize(20, 414, 280) + 'px',
          color: '#1a304c'
        }),on:{"click":function($event){return _vm.toggleTranslateMode()}}},[_vm._v(" "+_vm._s(_vm.currentTranslateMode)+" ")])]):_vm._e()]):_c('div',{style:({
      marginLeft: 'auto',
      marginRight: 22 + 'px'
    })},[_c('svg',{staticStyle:{"cursor":"pointer"},attrs:{"width":"19","height":"16"},on:{"click":function($event){return _vm.changeShowMenu(true)}}},[_c('rect',{attrs:{"width":"19","height":"2"}}),_c('rect',{attrs:{"y":"7","width":"19","height":"2"}}),_c('rect',{attrs:{"y":"14","width":"19","height":"2"}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }