var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card",style:({
    height: _vm.appWidth < 484 ? 100 + '%' : 700 - 2 + 'px',
    flexDirection: 'column',
    display: 'flex'
  })},[_c('div',{staticClass:"modal-card-head",style:({
      paddingLeft: _vm.gMDynamicSize(33) + 'px',
      paddingRight: _vm.gMDynamicSize(33) + 'px',
      background: 'white',
      borderBottom: 'none'
    })},[_c('div',{staticClass:"media is-mobile",style:({
        width: 100 + '%',
        marginBottom: _vm.gMDynamicSize(27, 960, 600) + 'px'
      })},[_c('div',{staticClass:"media-left",style:({
          marginRight: 0 + 'px'
        })},[_c('p',{staticClass:"sk-font-bold",style:({
            marginBottom: _vm.gMDynamicSize(27, 960, 600) + 'px',
            fontSize: _vm.gMDynamicSize(20) + 'px',
            lineHeight: _vm.gMDynamicSize(20) + 'px',
            color: '#1a304c'
          })},[_vm._v(" "+_vm._s(_vm.getDixTranslation('skEditTranslationTitel'))+" ")])]),_c('div',{staticClass:"media-content level-item"}),_c('div',{staticClass:"media-right",style:({
          marginLeft: 0 + 'px'
        })},[_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"src":require("./../../assets/SKicons/closeBlue.svg"),"width":_vm.gMDynamicSize(20, 960, 600) + 'px',"height":_vm.gMDynamicSize(20, 960, 600) + 'px'},on:{"click":function($event){return _vm.changeShowEditTranslationModal(false)}}})])])]),(_vm.editors && this.editingItem)?_c('div',{staticClass:"modal-card-body",style:({
      padding: 0 + 'px',
      marginLeft: _vm.appWidth > 414 ? _vm.gMDynamicSize(30, 960, 600) + 'px' : 20 + 'px',
      marginRight: _vm.appWidth > 414 ? _vm.gMDynamicSize(30, 960, 600) + 'px' : 20 + 'px',
      marginBottom: _vm.appWidth > 414 ? _vm.gMDynamicSize(30, 960, 600) + 'px' : 20 + 'px'
    })},[_c('p',{staticClass:"sk-font-bold",style:({
        marginBottom: 0 + 'px',
        fontSize: _vm.gMDynamicSize(16) + 'px',
        lineHeight: _vm.gMDynamicSize(20) + 'px',
        color: '#1a304c'
      })},[_vm._v(" german ")]),_c('textInputSK',{attrs:{"editorItem":_vm.getEditorItem('german')}}),_c('p',{staticClass:"sk-font-bold",style:({
        marginBottom: 0 + 'px',
        fontSize: _vm.gMDynamicSize(16) + 'px',
        lineHeight: _vm.gMDynamicSize(20) + 'px',
        color: '#1a304c'
      })},[_vm._v(" french ")]),_c('textInputSK',{attrs:{"editorItem":_vm.getEditorItem('french')}}),_c('p',{staticClass:"sk-font-bold",style:({
        marginBottom: 0 + 'px',
        fontSize: _vm.gMDynamicSize(16) + 'px',
        lineHeight: _vm.gMDynamicSize(20) + 'px',
        color: '#1a304c'
      })},[_vm._v(" italian ")]),_c('textInputSK',{attrs:{"editorItem":_vm.getEditorItem('italian')}}),_c('p',{staticClass:"sk-font-bold",style:({
        marginBottom: 0 + 'px',
        fontSize: _vm.gMDynamicSize(16) + 'px',
        lineHeight: _vm.gMDynamicSize(20) + 'px',
        color: '#1a304c'
      })},[_vm._v(" english ")]),_c('textInputSK',{attrs:{"editorItem":_vm.getEditorItem('english')}})],1):_vm._e(),_c('div',{staticClass:"modal-card-foot",style:({
      padding: '0 0 0 0px'
    })},[_c('div',{style:({
        width: 100 + '%',
        textAlign: 'center',
        height: _vm.gMDynamicSize(70, 670, 670) + 'px'
      })},[_c('b-button',{staticClass:"sk-button",style:({
          display: 'inline-flex',
          marginTop: _vm.gMDynamicSize(14, 670, 670) + 'px',
          background: '#3E7291',
          color: 'white',
          fontSize: _vm.gMDynamicSize(16, 670, 670) + 'px',
          lineHeight: _vm.gMDynamicSize(16, 670, 670) + 'px'
        }),on:{"click":function($event){return _vm.confirmTranslationEditor()}}},[_vm._v(_vm._s(_vm.getDixTranslation('skEditTranslationButton')))])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }