var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('P2SK',{style:({ marginBottom: _vm.gMDynamicSize(10) + 'px' }),attrs:{"text":'skEditorProfile',"bold":true}}),_c('div',{style:({ display: 'flex', flexWrap: 'wrap' })},[_c('div',{style:({
        width: _vm.gMDynamicSize(95, 414, 280) + 'px',
        marginRight: _vm.gMDynamicSize(35, 414, 280) + 'px'
      })},[_c('textInputSK',{attrs:{"editorItem":_vm.getEditorItem('title')}})],1),_c('div',{style:({
        width: _vm.gMDynamicSize(170, 414, 280) + 'px',
        marginRight: _vm.gMDynamicSize(35, 414, 280) + 'px'
      })},[_c('textInputSK',{attrs:{"editorItem":_vm.getEditorItem('firstName')}})],1),_c('div',{style:({
        width: _vm.gMDynamicSize(170, 414, 280) + 'px'
      })},[_c('textInputSK',{attrs:{"editorItem":_vm.getEditorItem('lastName')}})],1)]),_c('P2SK',{style:({ marginBottom: _vm.gMDynamicSize(10) + 'px' }),attrs:{"text":'skEditorProfileKorrespondenzsprache',"bold":true}}),_c('P2SK',{style:({ marginBottom: _vm.gMDynamicSize(10) + 'px' }),attrs:{"text":'skEditorProfileKorrespondenzspracheInfo'}}),_c('div',{style:({
      marginBottom: _vm.gMDynamicSize(50, 414, 280) + 'px'
    })},[_c('dropdownInputSK',{attrs:{"editorItem":_vm.getEditorItem('corrLanguageDd'),"dropdowns":_vm.corrlanguages}})],1),_c('P2SK',{style:({ marginBottom: _vm.gMDynamicSize(10) + 'px' }),attrs:{"text":'skEditorProfileSprachen',"bold":true}}),_c('P2SK',{style:({
      marginBottom: _vm.gMDynamicSize(8, 414, 280) + 'px'
    }),attrs:{"text":'skEditorProfileSprachenInfo'}}),_c('div',{style:({
      marginBottom: _vm.gMDynamicSize(36, 414, 280) + 'px'
    })},[_c('dropdownInputSK',{attrs:{"multiple":true,"editorItem":_vm.getEditorItem('languagesDd'),"dropdowns":_vm.languages}})],1),_c('P2SK',{style:({ marginBottom: _vm.gMDynamicSize(10) + 'px' }),attrs:{"text":'skEditorProfileRegionen',"bold":true}}),_c('P2SK',{style:({ marginBottom: _vm.gMDynamicSize(10) + 'px' }),attrs:{"text":'skEditorProfileRegionenInfo'}}),_c('div',{style:({
      marginBottom: _vm.gMDynamicSize(36, 414, 280) + 'px'
    })},[_c('dropdownInputSK',{attrs:{"multiple":true,"editorItem":_vm.getEditorItem('locations'),"dropdowns":_vm.locations}})],1),_c('P2SK',{style:({ marginBottom: _vm.gMDynamicSize(10) + 'px' }),attrs:{"text":'skEditorProfilePicture',"bold":true}}),_c('P2SK',{style:({ marginBottom: _vm.gMDynamicSize(10) + 'px' }),attrs:{"text":'skEditorProfilePictureInfo'}}),_c('div',{style:({
      width: 100 + '%',
      display: 'flex',
      flexWrap: 'wrap'
    })},[_c('chipElementSK',{style:({
        marginBottom: _vm.gMDynamicSize(36) + 'px'
      }),attrs:{"text":'skEditorProfileUploadPicture',"dropdown":true},on:{"click":function($event){return _vm.changeShowPictureUploadModal(true)}}}),(_vm.base64)?_c('chipElementSK',{style:({
        marginBottom: _vm.gMDynamicSize(36) + 'px'
      }),attrs:{"text":'skEditorProfileDeletePicture',"dropdown":true},on:{"click":function($event){return _vm.deletePictureFormFile()}}}):_vm._e(),(_vm.base64)?_c('div',{staticClass:"thumbnail",style:({
        marginLeft: _vm.gMDynamicSize(10, 414, 280) + 'px',
        width: _vm.gMDynamicSize(90, 414, 280) + 'px',
        height: _vm.gMDynamicSize(90, 414, 280) + 'px'
      })},[_c('img',{staticClass:"portrait",attrs:{"src":_vm.base64}})]):_vm._e()],1),_c('div',{style:({
      marginBottom: _vm.gMDynamicSize(50, 414, 280) + 'px'
    })},[_c('P2SK',{style:({ marginBottom: _vm.gMDynamicSize(10) + 'px' }),attrs:{"text":'skEditorProfilePrivacyPolicyInfo',"bold":true}}),_c('chipElementSK',{style:({ marginBottom: _vm.gMDynamicSize(10) + 'px' }),attrs:{"text":'skEditorProfileShowPrivacyPolicy',"dropdown":true},on:{"click":function($event){return _vm.changeShowPrivacyPolicyModal(true)}}}),_c('div',{style:({
        marginLeft: _vm.gMDynamicSize(20, 414, 280) + 'px'
      })},[_c('checkboxInputSK',{attrs:{"editorItem":_vm.getEditorItem('privacyPolicyAccepted'),"disabled":_vm.currentPrivacyPolicyAccepted}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }