import Vue from 'vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import moment from 'moment'

Vue.mixin({
  computed: {
    ...mapGetters([
      'appWidth',
      'user',
      'getItemType',
      'getDefaultSystemType',
      'getDefaultCheckTimeOut',
      'getDefaultEndDate',
      'getDefaultTitle',
      'getDefaultDescription',
      'getDefaultShortName',
      'getIco',
      'getDixTranslation',
      'getTranslateOrg',
      'getTranslateContext',
      'getTranslateItemType',
      'favorites',
      'presentations',
      'getResponsible',
      'colorFlagGray',
      'colorFlagRed',
      'colorFlagYellow',
      'colorFlagGreen'
    ])
  },
  methods: {
    ...mapActions(['actionChangeActualChatItemAndOpenEditor']),
    ...mapMutations(['editElement', 'addElement', 'deleteElement']),

    gMgetEllipsisText(str, maxLength) {
      let visibleString = ''
      if (str) {
        visibleString = str
      }
      if (str && str.length >= maxLength) {
        const arr = str.split(' ')
        let currentStrLength = 0
        const newArr = []

        arr.forEach((word) => {
          currentStrLength += Number(word.length) + 1
          if (currentStrLength <= maxLength) {
            newArr.push(word)
          }
        })
        visibleString = `${newArr.join(' ').trim()}...`
      }
      return visibleString
    },

    gMisEquivalent(a, b) {
      // Create arrays of property names
      const aProps = Object.getOwnPropertyNames(a)
      const bProps = Object.getOwnPropertyNames(b)

      // If number of properties is different,
      // objects are not equivalent
      if (aProps.length !== bProps.length) {
        return false
      }

      for (let i = 0; i < bProps.length - 1; i++) {
        const propName = bProps[i]
        // If values of same property are not equal,
        // objects are not equivalent
        if (a[propName] !== b[propName]) {
          return false
        }
      }

      // If we made it this far, objects
      // are considered equivalent
      return true
    },
    gMgetEditorComponent(item) {
      let inputType = ''
      if (item !== undefined) {
        inputType = item.inputType
      }
      if (
        inputType === 'dropdown' ||
        inputType === 'selection' ||
        inputType === 'selectionMulti' ||
        inputType === 'dropdownFlags'
      ) {
        inputType = 'dropdownSelectionMulti' // only one component for all types
      }
      if (inputType === 'text' || inputType === 'textArea') {
        inputType = 'text' // only one component for all types
      }
      const component = `inputType${this.gMcapitalize(inputType)}`
      if (component !== 'inputType') {
        return component
      }
    },
    gMcapitalize(str) {
      let capitalized = ''
      if (str !== undefined) {
        capitalized = str.charAt(0).toUpperCase() + str.slice(1)
      }
      return capitalized
    },
    gMgetPanelHeight(percentage, size) {
      const calculated = (window.innerHeight / 100) * percentage
      if (calculated < 847 && size === 4) {
        return 847
      }
      if (calculated < 562 && size === 3) {
        return 562
      }
      if (calculated < 419.4 && size === 2) {
        return 419.4
      }
      if (calculated < 277 && size === 1) {
        return 277
      }
      return calculated
    },
    gMtranslate(toolText) {
      const translation = this.getDixTranslation(toolText)
      return translation
    },
    gMtranslateOrg(organization) {
      const translation = this.getTranslateOrg(organization)
      return translation
    },
    gMtranslateContext(context) {
      const translation = this.getTranslateContext(context)
      return translation
    },
    gMtranslateItemType(tableName, typeNr, isSingular) {
      let translation = ''
      translation = this.getTranslateItemType(tableName, typeNr, isSingular)
      return translation
    },

    gMindicatorLabel(typeNr, dateString) {
      let label = ''
      if (typeNr === 1) {
        label = moment.utc(dateString).format('MMMM YYYY')
      }
      if (typeNr === 2) {
        label = `${
          this.gMtranslate('Q') + moment.utc(dateString).quarter().toString()
        } ${moment.utc(dateString).year().toString()}`
      }
      if (typeNr === 3) {
        label = moment.utc(dateString).year().toString()
      }
      if (typeNr === 4) {
        label = `${this.gMtranslate('Week')} ${moment
          .utc(dateString)
          .week()
          .toString()}, ${moment.utc(dateString).year().toString()}`
      }
      if (typeNr === 5) {
        label = moment.utc(dateString).format('dddd, D. MMM YYYY')
      }
      return label
    },
    gMindicatorLabelShort(typeNr, dateString) {
      let label = ''
      if (typeNr === 1) {
        label = moment.utc(dateString).format('MMM YY')
      }
      if (typeNr === 2) {
        label = `${
          this.gMtranslate('Q') + moment.utc(dateString).quarter().toString()
        } ${moment.utc(dateString).year().toString()}`
      }
      if (typeNr === 3) {
        label = moment.utc(dateString).year().toString()
      }
      if (typeNr === 4) {
        label = `${this.gMtranslate('Week')} ${moment
          .utc(dateString)
          .week()
          .toString()}, ${moment.utc(dateString).year().toString()}`
      }
      if (typeNr === 5) {
        label = moment.utc(dateString).format('D. MMM YY')
      }
      return label
    },

    gMgetResponsible(organization, context) {
      const responsible = this.getResponsible(organization, context)
      return responsible
    },
    gMgetOrgOwner(organization) {
      const orgOwner = this.getResponsible(organization, 1)
      return orgOwner
    },

    gMopenChatEditor(crudType, chatNr, item) {
      this.actionChangeActualChatItemAndOpenEditor({
        crudType,
        chatNr,
        item
      })
    },
    gMinputTypeCheckAccess(editorAccessRoles) {
      if (editorAccessRoles !== '') {
        let hasAccess = false
        if (editorAccessRoles.toLowerCase().indexOf('admin')) {
          hasAccess = this.gMuserIsAdmin()
          if (hasAccess === true) {
            return true
          }
        }
        if (editorAccessRoles.toLowerCase().indexOf('keyuser')) {
          hasAccess = this.gMuserIsKeyUser()
          if (hasAccess === true) {
            return true
          }
        }
        return false
      }
      return true
    },
    gMisOnline() {
      return true
    },

    gMgetIcon(icoName, collection) {
      const icon = this.getIco(icoName, collection)
      return icon
    },
    gMgetItemType(tableName, typeNr) {
      const itemType = this.getItemType(tableName, typeNr)
      return itemType
    },
    gMgetItemDefaultSystemType(tableName, typeNr) {
      const systemType = this.getDefaultSystemType(tableName, typeNr)
      return systemType
    },
    gMgetItemDefaultCheckTimeOut(tableName, typeNr, startDateString) {
      const checkTimeOut = this.getDefaultCheckTimeOut(tableName, typeNr, startDateString)
      return checkTimeOut
    },
    gMgetItemDefaultEndDate(tableName, typeNr, startDateString) {
      const endDate = this.getDefaultEndDate(tableName, typeNr, startDateString)
      return endDate
    },
    gMgetItemDefaultTitle(tableName, typeNr) {
      const title = this.getDefaultTitle(tableName, typeNr)
      return title
    },
    gMgetItemDefaultDescription(tableName, typeNr) {
      const description = this.getDefaultDescription(tableName, typeNr)
      return description
    },
    gMgetDefaultShortName(title) {
      const shortName = this.getDefaultShortName(title)
      return shortName
    },

    gMcheckIsValid(checkStatus, checkTimeOutString) {
      if (checkStatus < 1) {
        return false
      }

      const now = moment.utc()
      const timeout = moment.utc(checkTimeOutString)

      if (timeout.isBefore(now, 'day')) {
        return false
      }

      return true
    },
    gMgetCheckColor(checkStatus, checkTimeOutString) {
      // for font awesome
      const gray = this.colorFlagGray
      const red = this.colorFlagRed
      const yellow = this.colorFlagYellow
      const green = this.colorFlagGreen

      const isValid = this.gMcheckIsValid(checkStatus, checkTimeOutString)

      if (isValid === false) {
        return gray
      }
      if (checkStatus === 1) {
        return red
      }
      if (checkStatus === 2) {
        return yellow
      }
      if (checkStatus === 3) {
        return green
      }
    },
    gMgetInitials(title) {
      let initials = ''
      let first = ''
      let second = ''
      if (title.indexOf(' ') !== -1) {
        first = title.charAt(0)
        second = title.charAt(title.indexOf(' ') + 1)
        initials = first + second
      } else if (title.length > 1) {
        first = title.charAt(0)
        second = title.charAt(1)
        initials = first + second
      } else {
        first = title.charAt(0)
        initials = first
      }
      return initials.toUpperCase()
    },
    gMelementIsFavorite(elementGuid) {
      let isFavorite = false
      const favorites = this.favoritesFE
      let arr = []
      if (favorites !== undefined && favorites.length > 0) {
        arr = favorites.filter((item) => {
          return item.foreignGuid.toLowerCase() === elementGuid.toLowerCase()
        })
      }
      if (arr.length > 0) {
        isFavorite = true
      }
      return isFavorite
    },
    gMelementIsPresentation(elementGuid) {
      let isPresentation = false
      const presentations = this.presentations
      let arr = []
      if (presentations !== undefined && presentations.length > 0) {
        arr = presentations.filter((item) => {
          return item.foreignGuid.toLowerCase() === elementGuid.toLowerCase()
        })
      }
      if (arr.length > 0) {
        isPresentation = true
      }
      return isPresentation
    }
  }
})
