var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card",style:({
    height: 100 + '%',
    flexDirection: 'column',
    display: 'flex'
  })},[_c('div',{staticClass:"modal-card-head",style:({
      paddingLeft: _vm.gMDynamicSize(33) + 'px',
      paddingRight: _vm.gMDynamicSize(33) + 'px',
      background: 'white',
      borderBottom: 'none'
    })},[_c('div',{staticClass:"media is-mobile",style:({
        width: 100 + '%',
        marginBottom: 0 + 'px'
      })},[_c('div',{staticClass:"media-left",style:({
          marginRight: 0 + 'px'
        })},[_c('H2SK',{style:({
            marginBottom: _vm.gMDynamicSize(20) + 'px'
          }),attrs:{"text":'skEditorModalCreateProfile',"bold":true}}),(_vm.isFirstEdit === false)?_c('div',[_c('P2SK',{style:({
              display: 'inline',
              cursor: 'pointer',
              marginRight: _vm.gMDynamicSize(14) + 'px'
            }),attrs:{"text":'skEditorModalProfile',"bold":_vm.currentActiveIndex === 0 ? true : false},on:{"click":function($event){_vm.currentActiveIndex = 0}}}),_c('P2SK',{style:({
              display: 'inline',
              cursor: 'pointer',
              marginRight: _vm.gMDynamicSize(14) + 'px'
            }),attrs:{"text":'skEditorModalCategory',"bold":_vm.currentActiveIndex === 1 ? true : false},on:{"click":function($event){_vm.currentActiveIndex = 1}}}),_c('P2SK',{style:({
              display: 'inline',
              cursor: 'pointer',
              marginRight: _vm.gMDynamicSize(14) + 'px'
            }),attrs:{"text":'skEditorModalJob',"bold":_vm.currentActiveIndex === 2 ? true : false},on:{"click":function($event){_vm.currentActiveIndex = 2}}}),_c('P2SK',{style:({
              display: 'inline',
              cursor: 'pointer',
              marginRight: _vm.gMDynamicSize(14) + 'px'
            }),attrs:{"text":'skEditorModalCV',"bold":_vm.currentActiveIndex === 3 ? true : false},on:{"click":function($event){_vm.currentActiveIndex = 3}}}),_c('P2SK',{style:({
              display: 'inline',
              cursor: 'pointer'
            }),attrs:{"text":'skEditorModalContact',"bold":_vm.currentActiveIndex === 4 ? true : false},on:{"click":function($event){_vm.currentActiveIndex = 4}}})],1):_vm._e(),_c('div',[(
              _vm.currentActiveIndex === 0 &&
              (_vm.$v.editingItem.firstName.$invalid || _vm.$v.editingItem.lastName.$invalid)
            )?_c('div',[_c('P2SK',{style:({ color: 'red' }),attrs:{"text":'skEditorModalMissingNameError'}})],1):(_vm.currentActiveIndex === 0 && _vm.$v.editingItem.languagesDd.$invalid)?_c('div',[_c('P2SK',{style:({ color: 'red' }),attrs:{"text":'skEditorModalMissingLanguagesDdError'}})],1):(_vm.currentActiveIndex === 0 && _vm.$v.editingItem.locations.$invalid)?_c('div',[_c('P2SK',{style:({ color: 'red' }),attrs:{"text":'skEditorModalMissingLocationsError'}})],1):(_vm.currentActiveIndex === 0 && _vm.$v.editingItem.privacyPolicyAccepted.$invalid)?_c('div',[_c('P2SK',{style:({ color: 'red' }),attrs:{"text":'skEditorModalMissingPrivacyPolicyError'}})],1):(_vm.currentActiveIndex === 1 && _vm.$v.editingItem.categories.$invalid)?_c('div',[_c('P2SK',{style:({ color: 'red' }),attrs:{"text":'skEditorModalMissingCategoriesError'}})],1):(_vm.currentActiveIndex === 2 && _vm.$v.editingItem.jobTitle.$invalid)?_c('div',[_c('P2SK',{style:({ color: 'red' }),attrs:{"text":'skEditorModalMissingJobTitleError'}})],1):_c('div',{class:'hide-content-sk'},[_c('P2SK',{style:({ color: 'red' }),attrs:{"text":'placeholder error message'}})],1)])],1),_c('div',{staticClass:"media-content level-item"}),_c('div',{staticClass:"media-right",style:({
          marginLeft: 0 + 'px'
        })},[_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"src":require("./../../assets/SKicons/closeBlue.svg"),"width":_vm.gMDynamicSize(20) + 'px',"height":_vm.gMDynamicSize(20) + 'px'},on:{"click":function($event){return _vm.changeShowEditorModal(false)}}})])])]),_c('div',{staticClass:"modal-card-body",style:({
      padding: 0 + 'px',
      marginLeft: _vm.gMDynamicSize(33) + 'px',
      marginRight: _vm.gMDynamicSize(2) + 'px'
    })},[_c('EditorProfileBodySK',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentActiveIndex === 0),expression:"currentActiveIndex === 0"}],attrs:{"editors":_vm.editors}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentActiveIndex === 1),expression:"currentActiveIndex === 1"}]},[_c('P2SK',{style:({ marginBottom: _vm.gMDynamicSize(10) + 'px' }),attrs:{"text":'skEditorProfileDescription',"bold":true}}),_c('CategorySelectBodySK',{attrs:{"maxSelection":3}})],1),_c('EditorJobBodySK',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentActiveIndex === 2),expression:"currentActiveIndex === 2"}],attrs:{"editors":_vm.editors}}),_c('EditorCVBodySK',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentActiveIndex === 3),expression:"currentActiveIndex === 3"}],attrs:{"editors":_vm.editors}}),_c('EditorContactBodySK',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentActiveIndex === 4),expression:"currentActiveIndex === 4"}],attrs:{"editors":_vm.editors}})],1),_c('div',{staticClass:"modal-card-foot",style:({
      padding: '0 0 0 0px'
    })},[_c('div',{style:({
        width: 100 + '%',
        textAlign: 'center',
        height: _vm.gMDynamicSize(70, 960, 960) + 'px'
      })},[((_vm.isFirstEdit && _vm.currentActiveIndex === 4) || _vm.isFirstEdit === false)?_c('buttonElementSK',{style:({
          display: 'inline-flex',
          height: _vm.gMDynamicSize(30, 670, 670) + 'px',
          marginTop: _vm.gMDynamicSize(14, 960, 960) + 'px'
        }),attrs:{"disabled":_vm.$v.editingItem.languagesDd.$invalid ||
          _vm.$v.editingItem.locations.$invalid ||
          _vm.$v.editingItem.privacyPolicyAccepted.$invalid ||
          _vm.$v.editingItem.firstName.$invalid ||
          _vm.$v.editingItem.lastName.$invalid ||
          _vm.$v.editingItem.categories.$invalid ||
          _vm.$v.editingItem.jobTitle.$invalid,"text":'skEditorProfileSave'},on:{"click":function($event){return _vm.confirmProfileEditor()}}}):_vm._e(),(_vm.isFirstEdit && _vm.currentActiveIndex !== 4)?_c('buttonElementSK',{style:({
          display: 'inline-flex',
          height: _vm.gMDynamicSize(30, 670, 670) + 'px',
          marginTop: _vm.gMDynamicSize(14, 960, 960) + 'px'
        }),attrs:{"disabled":(_vm.$v.editingItem.languagesDd.$invalid && _vm.currentActiveIndex === 0) ||
          (_vm.$v.editingItem.locations.$invalid && _vm.currentActiveIndex === 0) ||
          (_vm.$v.editingItem.privacyPolicyAccepted.$invalid && _vm.currentActiveIndex === 0) ||
          ((_vm.$v.editingItem.firstName.$invalid || _vm.$v.editingItem.lastName.$invalid) &&
            _vm.currentActiveIndex === 0) ||
          (_vm.$v.editingItem.categories.$invalid && _vm.currentActiveIndex === 1) ||
          (_vm.$v.editingItem.jobTitle.$invalid && _vm.currentActiveIndex === 2),"text":'skEditorProfileNext'},on:{"click":function($event){_vm.currentActiveIndex += 1}}}):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }