var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    marginBottom: _vm.gMDynamicSize(5) + 'px',
    width: 100 + '%'
  })},[_c('iconSK',{style:({
      display: 'inline',
      cursor: 'pointer',
      marginRight: _vm.gMDynamicSize(10) + 'px'
    }),attrs:{"icon":_vm.isChecked ? 'rectangleChecked' : 'rectangleUnchecked',"width":10,"height":10},on:{"click":function($event){return _vm.iconClicked()}}}),_c('div',{style:({
      display: 'inline',
      cursor: 'pointer',
      marginRight: 5 + 'px'
    }),on:{"mouseover":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}},[(_vm.isMainCategory)?_c('P2SK',{style:({
        display: 'inline',
        color: _vm.selectedMainCategory === _vm.categoryObj.nr || _vm.hover ? '#F95268' : 'black'
      }),attrs:{"text":_vm.categoryObj.userLanguage,"bold":_vm.isMainCategory ? true : false},on:{"click":function($event){return _vm.labelClicked()}}}):_vm._e(),(!_vm.isMainCategory)?_c('P2SK',{style:({
        display: 'inline',
        color: '#3E7291'
      }),attrs:{"text":_vm.categoryObj.userLanguage,"bold":_vm.isMainCategory ? true : false},on:{"click":function($event){return _vm.labelClicked()}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }