var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.editorItem)?_c('div',[(_vm.multiple && _vm.currentSelected && _vm.currentSelected.length > 0)?_c('div',{style:({
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: _vm.gMDynamicSize(10) + 'px'
    })},_vm._l((_vm.currentSelected),function(obj){return _c('P2SK',{key:obj.nr,style:({ marginRight: _vm.gMDynamicSize(10) + 'px' }),attrs:{"text":obj.userLanguage}})}),1):_vm._e(),_c('section',{on:{"mouseover":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false},"click":function($event){_vm.showDropdown = !_vm.showDropdown}}},[_c('b-dropdown',{ref:"dropdownSK",style:({
        width: 264 + 'px',
        fontSize: _vm.gMDynamicSize(16) + 'px',
        lineHeight: _vm.gMDynamicSize(20) + 'px'
      }),attrs:{"disabled":_vm.translateMode,"expanded":"","aria-role":"list","mobile-modal":false,"close-on-click":false,"multiple":_vm.multiple,"scrollable":""},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [(_vm.multiple)?_c('chipElementSK',{attrs:{"dropdown":true,"hasIcon":true,"text":_vm.getDixTranslation(_vm.editorItem.placeholder)}}):(_vm.currentSelected)?_c('chipElementSK',{attrs:{"dropdown":true,"hasIcon":true,"text":_vm.currentSelected.userLanguage}}):_vm._e()]},proxy:true}],null,false,3692996347),model:{value:(_vm.currentSelected),callback:function ($$v) {_vm.currentSelected=$$v},expression:"currentSelected"}},_vm._l((_vm.dropdowns),function(obj){return _c('b-dropdown-item',{key:obj.nr,style:({
          width: 200 + 'px'
        }),attrs:{"aria-role":"listitem","value":obj}},[_vm._v(" "+_vm._s(obj.userLanguage)+" ")])}),1)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }