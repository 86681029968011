import Vue from 'vue'

import CheckBoxEditorSK from '../components/editors/CheckBoxEditorSK.vue'
import DropdownEditorSK from '../components/editors/DropdownEditorSK.vue'
import NumberEditorSK from '../components/editors/NumberEditorSK.vue'
import TextareaEditorSK from '../components/editors/TextareaEditorSK.vue'
import TextEditorSK from '../components/editors/TextEditorSK.vue'

import ButtonElementSK from '../components/elements/ButtonElementSK.vue'
import CategorySelectElementSK from '../components/elements/CategorySelectElementSK.vue'
import ChipElementSK from '../components/elements/ChipElementSK.vue'
import H1SK from '../components/elements/H1SK.vue'
import H2SK from '../components/elements/H2SK.vue'
import IconSK from '../components/elements/IconSK.vue'
import MediaEditLinkElementSK from '../components/elements/MediaEditLinkElementSK.vue'
import P1SK from '../components/elements/P1SK.vue'
import P2SK from '../components/elements/P2SK.vue'
import P3SK from '../components/elements/P3SK.vue'

import CheckboxInputSK from '../components/inputs/CheckboxInputSK.vue'
import DropdownInputSK from '../components/inputs/DropdownInputSK.vue'
import TextareaInputSK from '../components/inputs/TextareaInputSK.vue'
import TextInputSK from '../components/inputs/TextInputSK.vue'

Vue.component('CheckBoxEditorSK', CheckBoxEditorSK)
Vue.component('DropdownEditorSK', DropdownEditorSK)
Vue.component('NumberEditorSK', NumberEditorSK)
Vue.component('TextareaEditorSK', TextareaEditorSK)
Vue.component('TextEditorSK', TextEditorSK)

Vue.component('ButtonElementSK', ButtonElementSK)
Vue.component('CategorySelectElementSK', CategorySelectElementSK)
Vue.component('ChipElementSK', ChipElementSK)
Vue.component('H1SK', H1SK)
Vue.component('H2SK', H2SK)
Vue.component('IconSK', IconSK)
Vue.component('MediaEditLinkElementSK', MediaEditLinkElementSK)
Vue.component('P1SK', P1SK)
Vue.component('P2SK', P2SK)
Vue.component('P3SK', P3SK)

Vue.component('CheckboxInputSK', CheckboxInputSK)
Vue.component('DropdownInputSK', DropdownInputSK)
Vue.component('TextareaInputSK', TextareaInputSK)
Vue.component('TextInputSK', TextInputSK)
