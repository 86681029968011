var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card",style:({
    height: _vm.appWidth < 484 ? 100 + '%' : 655 + 'px',
    margin: 'auto',
    flexDirection: 'column',
    display: 'flex'
  })},[_c('div',{staticClass:"modal-card-head",style:({
      paddingLeft: _vm.gMDynamicSize(33) + 'px',
      paddingRight: _vm.gMDynamicSize(33) + 'px',
      background: 'white',
      borderBottom: 'none'
    })},[_c('div',{staticClass:"media is-mobile",style:({
        width: 100 + '%',
        marginBottom: _vm.gMDynamicSize(47, 600, 280) + 'px'
      })},[_c('div',{staticClass:"media-content level-item"},[_c('H2SK',{attrs:{"text":'skNoRoboter',"bold":true}})],1),_c('div',{staticClass:"media-right",style:({
          marginLeft: 0 + 'px'
        })},[_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"src":require("./../../assets/SKicons/closeBlue.svg"),"width":20 + 'px',"height":20 + 'px'},on:{"click":function($event){return _vm.$emit('close')}}})])])]),(_vm.testActive)?_c('div',{key:_vm.updateKey,staticClass:"modal-card-body",style:({
      padding: 0 + 'px',
      marginLeft: _vm.appWidth > 414 ? _vm.gMDynamicSize(30, 600, 280) + 'px' : 20 + 'px',
      marginRight: _vm.appWidth > 414 ? _vm.gMDynamicSize(30, 600, 280) + 'px' : 20 + 'px',
      marginBottom: _vm.appWidth > 414 ? _vm.gMDynamicSize(30, 600, 280) + 'px' : 20 + 'px'
    })},[(_vm.randomTest)?_c('P2SK',{attrs:{"text":_vm.randomTest.userLanguage,"textAlignCenter":true,"bold":true}}):_vm._e(),_c('VueDragResize',{style:({ background: _vm.orderArr[0] }),attrs:{"axis":"x","isResizable":false,"w":_vm.currentSize,"h":_vm.currentSize,"z":_vm.firstZIndex,"x":this.gMDynamicSize(40, 600, 280),"y":200},on:{"dragstop":function($event){return _vm.checkRedraw()},"dragging":function($event){return _vm.handleDrag($event, 1)}}}),_c('VueDragResize',{style:({ background: _vm.orderArr[1] }),attrs:{"axis":"x","isResizable":false,"z":_vm.secondZIndex,"x":this.currentSize + this.gMDynamicSize(40 + 30, 600, 280),"y":200,"w":_vm.currentSize,"h":_vm.currentSize},on:{"dragstop":function($event){return _vm.checkRedraw()},"dragging":function($event){return _vm.handleDrag($event, 2)}}}),_c('VueDragResize',{style:({ background: _vm.orderArr[2] }),attrs:{"axis":"x","isResizable":false,"z":_vm.thirdZIndex,"x":2 * this.currentSize + this.gMDynamicSize(40 + 30 + 30, 600, 280),"y":200,"w":_vm.currentSize,"h":_vm.currentSize},on:{"dragstop":function($event){return _vm.checkRedraw()},"dragging":function($event){return _vm.handleDrag($event, 3)}}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }