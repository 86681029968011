<template>
  <div
    :style="{
      display: 'flex',
      flexWrap: 'wrap',
      width: 100 + '%',
      height: appWidth > 860 ? gMDynamicSize(80) + 'px' : 'auto',
      paddingTop: gMDynamicSize(20) + 'px'
    }"
  >
    <!-- Left side -->
    <div
      v-if="appWidth > 860"
      :style="{
        display: 'flex',
        flexWrap: 'wrap'
      }"
    >
      <div
        @click="goToHomePage()"
        :style="{
          cursor: 'pointer',
          marginTop: gMDynamicSize(15) + 'px',
          marginLeft: gMDynamicSize(54) + 'px'
        }"
      >
        <img
          src="./../../assets/sheknows_logo.png"
          :width="gMDynamicSize(86) + 'px'"
          :height="gMDynamicSize(59) + 'px'"
        />
      </div>
      <div>
        <P2SK
          :style="{
            width: gMDynamicSize(100) + 'px',
            marginLeft: gMDynamicSize(15) + 'px'
          }"
          :text="'skHeaderExpertPool'"
        />
      </div>
    </div>
    <div
      v-else
      :style="{
        display: 'flex',
        flexWrap: 'wrap'
      }"
    >
      <div
        @click="goToHomePage()"
        :style="{
          cursor: 'pointer',
          marginLeft: gMDynamicSize(20) + 'px'
        }"
      >
        <img
          src="./../../assets/sheknows_logo.png"
          :width="gMDynamicSize(56) + 'px'"
          :height="gMDynamicSize(29) + 'px'"
        />
      </div>
    </div>

    <!-- Right side -->
    <div
      v-if="appWidth > 860"
      :style="{
        marginLeft: 'auto',
        display: 'flex',
        flexWrap: 'wrap'
      }"
    >
      <div>
        <P2SK
          :class="{ 'selected-category': $route.name === 'projectInfoPage' }"
          :style="{
            cursor: 'pointer',
            marginRight: 20 + 'px'
          }"
          :text="'skHeaderAbout'"
          @click="goToProjectInfoPage()"
        />
      </div>
      <div>
        <P2SK
          :class="{ 'selected-category': $route.name === 'faqPage' }"
          :style="{
            cursor: 'pointer',
            marginRight: 20 + 'px'
          }"
          :text="'FAQ'"
          @click="goToFAQPage()"
        />
      </div>
      <div>
        <P2SK
          :style="{
            cursor: 'pointer',
            marginRight: 20 + 'px'
          }"
          :text="'skHeaderFeedback'"
          @click="showFeedbackModal()"
        />
      </div>
      <div v-if="signedIn">
        <P2SK
          :class="{ 'selected-category': $route.params.userFullName === userFullName }"
          :style="{
            cursor: 'pointer',
            marginRight: 20 + 'px'
          }"
          :text="'skHeaderEditProfile'"
          @click="goToProfilePage()"
        />
      </div>
      <div v-else>
        <P2SK
          :style="{
            cursor: 'pointer',
            marginRight: 20 + 'px'
          }"
          :text="'skHeaderCreateProfile'"
          @click="login()"
        />
      </div>
      <div v-if="signedIn">
        <P2SK
          :style="{
            cursor: 'pointer',
            marginRight: 30 + 'px'
          }"
          :text="'skHeaderLogout'"
          @click="logout()"
        />
      </div>
      <div v-else>
        <P2SK
          :style="{
            cursor: 'pointer',
            marginRight: 30 + 'px'
          }"
          :text="'skHeaderLogin'"
          @click="login()"
        />
      </div>
      <div>
        <P2SK
          :class="{ 'selected-category': userLanguageDd === 1 }"
          :style="{
            cursor: 'pointer',
            marginRight: 16 + 'px'
          }"
          :text="'skHeaderGerman'"
          @click="changeLanguage(1)"
        />
      </div>
      <div>
        <P2SK
          :class="{ 'selected-category': userLanguageDd === 2 }"
          :style="{
            cursor: 'pointer',
            marginRight: 16 + 'px'
          }"
          :text="'skHeaderFrench'"
          @click="changeLanguage(2)"
        />
      </div>
      <div>
        <P2SK
          :class="{ 'selected-category': userLanguageDd === 3 }"
          :style="{
            cursor: 'pointer',
            marginRight: 16 + 'px'
          }"
          :text="'skHeaderItalian'"
          @click="changeLanguage(3)"
        />
      </div>
      <div>
        <P2SK
          :class="{ 'selected-category': userLanguageDd === 4 }"
          :style="{
            cursor: 'pointer',
            marginRight: 30 + 'px'
          }"
          :text="'skHeaderEnglish'"
          @click="changeLanguage(4)"
        />
      </div>
      <div v-if="canTranslate">
        <p
          :class="{ 'selected-category': translateMode }"
          class="sk-font-regular sk-user-select-none"
          @click="toggleTranslateMode()"
          :style="{
            cursor: 'pointer',
            marginRight: 30 + 'px',
            fontSize: gMDynamicSize(16, 414, 280) + 'px',
            lineHeight: gMDynamicSize(20, 414, 280) + 'px',
            color: '#1a304c'
          }"
        >
          {{ currentTranslateMode }}
        </p>
      </div>
    </div>
    <div
      v-else
      :style="{
        marginLeft: 'auto',
        marginRight: 22 + 'px'
      }"
    >
      <svg width="19" height="16" style="cursor: pointer" @click="changeShowMenu(true)">
        <rect width="19" height="2"></rect>
        <rect y="7" width="19" height="2"></rect>
        <rect y="14" width="19" height="2"></rect>
      </svg>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'userFullName',
      'canTranslate',
      'translateMode',
      'appWidth',
      'bearerToken',
      'gMDynamicSize',
      'signedIn',
      'userLanguageDd'
    ]),
    currentTranslateMode() {
      let text = 'translate Mode off'
      if (this.translateMode) {
        text = 'translate Mode on'
      }
      return text
    }
  },
  methods: {
    ...mapActions(['changeLanguageWithReload']),
    ...mapMutations([
      'toggleTranslateMode',
      'setEditingItem',
      'changeShowMenu',
      'signOut',
      'signIn',
      'changeShowEmailFeedbackModal'
    ]),
    goToProfilePage() {
      if (this.$route.name !== 'profilePage') {
        this.$router.push({
          name: 'profilePage',
          params: {
            userFullName: this.userFullName
          }
        })
      } else {
        this.$router.replace({
          name: 'profilePage',
          params: {
            userFullName: this.userFullName
          }
        })
      }
    },
    goToHomePage() {
      if (this.$route.name !== 'homePage') {
        this.$router.push({
          name: 'homePage'
        })
      }
    },
    goToProjectInfoPage() {
      if (this.$route.name !== 'projectInfoPage') {
        this.$router.push({
          name: 'projectInfoPage'
        })
      }
    },
    goToFAQPage() {
      if (this.$route.name !== 'faqPage') {
        this.$router.push({
          name: 'faqPage'
        })
      }
    },
    login() {
      this.signIn()
    },
    logout() {
      this.signOut()
    },
    changeLanguage(languageNr) {
      this.changeLanguageWithReload({ newLanguageNr: languageNr })
    },
    showFeedbackModal() {
      this.changeShowEmailFeedbackModal(true)
    }
  }
}
</script>

<style scoped>
.selected-category {
  border-bottom: 2px solid;
  border-color: #f95268;
}
</style>
