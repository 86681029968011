var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    width: _vm.isMobile ? _vm.appWidth - _vm.gMDynamicSize(32) + 'px' : 'auto',
    paddingLeft: _vm.gMDynamicSize(15) + 'px',
    paddingRight: _vm.gMDynamicSize(17) + 'px'
  })},[_c('div',{style:({
      textAlign: 'center',
      marginBottom: _vm.isMobile ? _vm.gMDynamicSize(11) + 'px' : 0 + 'px'
    })},[_c('P1SK',{style:({ display: 'inline' }),attrs:{"text":'skFilterSearch'}})],1),_c('div',{style:({
      textAlign: 'center'
    })},[_c('div',{style:({
        display: 'inline-block',
        width: _vm.gMDynamicSize(444, 600, 280) + 'px',
        marginBottom: _vm.gMDynamicSize(20) + 'px'
      }),on:{"mouseover":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}},[_c('b-autocomplete',{ref:"autocomplete",attrs:{"clearable":"","field":"label","data":_vm.filteredDataArray,"placeholder":_vm.getDixTranslation('skFilterYourSearchText')},on:{"blur":function($event){return _vm.handleBlur()}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_vm._v(_vm._s(_vm.getDixTranslation('skFilterNoResults')))]},proxy:true}]),model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}})],1)]),_c('div',{style:({
      textAlign: 'center',
      marginBottom: _vm.isMobile ? _vm.gMDynamicSize(20) + 'px' : 38 + 'px'
    })},[_c('P1SK',{staticClass:"selected-category",style:({ display: 'inline', cursor: 'pointer' }),attrs:{"text":'skFilterByCategories'},on:{"click":function($event){return _vm.changeShowCategorySelectModal(true)}}}),_vm._l((_vm.selectedCategories),function(item){return _c('chipElementSK',{key:item.nr,style:({
        display: 'inline-flex',
        marginTop: _vm.gMDynamicSize(8) + 'px',
        marginLeft: _vm.gMDynamicSize(15) + 'px'
      }),attrs:{"text":item.userLanguage,"hasIcon":true},on:{"click":function($event){return _vm.updateSelectedCategories({
          categoryObj: item,
          maxSelection: 1
        })}}})})],2),_c('div',{style:({
      textAlign: 'center',
      marginBottom: _vm.isMobile ? _vm.gMDynamicSize(23) + 'px' : 38 + 'px'
    })},[_c('P1SK',{class:{ 'selected-category': _vm.filterHasPhone },style:({ display: 'inline', cursor: 'pointer', marginRight: 10 + 'px' }),attrs:{"text":'skFilterHasPhone'},on:{"click":function($event){return _vm.changeFilterHasPhone()}}}),_c('iconSK',{style:({
        display: 'inline',
        cursor: 'pointer'
      }),attrs:{"icon":_vm.filterHasPhone ? 'rectangleChecked' : 'rectangleUnchecked',"width":15,"height":15},on:{"click":function($event){return _vm.changeFilterHasPhone()}}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.showLanguageLocationFilter &&
      _vm.currentExperts.length > 0 &&
      (_vm.selectedCategories.length > 0 || _vm.searchString.length > 0)
    ),expression:"\n      showLanguageLocationFilter &&\n      currentExperts.length > 0 &&\n      (selectedCategories.length > 0 || searchString.length > 0)\n    "}],style:({
      textAlign: 'center',
      marginBottom: _vm.isMobile ? _vm.gMDynamicSize(23) + 'px' : 38 + 'px'
    })},[_c('P1SK',{class:{ 'selected-category': _vm.selectedLanguages.length === 0 },style:({ display: 'inline', cursor: 'pointer' }),attrs:{"text":'skFilterAllLanguages'},on:{"click":function($event){return _vm.resetSelectedLanguages()}}}),_vm._l((_vm.currentLanguages),function(item){return _c('P1SK',{key:item.nr,class:{ 'selected-category': _vm.languageIsSelected(item) },style:({
        display: 'inline',
        cursor: 'pointer',
        marginLeft: _vm.gMDynamicSize(20) + 'px'
      }),attrs:{"text":item.userLanguage},on:{"click":function($event){return _vm.changeSelectedLanguages(item)}}})})],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.showLanguageLocationFilter &&
      _vm.currentExperts.length > 0 &&
      (_vm.selectedCategories.length > 0 || _vm.searchString.length > 0)
    ),expression:"\n      showLanguageLocationFilter &&\n      currentExperts.length > 0 &&\n      (selectedCategories.length > 0 || searchString.length > 0)\n    "}],style:({
      textAlign: 'center',
      marginBottom: _vm.isMobile ? _vm.gMDynamicSize(20) + 'px' : 38 + 'px'
    })},[_c('P1SK',{class:{ 'selected-category': _vm.selectedLocations.length === 0 },style:({ display: 'inline', cursor: 'pointer' }),attrs:{"text":'skFilterAllCantones'},on:{"click":function($event){return _vm.resetSelectedLocations()}}}),_vm._l((_vm.currentLocations),function(item){return _c('P1SK',{key:item.nr,class:{ 'selected-category': _vm.locationIsSelected(item) },style:({
        display: 'inline',
        cursor: 'pointer',
        marginLeft: _vm.gMDynamicSize(10) + 'px'
      }),attrs:{"text":item.userLanguage},on:{"click":function($event){return _vm.changeSelectedLocations(item)}}})})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }