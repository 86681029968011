<template>
  <div v-if="editingItem">
    <P2SK
      :style="{ marginBottom: gMDynamicSize(10) + 'px' }"
      :text="'skEditorContactKontaktUndSocialMedia'"
      :bold="true"
    />

    <div
      :style="{
        marginBottom: gMDynamicSize(14) + 'px'
      }"
    >
      <mediaEditLinkElementSK :icon="'Mail'" :text="editingItem.email" />
    </div>
    <mediaEditLinkElementSK :editorItem="getEditorItem('url')" :icon="'Web'" />
    <mediaEditLinkElementSK :editorItem="getEditorItem('phone')" :icon="'Tel'" />
    <mediaEditLinkElementSK :editorItem="getEditorItem('twitterLink')" :icon="'Twitter'" />
    <mediaEditLinkElementSK :editorItem="getEditorItem('instaLink')" :icon="'Insta'" />
    <mediaEditLinkElementSK :editorItem="getEditorItem('linkedInLink')" :icon="'Edin'" />
    <mediaEditLinkElementSK :editorItem="getEditorItem('fbLink')" :icon="'Fb'" />
    <mediaEditLinkElementSK :editorItem="getEditorItem('xingLink')" :icon="'Xing'" />
    <mediaEditLinkElementSK :editorItem="getEditorItem('pinterestLink')" :icon="'Pinterest'" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  props: {
    editors: {
      type: Array
    }
  },
  computed: {
    ...mapGetters(['gMDynamicSize', 'editingItem'])
  },
  methods: {
    getEditorItem(field) {
      if (this.editors) {
        return this.editors.find((editorItem) => {
          return editorItem.field.toLowerCase() === field.toLowerCase()
        })
      }
    }
  }
}
</script>
