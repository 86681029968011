<template>
  <b-field
    v-if="editorItem"
    :type="currentErrors.length > 0 ? 'is-danger' : ''"
    :message="debounce ? currentErrors : []"
  >
    <b-input
      ref="textareask"
      :placeholder="getDixTranslation(editorItem.placeholder)"
      v-model="currentEditing"
    ></b-input>
  </b-field>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { required, url, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      editing: null,
      debounce: true
    }
  },
  props: {
    editorItem: {
      type: Object
    }
  },
  mounted() {
    setTimeout(() => {
      this.setInputStyling()
    }, 1)
  },
  watch: {
    appWidth(width) {
      if (width) {
        this.setInputStyling()
      }
    },
    editing(string) {
      if (this.editorItem) {
        const data = {}
        if (string) {
          data.value = string
        } else {
          data.value = ''
        }

        data.field = this.editorItem.field
        this.editEditingItem(data)
      }
    }
  },
  validations() {
    return {
      editing: this.rules
    }
  },
  computed: {
    ...mapGetters(['gMDynamicSize', 'appWidth', 'editingItem']),
    currentEditing: {
      get() {
        if (this.editingItem && this.editorItem.field) {
          return this.editingItem[this.editorItem.field]
        }
      },
      set(newValue) {
        this.editing = newValue
      }
    },
    rules() {
      if (this.editorItem.format.toLowerCase() === 'url') {
        return { url }
      }
      if (
        this.editorItem.minValue === -1 &&
        this.editorItem.maxValue === -1 &&
        this.editorItem.isMandatory === true
      ) {
        return { required }
      }
      if (
        this.editorItem.minValue !== -1 &&
        this.editorItem.maxValue !== -1 &&
        this.editorItem.isMandatory === true
      ) {
        return {
          required,
          minLength: minLength(this.editorItem.minValue),
          maxLength: maxLength(this.editorItem.maxValue)
        }
      }
      if (
        this.editorItem.minValue !== -1 &&
        this.editorItem.maxValue !== -1 &&
        this.editorItem.isMandatory === false
      ) {
        return {
          minLength: minLength(this.editorItem.minValue),
          maxLength: maxLength(this.editorItem.maxValue)
        }
      }
      return {}
    },
    currentErrors() {
      const errors = []
      this.debounce = false
      if (this.editing !== null) {
        if (!this.$v.editing.required && this.$v.editing.required !== undefined) {
          errors.push(this.getDixTranslation('skVMMandatory'))
        }
        if (!this.$v.editing.minLength && this.$v.editing.minLength !== undefined) {
          errors.push(this.getDixTranslation('skVMTextTooShort'))
        }
        if (!this.$v.editing.maxLength && this.$v.editing.maxLength !== undefined) {
          errors.push(this.getDixTranslation('skVMTextTooLong'))
        }
        if (!this.$v.editing.url && this.$v.editing.url !== undefined) {
          errors.push(this.getDixTranslation('skVMInvalidURL'))
        }
      }
      // eslint-disable-next-line vue/no-async-in-computed-properties
      setTimeout(() => {
        this.debounce = true
      }, 500)
      return errors
    }
  },
  methods: {
    ...mapMutations(['editEditingItem']),
    setInputStyling() {
      if (this.$refs.textareask && this.$refs.textareask.$refs.input) {
        this.$refs.textareask.$refs.input.setAttribute(
          'style',
          `font-size:${this.gMDynamicSize(16)}px`,
          `line-height:${this.gMDynamicSize(20)}px`,
          `height:${this.gMDynamicSize(32)}px`
        )
      }
    }
  }
}
</script>
