var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    display: 'flex'
  })},[_c('img',{style:({
      verticalAlign: 'middle',
      height: 100 + '%',
      marginRight: _vm.editorItem ? _vm.gMDynamicSize(20) + 'px' : _vm.gMDynamicSize(10) + 'px'
    }),attrs:{"src":_vm.currentIcon,"width":_vm.editorItem ? _vm.gMDynamicSize(30) + 'px' : _vm.gMDynamicSize(22.5) + 'px',"height":_vm.editorItem ? _vm.gMDynamicSize(30) + 'px' : _vm.gMDynamicSize(22.5) + 'px'}}),(_vm.editorItem)?_c('div',{style:({
      width: _vm.gMDynamicSize(290, 414, 280) + 'px'
    })},[_c('textInputSK',{attrs:{"editorItem":_vm.editorItem}})],1):_c('P2SK',{style:({
      cursor: _vm.selectable ? 'auto' : 'pointer'
    }),attrs:{"text":_vm.currentLinkText,"selectable":_vm.selectable,"lineClamp":1},on:{"click":function($event){return _vm.openRequestedSinglePopup(_vm.text)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }