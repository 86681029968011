var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ion-page skViewPort"},[_c('ion-content',[_c('b-sidebar',{attrs:{"fullheight":true,"right":true,"fullwidth":false,"overlay":true},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('div',{staticClass:"level-item"},[_c('div',{style:({
            position: 'fixed',
            top: 27 + 'px',
            right: 22 + 'px'
          })},[_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"src":require("./../../assets/SKicons/closeWhite.svg"),"width":_vm.gMDynamicSize(32) + 'px',"height":_vm.gMDynamicSize(32) + 'px'},on:{"click":function($event){return _vm.changeShowMenu(false)}}})])]),_c('div',{style:({
          paddingLeft: _vm.gMDynamicSize(63) + 'px',
          paddingRight: _vm.gMDynamicSize(33) + 'px',
          paddingTop: _vm.gMDynamicSize(120) + 'px',
          paddingBottom: _vm.gMDynamicSize(11) + 'px'
        })},[_c('H2SK',{style:({
            cursor: 'pointer',
            marginBottom: 20 + 'px',
            marginRight: 20 + 'px'
          }),attrs:{"inverted":true,"text":'skHeaderAbout'},on:{"click":function($event){_vm.goToProjectInfoPage(), _vm.changeShowMenu(false)}}}),_c('H2SK',{style:({
            cursor: 'pointer',
            marginBottom: 20 + 'px',
            marginRight: 20 + 'px'
          }),attrs:{"inverted":true,"text":'FAQ'},on:{"click":function($event){_vm.goToFAQPage(), _vm.changeShowMenu(false)}}}),_c('H2SK',{style:({
            cursor: 'pointer',
            marginBottom: 20 + 'px',
            marginRight: 20 + 'px'
          }),attrs:{"inverted":true,"text":'skHeaderFeedback'},on:{"click":function($event){_vm.changeShowEmailFeedbackModal(true), _vm.changeShowMenu(false)}}}),(_vm.signedIn)?_c('H2SK',{style:({
            cursor: 'pointer',
            marginBottom: 20 + 'px',
            marginRight: 20 + 'px'
          }),attrs:{"inverted":true,"text":'skHeaderEditProfile'},on:{"click":function($event){_vm.goToProfilePage(), _vm.changeShowMenu(false)}}}):_c('H2SK',{style:({
            cursor: 'pointer',
            marginBottom: 20 + 'px',
            marginRight: 20 + 'px'
          }),attrs:{"inverted":true,"text":'skHeaderCreateProfile'},on:{"click":function($event){_vm.signIn(), _vm.changeShowMenu(false)}}}),(_vm.signedIn)?_c('H2SK',{style:({
            cursor: 'pointer',
            marginBottom: 20 + 'px',
            marginRight: 30 + 'px'
          }),attrs:{"inverted":true,"text":'skHeaderLogout'},on:{"click":function($event){_vm.signOut(), _vm.changeShowMenu(false)}}}):_c('H2SK',{style:({
            cursor: 'pointer',
            marginBottom: 20 + 'px',
            marginRight: 30 + 'px'
          }),attrs:{"inverted":true,"text":'skHeaderLogin'},on:{"click":function($event){_vm.signIn(), _vm.changeShowMenu(false)}}}),_c('div',{style:({
            marginLeft: 'auto',
            display: 'flex',
            flexWrap: 'wrap'
          })},[_c('div',[_c('H2SK',{style:({
                cursor: 'pointer',
                marginBottom: 20 + 'px',
                marginRight: 30 + 'px'
              }),attrs:{"inverted":true,"text":'skHeaderGerman'},on:{"click":function($event){_vm.changeLanguage(1), _vm.changeShowMenu(false)}}})],1),_c('div',[_c('H2SK',{style:({
                cursor: 'pointer',
                marginBottom: 20 + 'px',
                marginRight: 30 + 'px'
              }),attrs:{"inverted":true,"text":'skHeaderFrench'},on:{"click":function($event){_vm.changeLanguage(2), _vm.changeShowMenu(false)}}})],1),_c('div',[_c('H2SK',{style:({
                cursor: 'pointer',
                marginBottom: 20 + 'px',
                marginRight: 30 + 'px'
              }),attrs:{"inverted":true,"text":'skHeaderItalian'},on:{"click":function($event){_vm.changeLanguage(3), _vm.changeShowMenu(false)}}})],1),_c('div',[_c('H2SK',{style:({
                cursor: 'pointer',
                marginBottom: 20 + 'px',
                marginRight: 30 + 'px'
              }),attrs:{"inverted":true,"text":'skHeaderEnglish'},on:{"click":function($event){_vm.changeLanguage(4), _vm.changeShowMenu(false)}}})],1)])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }